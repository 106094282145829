export enum RequestStatusEnum {
  /// <summary>
  /// Zaprimljen zahtjev
  /// </summary>
  ProcedureSubmitted = 1,
  /// <summary>
  /// Storniran
  /// </summary>
  Cancelled = 2,
  /// <summary>
  /// Zaprimljen
  /// </summary>
  Submitted = 3,
  /// <summary>
  /// Izdano mišljenje s prijedlogom
  /// </summary>
  OpinionWithRecommendation = 4,
  /// <summary>
  /// Izdano rješenje o primjerenom programu
  /// </summary>
  DecisionOnAppropriateProgram = 5,
  /// <summary>
  /// Izdano rješenje o odgodi upisa
  /// </summary>
  DecisionOnEnrollmentPostponement = 6,
  /// <summary>
  /// Odobren postupak upisa
  /// </summary>
  EnrollmentApproved = 7,
  /// <summary>
  /// Upisnica potpisana
  /// </summary>
  RegistrationSigned = 8,
  /// <summary>
  /// Upisan
  /// </summary>
  Registered = 9,
  /// <summary>
  /// Nacrt
  /// </summary>
  Draft = 10,
  /// <summary>
  /// Na popisu školskih obveznika
  /// </summary>
  OnSchoolCandidateList = 11,
  /// <summary>
  /// Upisnica kreirana
  /// </summary>
  RegistrationFormCreated = 12,
  /// <summary>
  /// Odobren zahtjev
  /// </summary>
  ProcedureApproved = 13,
  /// <summary>
  /// Odbijen zahtjev
  /// </summary>
  ProcedureRejected = 14,
  /// <summary>
  /// Potvrđen postupak upisa
  /// </summary>
  EnrollmentConfirmed = 15,
  /// <summary>
  /// Kreiranje upisnice u tijeku
  /// </summary>
  RegistrationFormCreating = 16,
}
export default RequestStatusEnum;
