import { Typography } from "@king-ict/eupisi-ui/components";
import { Row, Col } from "react-bootstrap";

import styles from "./FormCard.module.scss";

export interface FormCardProps {
  children?: any;
  cardTitle?: string;
}
export const FormCard = ({ children, cardTitle }: FormCardProps) => {
  const renderCardTitle = () =>
    cardTitle === undefined ? (
      <></>
    ) : (
      <Row>
        <Col>
          <Typography variant="smallHeading" className="pt-2">
            {cardTitle}
          </Typography>
        </Col>
      </Row>
    );

  return (
    <>
      <div className={styles.formcard}>
        {renderCardTitle()}
        <div>{children}</div>
      </div>
    </>
  );
};
