import { useEffect, useState } from "react";

import {
  Button,
  InfoPanel,
  MobileMenu,
  Spinner,
  Stepper,
  StepperProvider,
  Typography,
  useStepper,
} from "@king-ict/eupisi-ui/components";
import { useBreakpoint } from "@king-ict/eupisi-ui/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import qs from "qs";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { useTypedSelector } from "../../app/rootReducer";
import { useAppDispatch } from "../../app/store";
import { useAuth } from "../../hooks/auth";
import { useBusinessRule } from "../../hooks/businessRule/useBusinessRule";
import { PersonDetails } from "../../models/Request/PersonDetails";
import Request from "../../models/Request/Request";
import { useGetCurrentSchoolYearQuery } from "../../queries/dataQuery";
import { childrenList } from "../../routes/routes";
import { setIsEdited } from "../../slices/applicationSlice";
import {
  getInitialConversation,
  selectInitialConversationQuestionGroupNames,
} from "../../slices/questionnaireSlice";
import {
  getRequestById,
  updateRequestApplicant,
} from "../../slices/requestSlice";
import ApplicantParentDataStep from "./ApplicantParentDataStep/ApplicantParentDataStep";
import ChildDataStep from "./ChildDataStep/ChildDataStep";
import DocumentDataStep from "./DocumentDataStep/DocumentDataStep";
import InitialConversationStep from "./InitialConversationStep/InitialConversationStep";
import InstitutionDataStep from "./InstitutionDataStep/InstitutionDataStep";
import OtherParentDataStep from "./OtherParentDataStep/OtherParentDataStep";
import RequestDraftStepperActions from "./RequestDraftStepperActions";
import styles from "./RequestPage.module.scss";
import RequestReviewStep from "./RequestReviewStep/RequestReviewStep";
import RequestStepperHeader from "./RequestStepperHeader";
import { RequestStepperHeaderButtons } from "./RequestStepperHeaderButtons";

/**
 * If you are looking for the routing for the component, look in
 * Layout.tsx which contains the routing logic.
 * @returns
 */
export default function RequestDraftPage() {
  const location = useLocation();
  const history = useHistory();
  const params = qs.parse(location.search.substring(1));
  const { id } = params;

  const questionGroupNames = useSelector(
    selectInitialConversationQuestionGroupNames
  );

  const { data: schoolYear } = useGetCurrentSchoolYearQuery();

  const request: Request = useTypedSelector((state) => state.request.request);

  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const { hasOneParent, isRequestTypePO, isRequestTypePU } = useBusinessRule();

  const dispatch = useAppDispatch();

  const [validRequest, setValidRequest] = useState<boolean>(true);

  const auth = useAuth();

  const isParentRequestAllowed = () => {
    if (request.applicantParentDetails.person.oib !== auth.user?.oib) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(getInitialConversation());
    if (id) {
      setIsLoading(true);
      dispatch(getRequestById(+id!))
        .then(unwrapResult)
        .then((results) => {
          if (results.subStep) {
            stepperApi.moveToSpecific(results.step, results.subStep);
          } else {
            stepperApi.moveToSpecific(results.step);
          }
          dispatch(setIsEdited());
          dispatch(
            updateRequestApplicant(
              results.applicantParentDetails || ({} as PersonDetails)
            )
          );
        })
        .then(() => {
          if (
            request.id === undefined ||
            request.id === null ||
            Number(id) !== request.id
          ) {
            setValidRequest(false);
          } else {
            if (Number(id) === request.id) {
              if (isParentRequestAllowed() === true) {
                setValidRequest(true);
              } else {
                setValidRequest(false);
              }
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, history, schoolYear, validRequest]);
  const { t } = useTranslation();

  const getQuestionGroupNames = () => {
    let newQuestionGroupNames = hasOneParent()
      ? [
          ...questionGroupNames.slice(0, 2),
          "-",
          ...questionGroupNames.slice(3, 6),
        ] // hardkodirano micanje prikaza roditelja2 iz upitinka
      : [...questionGroupNames];
    return newQuestionGroupNames;
  };

  const steps = [
    {
      title: t("request.steps.parentsStep"),
      substeps: [
        t("request.steps.parentSubStepOne"),
        hasOneParent() ? "-" : t("request.steps.parentSubStepTwo"),
      ],
    },
    {
      title: t("request.steps.childStep"),
      substeps: [],
    },
    {
      title:
        isRequestTypePO() ||
        (isRequestTypePU() && !request.institutionProgramId)
          ? "-"
          : t("request.steps.institutionStep"),
      substeps: [],
    },
    {
      title: isRequestTypePO()
        ? "-"
        : t("request.steps.initialConversationStep"),
      substeps: getQuestionGroupNames(),
    },
    {
      title: t("request.steps.documentStep"),
      substeps: [],
    },
    {
      title: t("request.steps.confirmStep"),
      substeps: [],
    },
  ];

  const getSteps = () => steps;

  const [stepperState, stepperApi] = useStepper(getSteps());

  const sizeBreakpoint = useBreakpoint();

  function getStepContent(step: number, subStep?: number) {
    switch (step) {
      case 0:
        switch (subStep) {
          case 0:
            return <ApplicantParentDataStep />;
          case 1:
            return <OtherParentDataStep />;
        }
        break;
      case 1:
        return <ChildDataStep />;
      case 2:
        return <InstitutionDataStep />;
      case 3:
        return <InitialConversationStep subStep={subStep ?? 0} />;
      case 4:
        return <DocumentDataStep />;
      case 5:
        return (
          <RequestReviewStep moveToSpecificStep={stepperApi.moveToSpecific} />
        );
      default:
        return <InfoPanel message={"Unknown step"} color="danger" fullWidth />;
    }
  }

  if (isLoading) return <Spinner show={true} />;
  return (
    <>
      {validRequest ? (
        <>
          <div className={styles.stepperWrapper}>
            <StepperProvider>
              <div className={styles.stepperContentWrapper}>
                {sizeBreakpoint === "xs" || sizeBreakpoint === "sm" ? (
                  <Row>
                    <MobileMenu open={openMenu} onClose={handleCloseMenu}>
                      <div className={styles.mobileMenuButtons}>
                        <RequestStepperHeaderButtons
                          currentStep={stepperState.currentStep}
                          currentSubstep={stepperState.currentSubstep}
                        />
                      </div>
                      <aside>
                        <Stepper data={getSteps()} {...stepperState} />
                      </aside>
                    </MobileMenu>
                    {validRequest && (
                      <>
                        <Col xs={12} className={styles.content}>
                          <RequestStepperHeader
                            currentStep={stepperState.currentStep}
                            currentSubstep={stepperState.currentSubstep}
                            openMobileMenu={handleOpenMenu}
                          />

                          {getStepContent(
                            stepperState.currentStep,
                            stepperState.currentSubstep
                          )}
                          <RequestDraftStepperActions
                            stepperState={stepperState}
                            stepperApi={stepperApi}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col md={3} className={styles.sidebar}>
                        <aside>
                          <Stepper data={getSteps()} {...stepperState} />
                        </aside>
                      </Col>
                      {validRequest && (
                        <>
                          <Col md={9} className={styles.content}>
                            <RequestStepperHeader
                              currentStep={stepperState.currentStep}
                              currentSubstep={stepperState.currentSubstep}
                              openMobileMenu={handleOpenMenu}
                            />

                            {getStepContent(
                              stepperState.currentStep,
                              stepperState.currentSubstep
                            )}
                            <RequestDraftStepperActions
                              stepperState={stepperState}
                              stepperApi={stepperApi}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </div>
            </StepperProvider>
          </div>
        </>
      ) : (
        /* REQUEST IS NOT VALID - DISPLAY ERROR MSG */
        <>
          <InfoPanel
            message={t("common.defaultErrorMessage")}
            color="danger"
            fullWidth
          />
          <Row>
            <Col md={6} xs={6}>
              <Typography component="p">
                {t("request.unauthorizedAccessError")}
              </Typography>
            </Col>
            <Col md={6} xs={6} className="text-right">
              <Button
                onClick={() => {
                  history.push(childrenList());
                }}
                size="sm"
                color="primary"
                outline
              >
                {t("request.prevStepButton")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
