import "@openfonts/source-sans-pro_latin-ext";
import "@king-ict/eupisi-ui/styles/theme.scss";

import { StrictMode, Suspense } from "react";

import { keycloakInstance } from "@king-ict/eupisi-ui/auth";
import { ReactKeycloakProvider } from "@king-ict/eupisi-ui/components";
import { Spinner } from "@king-ict/eupisi-ui/components";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./app/App";
import store from "./app/store";
import "./app/i18n";
import { ProvideAuth } from "./hooks/auth";

ReactDOM.render(
  <StrictMode>
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      LoadingComponent={<Spinner show={true} />}
    >
      <Provider store={store}>
        <ProvideAuth>
          <Router>
            <Suspense fallback={<Spinner show={true} />}>
              <App />
            </Suspense>
          </Router>
        </ProvideAuth>
      </Provider>
    </ReactKeycloakProvider>
  </StrictMode>,
  document.getElementById("root")
);
