import { Modal, Typography } from "@king-ict/eupisi-ui/components";
import { useTranslation } from "react-i18next";

export interface ConfirmModalProps {
  title: string;
  confirmMessage?: string | undefined;
  confirmDescription?: string | undefined;
  successMesage?: string | undefined;
  errorMessage?: string | undefined;
  confirmState?: "confirm" | "message" | "error";
  onClickClose: (event: React.MouseEvent<any, MouseEvent>) => void;
  onClickConfirm?: (event: React.MouseEvent<any, MouseEvent>) => void;
}

export const ConfirmModal = ({
  title,
  confirmMessage,
  confirmDescription,
  successMesage,
  errorMessage,
  confirmState,
  onClickClose,
  onClickConfirm,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      {confirmState === "confirm" && (
        <Modal
          showCloseMark
          type={confirmState}
          title={title}
          cancelText={t("common.no")}
          confirmText={t("common.yes")}
          open={confirmState === "confirm"}
          onClose={onClickClose}
          onConfirm={onClickConfirm}
        >
          <Typography>{confirmMessage}</Typography>
          <Typography variant="small">{confirmDescription}</Typography>
        </Modal>
      )}
      {confirmState === "message" && (
        <Modal
          showCloseMark
          type={confirmState}
          title={title}
          open={confirmState === "message"}
          confirmText={t("common.close")}
          onConfirm={onClickClose}
          onClose={onClickClose}
        >
          <Typography>{successMesage}</Typography>
        </Modal>
      )}
      {confirmState === "error" && (
        <Modal
          showCloseMark
          type={confirmState}
          title={title}
          open={confirmState === "error"}
          confirmText={t("common.close")}
          onConfirm={onClickClose}
          onClose={onClickClose}
        >
          <Typography>{errorMessage}</Typography>
        </Modal>
      )}
    </>
  );
};
