import React, { useEffect, useState } from "react";

import { Modal, Typography } from "@king-ict/eupisi-ui/components";
import { Location } from "history";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
}

const RouteLeavingGuard = ({ when, navigate }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setConfirmedNavigation(false);
      navigate(lastLocation.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <Modal
        type={"confirm"}
        title={t("request.closeConfirmationTitle")}
        open={modalVisible}
        confirmText={t("request.closeConfirmationYes")}
        cancelText={t("common.no")}
        onClose={closeModal}
        onConfirm={handleConfirmNavigationClick}
      >
        <Typography component="div" variant="medium">
          {t("request.closeConfirmationMessage")}
        </Typography>
      </Modal>
    </>
  );
};
export default RouteLeavingGuard;
