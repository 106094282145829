import { useState } from "react";

import { Button, Modal, Typography } from "@king-ict/eupisi-ui/components";
import { UploadFile } from "@king-ict/eupisi-ui/models";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { saveRequest } from "../../api/Request/RequestApi";
import { saveRegistrationFormByRequestId } from "../../api/Request/RequestApi";
import { useTypedSelector } from "../../app/rootReducer";
import { useBusinessRule } from "../../hooks/businessRule/useBusinessRule";
import RequestStatusEnum from "../../models/Request/RequestStatusEnum";
import { RequestReviewStep } from "../../models/Request/RequestStep";
import RequestStepType from "../../models/Request/RequestStepType";
import { childrenList } from "../../routes/routes";

type Props = {
  stepperState: any;
  stepperApi: any;
  canUpload?: boolean;
  filesToUpload?: UploadFile[];
  editModeConfirm?: boolean;
  editToggle?: () => void;
};

export default function RequestStepperActions({
  stepperState,
  stepperApi,
  canUpload,
  filesToUpload,
  editModeConfirm,
  editToggle,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    isRequestTypePU,
    hasRegistration,
    hasEnrollmentDecision,
  } = useBusinessRule();

  const request = useTypedSelector((state) => state.request.request);
  const { isApplicantParent } = useBusinessRule();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  // Previous step
  const showPreviousStep = () =>
    stepperState.currentStep > RequestReviewStep.Review;
  const handlePrevious = () => {
    if (
      stepperState.currentStep === RequestReviewStep.Registration &&
      hasEnrollmentDecision()
    ) {
      stepperApi.moveToSpecific(RequestReviewStep.EnrollmentDecision);
    } else {
      stepperApi.moveToSpecific(RequestReviewStep.Review);
    }
  };
  // Next step
  const showNextStep = () =>
    (stepperState.currentStep === RequestReviewStep.Review &&
      (hasRegistration() || hasEnrollmentDecision())) ||
    (stepperState.currentStep === RequestReviewStep.EnrollmentDecision &&
      hasRegistration());

  const handleNext = () => {
    if (
      stepperState.currentStep === RequestReviewStep.Review &&
      hasEnrollmentDecision()
    ) {
      stepperApi.moveToSpecific(RequestReviewStep.EnrollmentDecision);
    } else if (
      (stepperState.currentStep === RequestReviewStep.Review ||
        stepperState.currentStep === RequestReviewStep.EnrollmentDecision) &&
      hasRegistration()
    ) {
      stepperApi.moveToSpecific(RequestReviewStep.Registration);
    }
  };
  // Upload signed document
  const showUploadSignedRegistration = () =>
    stepperState.currentStep === RequestReviewStep.Registration &&
    request.statusId === RequestStatusEnum.RegistrationFormCreated;
  const handleUploadDocuments = async () => {
    //Upload document api
    if (filesToUpload && filesToUpload.length > 0) {
      await saveRegistrationFormByRequestId(request.id!, filesToUpload).then(
        (response) => {
          if (response?.result && response.isValid) {
            setSuccessMessage(
              t("enrollmentStep.RegistrationFormsSuccessUpload")
            );
          } else {
            if (response?.validationFailures) {
              setErrorMessage(response.validationFailures[0].message);
            }
          }
        }
      );
    } else {
      setErrorMessage(t("enrollmentStep.RegistrationFormsInfo"));
    }
  };

  // Edit institution
  const showEditInstitution = () =>
    isRequestTypePU() &&
    stepperState.currentStep === RequestReviewStep.Review &&
    request.statusId === RequestStatusEnum.OnSchoolCandidateList;

  const showConfirmEditInstitution = () =>
    isRequestTypePU() &&
    stepperState.currentStep === RequestReviewStep.InstitutionProgramData &&
    request.statusId === RequestStatusEnum.OnSchoolCandidateList;

  const handleEditInstitution = () => {
    stepperApi.moveToSpecific(RequestReviewStep.InstitutionProgramData);
  };

  const handleConfirmEditInstitution = async () => {
    await saveRequest(request, RequestStepType.SaveOnly).then((response) => {
      if (response?.result && response.isValid) {
        history.push(childrenList());
      } else {
        if (response?.validationFailures) {
          setErrorMessage(response.validationFailures[0].message);
        }
      }
    });
  };
  // only aaplicant has right for stepper actions
  if (!isApplicantParent()) return null;
  return (
    <>
      <Row className="border-top border-2 bg-light p-3">
        <Col md={6} xs={6}>
          {showPreviousStep() && (
            <Button onClick={handlePrevious} size="sm" color="primary" outline>
              {t("request.prevStepButton")}
            </Button>
          )}
        </Col>

        <Col className="text-right" md={6} xs={6}>
          {showNextStep() && (
            <Button onClick={handleNext} size="sm" color="primary" outline>
              {t("request.nextStepButtonNav")}
            </Button>
          )}
          {showUploadSignedRegistration() && (
            <Button onClick={handleUploadDocuments} size="sm" color="primary">
              {t("request.nextStepButtonUploadRegistration")}
            </Button>
          )}
          {showEditInstitution() && (
            <Button
              onClick={handleEditInstitution}
              size="sm"
              color="primary"
              outline
            >
              Ažuriranje ustanove
            </Button>
          )}
          {showConfirmEditInstitution() && (
            <Button
              onClick={() => setOpenConfirmModal(true)}
              size="sm"
              color="primary"
            >
              Predaj zahtjev
            </Button>
          )}
        </Col>
      </Row>

      <Modal
        open={!!errorMessage}
        title={t("enrollmentStep.signedEnrollmentDocument")}
        onClose={() => setErrorMessage(undefined)}
        onConfirm={() => setErrorMessage(undefined)}
        type="error"
        confirmText={t("common.close")}
      >
        <Typography component="div" variant="medium">
          {errorMessage}
        </Typography>
      </Modal>

      <Modal
        open={!!successMessage}
        title={t("enrollmentStep.signedEnrollmentDocument")}
        onClose={() => setSuccessMessage(undefined)}
        onConfirm={() => history.push(childrenList())}
        type="message"
        confirmText={t("common.close")}
      >
        <Typography component="div" variant="medium">
          {successMessage}
        </Typography>
      </Modal>

      <Modal
        type={"confirm"}
        title={t("request.sendRequestConfirmModalTitle")}
        open={openConfirmModal}
        confirmText={t("request.sendRequestButton")}
        cancelText={t("common.cancel")}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={handleConfirmEditInstitution}
      >
        <Typography component="div" variant="medium">
          {t("request.sendRequestConfirmModalMessage")}
        </Typography>
      </Modal>
    </>
  );
}
