export enum DocumentType {
  Parent = 1,
  OtherParent,
  Child,
  EducationalRehabilitation,
  Medical,
  Psychology,
  DecisionProposal,
  Form7,
  EnrollmentDecision,
  RegistrationForm,
  /**
   * Optional for EarlyEnrollment RequestType
   */
  PsychophysicalConditionDecision,
  /**
   * Required for TemporaryExemption RequestType
   */
  UniqueBodyOfExpertise,
}
