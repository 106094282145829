import { Typography, useStepperDispatch } from "@king-ict/eupisi-ui/components";
import { useValidation } from "@king-ict/eupisi-ui/hooks";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import QuestionDisplayTypeEnum from "../../../models/Questionnaire/QuestionDisplayTypeEnum";
import InitialConversationQuestionDataItemValidationSchema from "../../../models/Request/InitialConversationQuestionDataItemValidationSchema";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";
import QuestionComponentFactory from "./QuestionComponentFactory";
import { useInitialConversationStep } from "./useInitialConversationStep";

interface Props {
  subStep: number;
}

export default function InitialConversationStep({ subStep }: Props) {
  const { t } = useTranslation();

  const [
    questionGroup,
    questionGroupConversationItems,
    setQuestionGroupConversationItems,
  ] = useInitialConversationStep({
    subStep: subStep,
  });

  useStepperDispatch({
    type: "next",
    payload: () => {
      return handleStep();
    },
  });

  useStepperDispatch({
    type: "previous",
    payload: () => {
      return handleStep();
    },
  });

  useStepperDispatch({
    type: "save",
    payload: () => {},
  });

  const [errors, doValidations, stopValidations] = useValidation({
    data: questionGroupConversationItems,
    validationSchema: InitialConversationQuestionDataItemValidationSchema,
  });

  const handleStep = () => {
    console.log("handleSTEPInitial");
    if (questionGroupConversationItems) {
      const isValid = doValidations();

      if (isValid) {
        stopValidations();
      } else {
        return isValid;
      }
    } else {
      return false;
    }
  };

  const renderQuestions = () => {
    const repeatableSetLength = questionGroupConversationItems.filter(
      (item) => item.sort === 0
    ).length;
    return questionGroupConversationItems.map((cd, i) => {
      const question = questionGroup?.questions.find(
        (q) => q.questionGroupQuestionId === cd.questionGroupQuestionId
      );

      if (!question) {
        return null;
      }

      const isLastQuestionInRepeatableSet = (i + 1) % repeatableSetLength === 0;

      // TODO: Resolve error text in QuetionComponentFactory
      // See how to put error text for Radio input in validationSchema
      let errorText;
      if (errors !== undefined) {
        switch (question.displayTypeId) {
          case QuestionDisplayTypeEnum.Text:
          case QuestionDisplayTypeEnum.TextArea:
          case QuestionDisplayTypeEnum.Time:
          case QuestionDisplayTypeEnum.Date:
            errorText = errors[i]?.answer;
            break;
          case QuestionDisplayTypeEnum.Select:
            errorText = errors[i]?.answerId;
            break;
          case QuestionDisplayTypeEnum.Radio:
            errorText = errors[i]?.answerId
              ? t("validations.radioGroupRequired")
              : undefined;
            break;
          case QuestionDisplayTypeEnum.MultiSelect:
            errorText = errors[i]?.answerIds;
            break;
        }
      }

      return (
        <>
          <QuestionComponentFactory
            key={
              cd.isRepeatable
                ? `${cd.questionGroupQuestionId}_${cd.sort}`
                : cd.questionGroupQuestionId
            }
            stateIndex={i}
            question={question!}
            conversationItem={cd}
            setState={setQuestionGroupConversationItems}
            errorText={errorText}
          />
          {isLastQuestionInRepeatableSet && (
            <Row>
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
          )}
        </>
      );
    });
  };

  return (
    <>
      <RequestFormTopMessage
        title={t("initialConversationStep.mainInfoTitle")}
        infoMessage={t("initialConversationStep.mainInfoMessage")}
      />
      <Typography variant="medium" color="light">
        <i>{t("initialConversationStep.gdpr1")}</i>
      </Typography>
      <Typography variant="medium" color="light">
        <i>{t("initialConversationStep.gdpr2")}</i>
      </Typography>
      <RequestFormCard cardTitle={questionGroup?.name}>
        <Row>
          <Col />
          <Col xs={12} lg={6}>
            {renderQuestions()}
          </Col>
          <Col />
        </Row>
      </RequestFormCard>
    </>
  );
}
