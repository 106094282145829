import { combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import { dataApi } from "../queries/dataQuery";
import applicationReducer from "../slices/applicationSlice";
import enrollmentDecisionSlice from "../slices/enrollmentDecisionSlice";
import questionnaireReducer from "../slices/questionnaireSlice";
import requestReducer from "../slices/requestSlice";

const rootReducer = combineReducers({
  application: applicationReducer,
  request: requestReducer,
  questionnaire: questionnaireReducer,
  enrollmentDecisione: enrollmentDecisionSlice,
  [dataApi.reducerPath]: dataApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
