export enum RequestStep {
  //RequestDraft
  ParentData = 0,
  ChildData,
  InstitutionProgramData,
  InitialQuestionnaireData,
  DocumentationData,
  Approval,
}
export enum RequestReviewStep {
  //Request
  Review = 0,
  EnrollmentDecision = 1,
  Registration = 2,
  InstitutionProgramData = 3,
}
