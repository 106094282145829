import { api } from "@king-ict/eupisi-ui/utils/apiUtils";

const apiUrlPrefix: string = "user-manual";

export const getUserManual = async () => {
  return api.download(`${apiUrlPrefix}`) as Promise<any>;
};

export const getStatement = async () => {
  return api.download(`${apiUrlPrefix}/statement`) as Promise<any>;
};
