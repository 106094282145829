import React, { useState } from "react";

import {
  DocumentList,
  DocumentsModal,
  InfoPanel,
  Typography,
  useStepperDispatch,
} from "@king-ict/eupisi-ui/components";
import { UploadFile } from "@king-ict/eupisi-ui/models";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { getRequestDocument } from "../../../api/Request/RequestApi";
import { useTypedSelector } from "../../../app/rootReducer";
import { useAppDispatch } from "../../../app/store";
import { useBusinessRule } from "../../../hooks/businessRule/useBusinessRule";
import { DocumentType } from "../../../models/Request/DocumentType";
import RequestTypeEnum from "../../../models/Request/RequestTypeEnum";
import { Attachment } from "../../../models/Shared/Attachment";
import {
  updateChildAttachments,
  updateChildDocuments,
  updateEducationalRehabilitationAttachments,
  updateEducationalRehabilitationDocuments,
  updateMedicalAttachments,
  updateMedicalDocuments,
  updateOtherParentAttachments,
  updateOtherParentDocuments,
  updatePshilogyAttachments,
  updatePsychologyDocuments,
  updatePsychophysicalConditionDecisionAttachments,
  updatePsychophysicalConditionDecisionDocuments,
  updateUniqueBodyOfExpertiseAttachments,
  updateUniqueBodyOfExpertiseDocuments,
} from "../../../slices/requestSlice";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";

export default function DocumentDataStep() {
  const requestState = useTypedSelector((state) => state.request.request);
  const [showUploadModal, setShowUploadModal] = useState<
    DocumentType | undefined
  >();
  const [uploadChildDocument, setUploadChildDocument] = useState<UploadFile[]>(
    []
  );
  const [uploadMedicalDocument, setUploadMedicalDocument] = useState<
    UploadFile[]
  >([]);
  const [uploadPsychologyDocument, setUploadPsychologyDocument] = useState<
    UploadFile[]
  >([]);
  const [
    uploadPsychophysicalConditionDecisionDocument,
    setUploadPsychophysicalConditionDecisionDocument,
  ] = useState<UploadFile[]>([]);
  const [
    uploadUniqueBodyOfExpertiseDocument,
    setUploadUniqueBodyOfExpertiseDocument,
  ] = useState<UploadFile[]>([]);
  const [
    uploadEducationalRehabilitationDocument,
    setUploadEducationalRehabilitationDocument,
  ] = useState<UploadFile[]>([]);
  const [uploadOtherDocument, setUploadOtherDocument] = useState<UploadFile[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [errorMessageParent2, setErrorMessageParent2] = useState<
    string | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    hasTwoParent,
    isParentTwoHasOIB,
    isRequestTypePO,
    isRequestTypeRUTUR,
  } = useBusinessRule();

  useStepperDispatch({
    type: "next",
    payload: () => {
      return handleStep();
    },
  });

  useStepperDispatch({
    type: "previous",
    payload: () => {
      return handleStep();
    },
  });

  const handleStep = () => {
    if (requestState) {
      const isValid = doValidations();
      if (isValid) {
        dispatch(
          updatePsychophysicalConditionDecisionDocuments(
            requestState.psychophysicalConditionDecisionUploadFiles ?? []
          )
        );
        dispatch(
          updateUniqueBodyOfExpertiseDocuments(
            requestState.uniqueBodyOfExpertiseUploadFiles ?? []
          )
        );
        dispatch(updateChildDocuments(requestState.childUploadFiles ?? []));
        dispatch(updateMedicalDocuments(requestState.medicalUploadFiles ?? []));
        dispatch(
          updatePsychologyDocuments(requestState.psychologyUploadFiles ?? [])
        );
        dispatch(
          updateEducationalRehabilitationDocuments(
            requestState.educationalRehabilitationUploadFiles ?? []
          )
        );
        dispatch(
          updateOtherParentDocuments(requestState.otherUploadFiles ?? [])
        );
        return isValid;
      }
      return false;
    }
    return false;
  };

  const doValidations = (): boolean => {
    let isValid = true;
    if (
      hasTwoParent() &&
      !isParentTwoHasOIB() &&
      (requestState.otherUploadFiles?.length || 0) === 0 &&
      (requestState.otherAttachments?.length || 0) === 0
    ) {
      setErrorMessageParent2(
        t("documentDataStep.requiredParent2DocumentMessage")
      );
      isValid = false;
    }

    if (
      isRequestTypeRUTUR() && //at least one document
      (requestState.medicalUploadFiles?.length || 0) === 0 &&
      (requestState.medicalAttachments?.length || 0) === 0 &&
      (requestState.psychologyUploadFiles?.length || 0) === 0 &&
      (requestState.psychologyAttachments?.length || 0) === 0 &&
      (requestState.educationalRehabilitationUploadFiles?.length || 0) === 0 &&
      (requestState.educationalRehabilitationAttachments?.length || 0) === 0 &&
      (requestState.childUploadFiles?.length || 0) === 0 &&
      (requestState.childAttachments?.length || 0) === 0
    ) {
      setErrorMessage(t("documentDataStep.requiredOneDocumentMessage"));
      isValid = false;
    }

    if (
      isRequestTypePO() &&
      (requestState.uniqueBodyOfExpertiseAttachments?.length || 0) === 0 &&
      (requestState.uniqueBodyOfExpertiseUploadFiles?.length || 0) === 0
    ) {
      setErrorMessage(
        t("documentDataStep.uniqueBodyOfExpertiseDocumentRequired")
      );
      isValid = false;
    }

    return isValid;
  };
  const handleFileChange = async (files: File[]) => {
    let uploadedFiles: UploadFile[] = [];

    if (files && files.length > 0) {
      files.forEach((file) => {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        var uploadedFile = {
          fileName: file.name,
          fileData: fileReader.result,
        } as UploadFile;
        uploadedFiles.push(uploadedFile);
      });
    }
    switch (showUploadModal) {
      case DocumentType.Child:
        setUploadChildDocument(uploadedFiles.length > 0 ? uploadedFiles : []);
        break;
      case DocumentType.Medical:
        setUploadMedicalDocument(uploadedFiles.length > 0 ? uploadedFiles : []);
        break;
      case DocumentType.Psychology:
        setUploadPsychologyDocument(
          uploadedFiles.length > 0 ? uploadedFiles : []
        );
        break;
      case DocumentType.EducationalRehabilitation:
        setUploadEducationalRehabilitationDocument(
          uploadedFiles.length > 0 ? uploadedFiles : []
        );
        break;
      case DocumentType.OtherParent:
        setUploadOtherDocument(uploadedFiles.length > 0 ? uploadedFiles : []);
        break;
      case DocumentType.PsychophysicalConditionDecision:
        setUploadPsychophysicalConditionDecisionDocument(
          uploadedFiles.length > 0 ? uploadedFiles : []
        );
        break;
      case DocumentType.UniqueBodyOfExpertise:
        setUploadUniqueBodyOfExpertiseDocument(
          uploadedFiles.length > 0 ? uploadedFiles : []
        );
        break;
      default:
        break;
    }
  };
  const handleUploadFileChange = (files: UploadFile[]) => {
    setErrorMessage(undefined);
    setErrorMessageParent2(undefined);
    switch (showUploadModal) {
      case DocumentType.Child:
        let combinedChild = files.concat(uploadChildDocument);
        setUploadChildDocument(combinedChild ?? []);
        setShowUploadModal(undefined);
        dispatch(updateChildDocuments(combinedChild ?? []));
        break;
      case DocumentType.Medical:
        let combinedMedical = files.concat(uploadMedicalDocument);
        setUploadMedicalDocument(combinedMedical ?? []);
        setShowUploadModal(undefined);
        dispatch(updateMedicalDocuments(combinedMedical ?? []));
        break;
      case DocumentType.Psychology:
        let combinedPsychology = files.concat(uploadPsychologyDocument);
        setUploadPsychologyDocument(combinedPsychology ?? []);
        setShowUploadModal(undefined);
        dispatch(updatePsychologyDocuments(combinedPsychology ?? []));
        break;
      case DocumentType.EducationalRehabilitation:
        let combinedEducationalRehabilitation = files.concat(
          uploadEducationalRehabilitationDocument
        );
        setUploadEducationalRehabilitationDocument(
          combinedEducationalRehabilitation ?? []
        );
        setShowUploadModal(undefined);
        dispatch(
          updateEducationalRehabilitationDocuments(
            combinedEducationalRehabilitation ?? []
          )
        );
        break;
      case DocumentType.OtherParent:
        let combinedOther = files.concat(uploadOtherDocument);
        setUploadOtherDocument(combinedOther ?? []);
        setShowUploadModal(undefined);
        dispatch(updateOtherParentDocuments(combinedOther ?? []));
        break;
      case DocumentType.PsychophysicalConditionDecision:
        let psychophysicalConditionDecision = files.concat(
          uploadPsychophysicalConditionDecisionDocument
        );
        setUploadPsychophysicalConditionDecisionDocument(
          psychophysicalConditionDecision ?? []
        );
        setShowUploadModal(undefined);
        dispatch(
          updatePsychophysicalConditionDecisionDocuments(
            psychophysicalConditionDecision ?? []
          )
        );
        break;
      case DocumentType.UniqueBodyOfExpertise:
        let uniqueBodyOfExpertise = files.concat(
          uploadUniqueBodyOfExpertiseDocument
        );
        setUploadUniqueBodyOfExpertiseDocument(uniqueBodyOfExpertise ?? []);
        setShowUploadModal(undefined);
        dispatch(
          updateUniqueBodyOfExpertiseDocuments(uniqueBodyOfExpertise ?? [])
        );
        break;
      default:
        break;
    }
  };
  const handleUploadDocumentClick = (documentType: DocumentType) => {
    setShowUploadModal(documentType);
    switch (documentType) {
      case DocumentType.Child:
        setUploadChildDocument(() => requestState.childUploadFiles ?? []);
        dispatch(updateChildDocuments(requestState.childUploadFiles ?? []));
        break;
      case DocumentType.Medical:
        setUploadMedicalDocument(() => requestState.medicalUploadFiles ?? []);
        dispatch(updateMedicalDocuments(requestState.medicalUploadFiles ?? []));
        break;
      case DocumentType.Psychology:
        setUploadPsychologyDocument(
          () => requestState.psychologyUploadFiles ?? []
        );
        dispatch(
          updatePsychologyDocuments(requestState.psychologyUploadFiles ?? [])
        );
        break;
      case DocumentType.EducationalRehabilitation:
        setUploadEducationalRehabilitationDocument(
          () => requestState.educationalRehabilitationUploadFiles ?? []
        );
        dispatch(
          updateEducationalRehabilitationDocuments(
            requestState.educationalRehabilitationUploadFiles ?? []
          )
        );
        break;
      case DocumentType.OtherParent:
        setUploadOtherDocument(() => requestState.otherUploadFiles ?? []);
        dispatch(
          updateOtherParentDocuments(requestState.otherUploadFiles ?? [])
        );
        break;
      case DocumentType.PsychophysicalConditionDecision:
        setUploadPsychophysicalConditionDecisionDocument(
          () => requestState.psychophysicalConditionDecisionUploadFiles ?? []
        );
        dispatch(
          updatePsychophysicalConditionDecisionDocuments(
            requestState.psychophysicalConditionDecisionUploadFiles ?? []
          )
        );
        break;
      case DocumentType.UniqueBodyOfExpertise:
        setUploadUniqueBodyOfExpertiseDocument(
          () => requestState.uniqueBodyOfExpertiseUploadFiles ?? []
        );
        dispatch(
          updateUniqueBodyOfExpertiseDocuments(
            requestState.uniqueBodyOfExpertiseUploadFiles ?? []
          )
        );
        break;
      default:
        break;
    }
  };
  const handleRemoveUploadFile = (
    doc: UploadFile,
    documentType: DocumentType
  ) => {
    if (doc && doc.fileName) {
      switch (documentType) {
        case DocumentType.Child:
          let combinedChild = uploadChildDocument?.filter(
            (v) => v.fileName !== doc.fileName
          );
          setUploadChildDocument(combinedChild ?? []);
          dispatch(updateChildDocuments(combinedChild ?? []));
          break;
        case DocumentType.Medical:
          let combinedMedical = uploadMedicalDocument?.filter(
            (v) => v.fileName !== doc.fileName
          );
          setUploadMedicalDocument(combinedMedical ?? []);
          dispatch(updateMedicalDocuments(combinedMedical ?? []));
          break;
        case DocumentType.Psychology:
          let combinedPsychology = uploadPsychologyDocument?.filter(
            (v) => v.fileName !== doc.fileName
          );
          setUploadPsychologyDocument(combinedPsychology ?? []);
          dispatch(updatePsychologyDocuments(combinedPsychology ?? []));
          break;
        case DocumentType.EducationalRehabilitation:
          let combinedEducationalRehabilitation = uploadEducationalRehabilitationDocument?.filter(
            (v) => v.fileName !== doc.fileName
          );
          setUploadEducationalRehabilitationDocument(
            combinedEducationalRehabilitation ?? []
          );
          dispatch(
            updateEducationalRehabilitationDocuments(
              combinedEducationalRehabilitation ?? []
            )
          );
          break;
        case DocumentType.OtherParent:
          let combinedOther = uploadOtherDocument?.filter(
            (v) => v.fileName !== doc.fileName
          );
          setUploadOtherDocument(combinedOther ?? []);
          dispatch(updateOtherParentDocuments(combinedOther ?? []));
          break;
        case DocumentType.PsychophysicalConditionDecision:
          let psychophysicalConditionDecision = uploadPsychophysicalConditionDecisionDocument?.filter(
            (v) => v.fileName !== doc.fileName
          );
          setUploadPsychophysicalConditionDecisionDocument(
            psychophysicalConditionDecision ?? []
          );
          dispatch(
            updatePsychophysicalConditionDecisionDocuments(
              psychophysicalConditionDecision ?? []
            )
          );
          break;
        case DocumentType.UniqueBodyOfExpertise:
          let uniqueBodyOfExpertise = uploadUniqueBodyOfExpertiseDocument?.filter(
            (v) => v.fileName !== doc.fileName
          );
          setUploadUniqueBodyOfExpertiseDocument(uniqueBodyOfExpertise ?? []);
          dispatch(
            updateUniqueBodyOfExpertiseDocuments(uniqueBodyOfExpertise ?? [])
          );
          break;
        default:
          break;
      }
    }
  };
  const handleRemoveAttachment = (
    doc: Attachment,
    documentType: DocumentType
  ) => {
    if (doc && doc.id) {
      switch (documentType) {
        case DocumentType.Child:
          dispatch(
            updateChildAttachments(
              requestState.childAttachments?.filter((v) => v.id !== doc.id) ??
                []
            )
          );
          break;
        case DocumentType.Medical:
          dispatch(
            updateMedicalAttachments(
              requestState.medicalAttachments?.filter((v) => v.id !== doc.id) ??
                []
            )
          );
          break;
        case DocumentType.Psychology:
          dispatch(
            updatePshilogyAttachments(
              requestState.psychologyAttachments?.filter(
                (v) => v.id !== doc.id
              ) ?? []
            )
          );
          break;
        case DocumentType.EducationalRehabilitation:
          dispatch(
            updateEducationalRehabilitationAttachments(
              requestState.educationalRehabilitationAttachments?.filter(
                (v) => v.id !== doc.id
              ) ?? []
            )
          );
          break;
        case DocumentType.OtherParent:
          dispatch(
            updateOtherParentAttachments(
              requestState.otherAttachments?.filter((v) => v.id !== doc.id) ??
                []
            )
          );
          break;
        case DocumentType.PsychophysicalConditionDecision:
          dispatch(
            updatePsychophysicalConditionDecisionAttachments(
              requestState.psychophysicalConditionDecisionAttachments?.filter(
                (v) => v.id !== doc.id
              ) ?? []
            )
          );
          break;
        case DocumentType.UniqueBodyOfExpertise:
          dispatch(
            updateUniqueBodyOfExpertiseAttachments(
              requestState.uniqueBodyOfExpertiseAttachments?.filter(
                (v) => v.id !== doc.id
              ) ?? []
            )
          );
          break;
        default:
          break;
      }
    }
  };
  const handleDocumentModalClose = async () => {
    setShowUploadModal(undefined);
  };
  const handleDocumentDownload = async (
    doc: Attachment,
    type: DocumentType
  ) => {
    if (doc && doc.id) {
      await getRequestDocument(doc.id, requestState.id!, type);
    }
  };
  const getDocumentFormCard = (
    title: string,
    subTitle: string,
    type: DocumentType,
    editable: boolean,
    attachments?: Attachment[],
    uplaodFiles?: UploadFile[]
  ) => {
    return (
      <>
        <RequestFormCard
          cardTitle={title}
          cardSubTitle={subTitle}
          showRefresh={editable}
          refreshTitle={t("documentUpload.attachDocument")}
          onClickRefresh={() => handleUploadDocumentClick(type)}
          key={type}
        >
          <Row>
            <Col md={6}>
              <Typography variant="baseBold" className="border-bottom">
                {t("request.documentAttachmentList")}
              </Typography>
              <DocumentList
                documents={attachments ?? []}
                onDocumentClick={(doc) => handleDocumentDownload(doc, type)}
                onRemoveClick={(doc) => handleRemoveAttachment(doc, type)}
                removable={editable}
              />
            </Col>
            <Col md={6}>
              <Typography
                variant="baseBold"
                color="light"
                className="border-bottom"
              >
                {t("request.documentUpdateFileList")}
              </Typography>
              <DocumentList
                documents={uplaodFiles ?? []}
                onRemoveClick={(doc) => handleRemoveUploadFile(doc, type)}
              />
            </Col>
          </Row>
        </RequestFormCard>
        <br />
      </>
    );
  };

  return (
    <>
      <RequestFormTopMessage
        title={
          requestState.type === RequestTypeEnum.RegularEnrollment
            ? t("documentDataStep.mainInfoTitleRU")
            : requestState.type ===
              RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties
            ? t("documentDataStep.mainInfoTitleRUTUR")
            : requestState.type === RequestTypeEnum.TemporaryExemption
            ? t("documentDataStep.mainInfoTitlePO")
            : requestState.type === RequestTypeEnum.EarlyEnrollment
            ? t("documentDataStep.mainInfoTitlePU")
            : ""
        }
        infoMessage={
          requestState.type === RequestTypeEnum.RegularEnrollment
            ? t("documentDataStep.mainInfoMessageRU")
            : requestState.type ===
              RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties
            ? t("documentDataStep.mainInfoMessageRUTUR")
            : requestState.type === RequestTypeEnum.TemporaryExemption
            ? t("documentDataStep.mainInfoMessagePO")
            : requestState.type === RequestTypeEnum.EarlyEnrollment
            ? t("documentDataStep.mainInfoMessagePU")
            : ""
        }
      />
      {!!errorMessage && (
        <InfoPanel color={"danger"} message={errorMessage}></InfoPanel>
      )}
      {/*PsychophysicalConditionDecision*/}
      {(requestState.type === RequestTypeEnum.EarlyEnrollment ||
        requestState.psychophysicalConditionDecisionAttachments?.some(
          (v) => true
        )) &&
        getDocumentFormCard(
          t("documentDataStep.titleChild"),
          t(
            "documentUpload.documentReview.psychophysicalConditionDecisionDocuments"
          ),
          DocumentType.PsychophysicalConditionDecision,
          requestState.type === RequestTypeEnum.EarlyEnrollment,
          requestState.psychophysicalConditionDecisionAttachments,
          uploadPsychophysicalConditionDecisionDocument
        )}
      {/*UniqueBodyOfExpertise*/}
      {(requestState.type === RequestTypeEnum.TemporaryExemption ||
        requestState.uniqueBodyOfExpertiseAttachments?.some((v) => true)) &&
        getDocumentFormCard(
          t("documentDataStep.titleChild"),
          t("documentUpload.documentReview.uniqueBodyOfExpertiseDocuments"),
          DocumentType.UniqueBodyOfExpertise,
          requestState.type === RequestTypeEnum.TemporaryExemption,
          requestState.uniqueBodyOfExpertiseAttachments,
          uploadUniqueBodyOfExpertiseDocument
        )}
      {/* MEDICINSKA DOKUMENATACIJA */}
      {(requestState.type ===
        RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
        requestState.type === RequestTypeEnum.TemporaryExemption ||
        requestState.medicalAttachments?.some((v) => true)) &&
        getDocumentFormCard(
          t("documentDataStep.titleChild"),
          t("documentDataStep.medicalSubTitle"),
          DocumentType.Medical,
          requestState.type ===
            RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
            requestState.type === RequestTypeEnum.TemporaryExemption,
          requestState.medicalAttachments,
          uploadMedicalDocument
        )}

      {/* PSIHOLOŠKA DOKUMENTACIJA */}
      {(requestState.type ===
        RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
        requestState.type === RequestTypeEnum.TemporaryExemption ||
        requestState.psychologyAttachments?.some((v) => true)) &&
        getDocumentFormCard(
          t("documentDataStep.titleChild"),
          t("documentDataStep.psychologySubTitle"),
          DocumentType.Psychology,
          requestState.type ===
            RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
            requestState.type === RequestTypeEnum.TemporaryExemption,
          requestState.psychologyAttachments,
          uploadPsychologyDocument
        )}
      {/* EDUKACIJSKO-REHABILITACIJSKA  DOKUMENTACIJA */}
      {(requestState.type ===
        RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
        requestState.type === RequestTypeEnum.TemporaryExemption ||
        requestState.educationalRehabilitationAttachments?.some((v) => true)) &&
        getDocumentFormCard(
          t("documentDataStep.titleChild"),
          t("documentDataStep.educationalRehabilitationSubTitle"),
          DocumentType.EducationalRehabilitation,
          requestState.type ===
            RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
            requestState.type === RequestTypeEnum.TemporaryExemption,
          requestState.educationalRehabilitationAttachments,
          uploadEducationalRehabilitationDocument
        )}
      {/* OSTALA DOKUMENTACIJA ZA DIJETE */}
      {(requestState.type === RequestTypeEnum.RegularEnrollment ||
        requestState.type ===
          RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
        requestState.type === RequestTypeEnum.TemporaryExemption ||
        requestState.type === RequestTypeEnum.EarlyEnrollment) &&
        getDocumentFormCard(
          t("documentDataStep.titleChild"),
          requestState.type === RequestTypeEnum.RegularEnrollment
            ? t("documentDataStep.childSubTitleRU")
            : t("documentDataStep.childSubTitleRUTUR"),
          DocumentType.Child,
          true,
          requestState.childAttachments,
          uploadChildDocument
        )}
      {!!errorMessageParent2 && (
        <InfoPanel color={"danger"} message={errorMessageParent2}></InfoPanel>
      )}

      {/* RODITELJ 2 DODATNA DOKUEMNTACIJA */}
      {hasTwoParent() &&
        !isParentTwoHasOIB() &&
        (requestState.type === RequestTypeEnum.RegularEnrollment ||
          requestState.type ===
            RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
          requestState.type === RequestTypeEnum.TemporaryExemption ||
          requestState.type === RequestTypeEnum.EarlyEnrollment) &&
        getDocumentFormCard(
          t("documentDataStep.titleOther"),
          t("documentDataStep.mainAlternativDocumentUpoad"),
          DocumentType.OtherParent,
          true,
          requestState.otherAttachments,
          uploadOtherDocument
        )}
      <DocumentsModal
        title={t("documentUpload.documentModal.title")}
        infoPanelText={t("documentUpload.documentModal.info")}
        types={t("documentUpload.documentModal.types")}
        allDocuments={[]}
        maxSize={10}
        info={""}
        filesAction={handleFileChange}
        open={showUploadModal !== undefined}
        onClose={handleDocumentModalClose}
        onConfirm={handleUploadFileChange}
        hideOldDocuments={true}
        btnCancelText={t("common.quit")}
        btnOkText={t("common.addNew")}
      />
    </>
  );
}
