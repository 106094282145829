import { useTypedSelector } from "../../app/rootReducer";
import ParentNumberEnum from "../../models/Person/ParentNumberEnum";
import { EnrollmentDecision } from "../../models/Request/EnrollmentDecision";
import RequestStatusEnum from "../../models/Request/RequestStatusEnum";
import RequestTypeEnum from "../../models/Request/RequestTypeEnum";
import { useAuth } from "../auth";

export const useBusinessRule = () => {
  const auth = useAuth();
  const request = useTypedSelector((state) => state.request.request);
  const enrollmentDecision: EnrollmentDecision = useTypedSelector(
    (state) => state.enrollmentDecisione.enrollmentDecision
  );

  // ToDo ne koristi se
  const isLoginEnabled = () => {
    return true;
  };
  // const isLoginEnabled = useCallback(() => {
  //   return (
  //     schoolYear.currentImportantDate.eventId &&
  //     schoolYear.currentImportantDate.eventId !==
  //       ImportantDateEventEnum.InstitutionAdministration
  //   );
  // }, [schoolYear.currentImportantDate.eventId]);
  // REQUEST
  const isApplicantParent = () => {
    return request?.applicantParentDetails?.person?.oib === auth.user?.oib;
  };
  const isApplicantParentTwo = () => {
    return (
      request?.applicantParentDetails?.person?.oib !== auth.user?.oib &&
      request?.otherParentDetails?.person?.oib === auth.user?.oib
    );
  };
  const isParentTwoHasOIB = () => {
    return (request?.applicantParentDetails?.parentNumberId ===
      ParentNumberEnum.TwoParents &&
      request.otherParentDetails?.person?.oib) as boolean;
  };
  const hasOneParent = () => {
    return (
      request?.applicantParentDetails?.parentNumberId ===
      ParentNumberEnum.OneParent
    );
  };

  const hasTwoParent = () => {
    return (
      request?.applicantParentDetails?.parentNumberId ===
      ParentNumberEnum.TwoParents
    );
  };
  // REQUEST STATUS
  const isRequestDraft = () => {
    return (request?.statusId === RequestStatusEnum.Draft ||
      !request?.statusId) as boolean;
  };
  const isRequestProcedureSubmitted = () => {
    return (request?.statusId &&
      request.statusId === RequestStatusEnum.ProcedureSubmitted) as boolean;
  };
  const isRequestCancelled = () => {
    return (request?.statusId &&
      request.statusId === RequestStatusEnum.Cancelled) as boolean;
  };

  const isRequestSubmitted = () => {
    return (request?.statusId &&
      request.statusId === RequestStatusEnum.Submitted) as boolean;
  };

  // REQUEST TYPES
  const isRequestTypePU = () => {
    return (request?.type &&
      request.type === RequestTypeEnum.EarlyEnrollment) as boolean;
  };
  const isRequestTypePO = () => {
    return (request?.type &&
      request.type === RequestTypeEnum.TemporaryExemption) as boolean;
  };
  const isRequestTypeRU = () => {
    return (request?.type &&
      request.type === RequestTypeEnum.RegularEnrollment) as boolean;
  };
  const isRequestTypeRUTUR = () => {
    return (request?.type &&
      request.type ===
        RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties) as boolean;
  };

  // ENROLLMENT DECISION
  const hasRegistration = () => {
    return (request.statusId === RequestStatusEnum.RegistrationFormCreated ||
      request.statusId === RequestStatusEnum.Registered) as boolean;
  };
  const hasEnrollmentDecision = () => {
    return (enrollmentDecision && !!enrollmentDecision?.solutionId) as boolean;
  };

  return {
    isLoginEnabled,
    isApplicantParent,
    isApplicantParentTwo,
    isParentTwoHasOIB,
    hasOneParent,
    hasTwoParent,
    isRequestDraft,
    isRequestProcedureSubmitted,
    isRequestCancelled,
    isRequestSubmitted,
    isRequestTypePU,
    isRequestTypePO,
    isRequestTypeRU,
    isRequestTypeRUTUR,
    hasRegistration,
    hasEnrollmentDecision,
  };
};
