import {
  RadioButton,
  RadioGroup,
  Select,
  SelectData,
  TextArea,
  TextField,
} from "@king-ict/eupisi-ui/components";
import { nameof } from "@king-ict/eupisi-ui/utils";
import { useTranslation } from "react-i18next";

import useInput from "../../../hooks/input/useInput";
import QuestionDataTypeEnum from "../../../models/Questionnaire/QuestionDataTypeEnum";
import QuestionDisplayTypeEnum from "../../../models/Questionnaire/QuestionDisplayTypeEnum";
import QuestionnaireAnswer from "../../../models/Questionnaire/QuestionnaireAnswer";
import QuestionnaireQuestion from "../../../models/Questionnaire/QuestionnaireQuestion";
import QuestionnaireQuestionDto from "../../../models/Request/InitialConversationQuestionDataItem";
interface Props {
  stateIndex: number;
  question: QuestionnaireQuestion;
  conversationItem: QuestionnaireQuestionDto;
  errorText?: string;
  setState: (prevState: any) => void;
}

export default function QuestionComponentFactory({
  stateIndex,
  question,
  conversationItem,
  errorText,
  setState,
}: Props) {
  const {
    getIndexedName,
    handleTextChange,
    handleNumberChange,
    handleNumberArrayChange,
  } = useInput();

  const { t } = useTranslation();

  //FIX for numbers bellow zerro
  const GetErrorNumberBellowZero = (
    displayType: QuestionDataTypeEnum,
    answer?: string
  ) => {
    if (displayType === QuestionDataTypeEnum.Integer && answer) {
      if (+answer < 0) return t("validations.questionaryNegativNumber");
    }
    return undefined;
  };

  const resolveTextFieldType = (displayType: QuestionDataTypeEnum) => {
    switch (displayType) {
      case QuestionDataTypeEnum.Integer:
        return "number";
      case QuestionDataTypeEnum.Text:
      default:
        return "text";
    }
  };
  switch (question.displayTypeId) {
    case QuestionDisplayTypeEnum.ReadOnly:
    case QuestionDisplayTypeEnum.Text:
      return (
        <>
          {(conversationItem.isVisible || conversationItem.isRepeatable) && (
            <TextField
              name={getIndexedName(
                stateIndex,
                nameof<typeof conversationItem>("answer")
              )}
              type={resolveTextFieldType(question.dataTypeId)}
              value={conversationItem.answer ?? undefined}
              label={question.name}
              disabled={
                question.displayTypeId === QuestionDisplayTypeEnum.ReadOnly
              }
              required={question.isRequired}
              maxLength={question.answerLength}
              onChange={(e) =>
                handleTextChange(
                  e,
                  stateIndex,
                  setState,
                  question.questionGroupQuestionId
                )
              }
              errorText={
                errorText ||
                GetErrorNumberBellowZero(
                  question.dataTypeId,
                  conversationItem?.answer
                )
              }
              min={0}
            />
          )}
        </>
      );
    case QuestionDisplayTypeEnum.TextArea:
      return (
        <TextArea
          name={getIndexedName(
            stateIndex,
            nameof<typeof conversationItem>("answer")
          )}
          value={conversationItem.answer ?? undefined}
          label={question.name}
          required={question.isRequired}
          maxLength={question.answerLength}
          rows={4}
          onChange={(e) =>
            handleTextChange(
              e,
              stateIndex,
              setState,
              question.questionGroupQuestionId
            )
          }
          errorText={errorText}
          ariaLabel={question.name}
        />
      );
    case QuestionDisplayTypeEnum.Select:
      return (
        <Select
          name={getIndexedName(
            stateIndex,
            nameof<typeof conversationItem>("answerId")
          )}
          value={conversationItem.answerId ?? ""}
          label={question.name}
          required={question.isRequired}
          selectData={
            {
              valueProp: "id",
              textProp: "name",
              data: [
                { id: 0, name: "" } as QuestionnaireAnswer,
                ...question.answers,
              ],
            } as SelectData<QuestionnaireAnswer>
          }
          onChange={(e) =>
            handleNumberChange(
              e,
              stateIndex,
              setState,
              question.questionGroupQuestionId
            )
          }
          errorText={errorText}
        />
      );
    case QuestionDisplayTypeEnum.MultiSelect:
      return (
        <Select
          name={getIndexedName(
            stateIndex,
            nameof<typeof conversationItem>("answerIds")
          )}
          value={conversationItem.answerIds ?? []}
          label={question.name}
          required={question.isRequired}
          multiple
          selectData={
            {
              valueProp: "id",
              textProp: "name",
              data: [...question.answers],
            } as SelectData<QuestionnaireAnswer>
          }
          onChange={(e) =>
            handleNumberArrayChange(
              e,
              stateIndex,
              setState,
              question.questionGroupQuestionId
            )
          }
          errorText={errorText}
        />
      );
    case QuestionDisplayTypeEnum.Radio:
      return (
        <RadioGroup
          name={getIndexedName(
            stateIndex,
            nameof<typeof conversationItem>("answerId")
          )}
          title={question.name}
          required={question.isRequired}
          onChange={(e) =>
            handleNumberChange(
              e,
              stateIndex,
              setState,
              question.questionGroupQuestionId
            )
          }
          errorText={errorText}
        >
          {question.answers.map((a) => (
            <RadioButton
              key={a.id}
              label={a.name}
              value={a.id}
              checked={+(conversationItem?.answerId ?? 0) === a.id}
              ariaLabel={t("accessibility.radioAnswer")}
            />
          ))}
        </RadioGroup>
      );
    default:
      return <></>;
  }
}
