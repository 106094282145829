import { useEffect, useState } from "react";

import {
  Button,
  InfoPanel,
  Modal,
  Typography,
} from "@king-ict/eupisi-ui/components";
import qs from "qs";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

import { cancelRequestByRequestId } from "../../../api/Request/RequestApi";
import { RootState } from "../../../app/rootReducer";
import { useAppDispatch } from "../../../app/store";
import { useAuth } from "../../../hooks/auth";
import { useBusinessRule } from "../../../hooks/businessRule/useBusinessRule";
import InstitutionDetails from "../../../models/Institution/InstitutionDetails";
import ParentNumberEnum from "../../../models/Person/ParentNumberEnum";
import { CommercialProgram } from "../../../models/Request/CommercialProgram";
import RequestStatusEnum from "../../../models/Request/RequestStatusEnum";
import RequestTypeEnum from "../../../models/Request/RequestTypeEnum";
import CodeBook from "../../../models/Shared/CodeBook";
import {
  useGetCitizenshipsQuery,
  useGetCountriesQuery,
  useGetGendersQuery,
  useGetNationalitiesQuery,
  useGetNationalMinoritiesQuery,
  useGetParentshipTypesQuery,
  useGetProfessionalQualificationsQuery,
} from "../../../queries/dataQuery";
import { useGetInstitutionDetailsQuery } from "../../../queries/dataQuery";
import { childrenList, dashboard } from "../../../routes/routes";
import {
  updateRequestStep,
  updateRequestSubStep,
} from "../../../slices/requestSlice";
import ApplicantParentDetails from "../../RequestInfo/RequestDetails/ApplicantParentDetails";
import ChildDetails from "../../RequestInfo/RequestDetails/ChildDetails";
import DocumentationDetails from "../../RequestInfo/RequestDetails/DocumentationDetails";
import InitialConversationDetails from "../../RequestInfo/RequestDetails/InitialConversationDetails";
import InstitutionProgramDetails from "../../RequestInfo/RequestDetails/InstitutionProgramDetails";
import OtherParentDetails from "../../RequestInfo/RequestDetails/OtherParentDetails";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";

interface Props {
  moveToSpecificStep: (step: number, substep?: number) => void;
}

export default function RequestReviewStep({ moveToSpecificStep }: Props) {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const params = qs.parse(location.search.substring(1));
  const { id } = params;
  const { t } = useTranslation();

  const request = useSelector((state: RootState) => state.request.request);

  const getCommercialPrograms = (
    institution: InstitutionDetails | undefined
  ): CodeBook[] => {
    var arrayObjects = [] as CodeBook[];
    for (const [propertyKey, propertyValue] of Object.entries(
      CommercialProgram
    )) {
      var num = Number(propertyKey);
      if (Number.isNaN(Number(propertyKey))) {
        continue;
      }
      var tranKey = "commercialProgram." + propertyValue;
      var trans = t(tranKey);
      arrayObjects.push({ id: num, name: trans });
    }
    if (!(institution?.institutionSchoolYearOther?.allDayClass ?? false))
      arrayObjects = arrayObjects.filter(
        (v) => v.id !== CommercialProgram.FullDayClasses
      );
    if (!(institution?.institutionSchoolYearOther?.extendedStayClass ?? false))
      arrayObjects = arrayObjects.filter(
        (v) => v.id !== CommercialProgram.ExtendedStay
      );
    return arrayObjects;
  };

  const { data: primaryInstitution } = useGetInstitutionDetailsQuery({
    institutionId: request?.institutionId ?? 0,
    schoolYear: request?.schoolYearId!,
  });

  const { data: secondaryInstitution } = useGetInstitutionDetailsQuery({
    institutionId:
      request?.outsideEnrollmentAreaInstitutionProgram?.institutionId ?? 0,
    schoolYear: request?.schoolYearId!,
  });

  const [
    primaryInstitutonCommercialProgram,
    setPrimaryInstitutionCommercialProgram,
  ] = useState<CodeBook>();
  const [
    secondaryInstitutonCommercialProgram,
    setSecondaryInstitutionCommercialProgram,
  ] = useState<CodeBook>();

  const [validRequest, setValidRequest] = useState<boolean>(false);

  const auth = useAuth();

  useEffect(() => {
    //Prijevremeni upis nema postavljanja ustanove
    if (request.type !== RequestTypeEnum.EarlyEnrollment) {
      let primaryCommercialProgram = getCommercialPrograms(
        primaryInstitution
      ).filter((x) => x.id === request.commercialProgramId);

      setPrimaryInstitutionCommercialProgram(primaryCommercialProgram[0]);

      if (request.outsideEnrollmentAreaCommercialProgramId !== undefined) {
        let secondaryCommercialProgram = getCommercialPrograms(
          secondaryInstitution
        ).filter(
          (x) => x.id === request.outsideEnrollmentAreaCommercialProgramId
        );

        setSecondaryInstitutionCommercialProgram(secondaryCommercialProgram[0]);
      }
    }

    if (
      request.id === undefined ||
      request.id === null ||
      Number(id) !== request.id
    ) {
      setValidRequest(false);
      //history.push(childrenList());
    } else {
      if (Number(id) === request.id) {
        if (isParentRequestAllowed() === true) {
          setValidRequest(true);
        } else {
          setValidRequest(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, validRequest]);

  const { hasTwoParent, isParentTwoHasOIB, isRequestDraft } = useBusinessRule();

  const [openRequestSuccessModal, setOpenRequestSuccessModal] = useState(false);
  const [
    openCancelSubmittedRequestModal,
    setOpenCancelSubmittedRequestModal,
  ] = useState(false);

  const history = useHistory();

  const isParentRequestAllowed = () => {
    if (request.applicantParentDetails.person.oib !== auth.user?.oib) {
      return false;
    } else {
      return true;
    }
  };

  const handleToggleRequestSuccessModal = () => {
    setOpenRequestSuccessModal(
      (openRequestSuccessModal) => !openRequestSuccessModal
    );
  };

  const handleToggleOpenCancelSuccessModal = () => {
    setOpenCancelSubmittedRequestModal(
      (openCancelSubmittedRequestModal) => !openCancelSubmittedRequestModal
    );
  };

  const handleMoveToSpecificStep = (step: number, subStep: number) => {
    dispatch(updateRequestStep(step));
    dispatch(updateRequestSubStep(subStep));
    if (moveToSpecificStep) moveToSpecificStep(step, subStep);
  };

  const emptyValue = "-";

  const { data: nationalities } = useGetNationalitiesQuery();
  const { data: citizenships } = useGetCitizenshipsQuery();
  const { data: nationalMinorities } = useGetNationalMinoritiesQuery();
  const { data: countries } = useGetCountriesQuery();
  const { data: genders } = useGetGendersQuery();
  const {
    data: professionalQualifications,
  } = useGetProfessionalQualificationsQuery();
  const { data: parentshipTypes } = useGetParentshipTypesQuery();

  const GetMainInfoTitle = (isDraft: boolean, type: RequestTypeEnum) => {
    let title = "";
    if (isDraft) {
      switch (type) {
        case RequestTypeEnum.RegularEnrollment:
        case RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties:
        case RequestTypeEnum.TemporaryExemption:
        case RequestTypeEnum.EarlyEnrollment:
          title = "Molimo da još jednom provjerite sve stavke Vašega zahtjeva.";
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case RequestTypeEnum.RegularEnrollment:
        case RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties:
          title = "Pregled Vašeg zahtjeva za upis.";
          break;
        case RequestTypeEnum.TemporaryExemption:
          title = "Pregled Vašeg zahtjeva za privremeno oslobađanje.";
          break;
        case RequestTypeEnum.EarlyEnrollment:
          title = "Pregled Vašeg zahtjeva za prijevremeni upis.";
          break;
        default:
          break;
      }
    }
    return title;
  };
  const GetMainInfoMessage = (isDraft: boolean, type: RequestTypeEnum) => {
    let title = "";
    if (isDraft) {
      switch (type) {
        case RequestTypeEnum.RegularEnrollment:
        case RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties:
        case RequestTypeEnum.EarlyEnrollment:
          title =
            "U slučaju da je potrebno izmijeniti jedan od unesenih podataka vratite se na odgovarajući korak predaje zahtjeva.";
          break;
        case RequestTypeEnum.TemporaryExemption:
          title =
            "Odabirom potvrde zahtjeva Vaš zahtjev za privremeno oslobađanje djeteta od upisa u prvi razred osnove škole biti će proslijeđen Upravnom odjelu koje će donijeti Rješenje o oslobađanju upisa.";
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case RequestTypeEnum.RegularEnrollment:
        case RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties:
          title =
            "U slučaju da je potrebno izmijeniti neki podataka obratite se administratoru škole.";
          break;
        case RequestTypeEnum.TemporaryExemption:
          title = "Pregled Vašeg zahtjeva za privremeno oslobađanje.";
          break;
        case RequestTypeEnum.EarlyEnrollment:
          title =
            request.statusId === RequestStatusEnum.OnSchoolCandidateList
              ? "Molimo popunite podatke o osnovnoj školi te potvrdite unos."
              : "Pregled Vašeg zahtjeva za prijevremeni upis.";
          break;
        default:
          break;
      }
    }
    return title;
  };

  /**
   * Only early enrollment or temporary exemption types can dismiss submitted request
   * @returns {boolean} - Get boolean value if request can be dismissed
   */
  const canDismissRequest = (): boolean => {
    if (
      (request?.type === RequestTypeEnum.EarlyEnrollment ||
        request?.type === RequestTypeEnum.TemporaryExemption) &&
      request?.statusId === RequestStatusEnum.ProcedureSubmitted
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Dismiss request - only earlyEnrollment or temporaryExemption request types can be dismissed
   * @returns {void} - dismiss request and redirect to dashboard
   */
  const dismissRequest = async () => {
    if (request.id) {
      await cancelRequestByRequestId(request.id!).then((response) => {
        history.push(childrenList());
      });
    }
  };

  //Used to display message on modal
  const dismissModalMessage = (
    <>
      <Typography component="div" variant="medium">
        {t("requestDetails.dismissRequestModalMessageAdditionalInfo")}
        <br />
        {t("requestDetails.dismissRequestModalQuestion")}
      </Typography>
    </>
  );

  return (
    <>
      {validRequest ? (
        <>
          <RequestFormTopMessage
            title={GetMainInfoTitle(isRequestDraft(), request.type)}
            infoMessage={GetMainInfoMessage(isRequestDraft(), request.type)}
          />
          <RequestFormCard
            cardTitle={
              isRequestDraft()
                ? t("requestReviewStep.header")
                : "Pregled predanog zahtjeva"
            }
          >
            <ApplicantParentDetails
              emptyValue={emptyValue}
              applicantParent={request.applicantParentDetails}
              countries={countries!}
              citizenships={citizenships!}
              nationalMinorities={nationalMinorities!}
              nationalities={nationalities!}
              parentshipTypes={parentshipTypes!}
              professionalQualifications={professionalQualifications!}
              genders={genders!}
              showEdit={isRequestDraft()}
              moveToSpecificStep={() => handleMoveToSpecificStep(0, 0)}
            />
            <hr />

            {request.parentNumberId === ParentNumberEnum.TwoParents &&
              request.otherParentDetails && (
                <>
                  <OtherParentDetails
                    emptyValue={emptyValue}
                    otherParent={request.otherParentDetails}
                    genders={genders!}
                    professionalQualifications={professionalQualifications!}
                    parentshipTypes={parentshipTypes!}
                    showEdit={isRequestDraft()}
                    moveToSpecificStep={() => handleMoveToSpecificStep(0, 1)}
                  />
                  <hr />
                </>
              )}

            <ChildDetails
              emptyValue={emptyValue}
              child={request.childDetails}
              countries={countries!}
              citizenships={citizenships!}
              nationalMinorities={nationalMinorities!}
              nationalities={nationalities!}
              genders={genders!}
              showEdit={isRequestDraft()}
              moveToSpecificStep={() => handleMoveToSpecificStep(1, 0)}
            />
            <hr />
            {(request.type === RequestTypeEnum.RegularEnrollment ||
              request.type ===
                RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
              (request.type === RequestTypeEnum.EarlyEnrollment &&
                request.statusId !== RequestStatusEnum.ProcedureSubmitted &&
                !isRequestDraft())) && (
              <>
                <InstitutionProgramDetails
                  emptyValue={emptyValue}
                  institution={primaryInstitution}
                  request={request}
                  primaryCommercialProgram={primaryInstitutonCommercialProgram}
                  secondaryCommercialProgram={
                    secondaryInstitutonCommercialProgram
                  }
                  secondaryInstitution={secondaryInstitution}
                  showEdit={isRequestDraft()}
                  moveToSpecificStep={() => handleMoveToSpecificStep(2, 0)}
                />
                <hr />
              </>
            )}

            {(request.type === RequestTypeEnum.RegularEnrollment ||
              request.type ===
                RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
              request.type === RequestTypeEnum.EarlyEnrollment) && (
              <>
                <InitialConversationDetails
                  requestID={request.id}
                  showEdit={isRequestDraft()}
                  moveToSpecificStep={() => handleMoveToSpecificStep(3, 0)}
                />
                <hr />
              </>
            )}
            <DocumentationDetails
              requestType={request.type}
              childDocuments={request.childAttachments ?? []}
              medicalDocuments={request.medicalAttachments ?? []}
              psychologyDocuments={request.psychologyAttachments ?? []}
              educationalRehabilitationDocuments={
                request.educationalRehabilitationAttachments ?? []
              }
              otherDocuments={request.otherAttachments ?? []}
              psychophysicalConditionDecisionDocuments={
                request.psychophysicalConditionDecisionAttachments ?? []
              }
              uniqueBodyOfExpertiseDocuments={
                request.uniqueBodyOfExpertiseAttachments ?? []
              }
              showOtherParentDocuments={hasTwoParent() && !isParentTwoHasOIB()}
              showEdit={isRequestDraft()}
              moveToSpecificStep={() => handleMoveToSpecificStep(4, 0)}
            />
            <hr />

            {canDismissRequest() && (
              <>
                <div className="request-form-card">
                  <Row>
                    <Col md={12} xs={6} className="text-right">
                      <Button
                        color="danger"
                        outline
                        size="sm"
                        onClick={handleToggleOpenCancelSuccessModal}
                      >
                        Storniraj
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </RequestFormCard>

          <Modal
            type={"message"}
            open={openRequestSuccessModal}
            confirmText={t("common.close")}
            onConfirm={() => history.push(dashboard())}
            onClose={() => handleToggleRequestSuccessModal()}
          ></Modal>

          <Modal
            children={dismissModalMessage}
            type={"confirm"}
            title={t("requestDetails.dismissRequestModalTitle")}
            open={openCancelSubmittedRequestModal}
            confirmText={t("requestDetails.dismissRequestConfirmModalYes")}
            cancelText={t("common.no")}
            onConfirm={() => dismissRequest()}
            onClose={() => handleToggleOpenCancelSuccessModal()}
          ></Modal>
        </>
      ) : (
        <>
          <InfoPanel
            message={t("common.defaultErrorMessage")}
            color="danger"
            fullWidth
          />
          <Row>
            <Col md={6} xs={6}>
              <Typography component="p">
                {t("request.unauthorizedAccessError")}
              </Typography>
            </Col>
            <Col md={6} xs={6} className="text-right">
              <Button
                onClick={() => {
                  history.push(childrenList());
                }}
                size="sm"
                color="primary"
                outline
              >
                {t("request.prevStepButton")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
