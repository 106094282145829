import { api } from "@king-ict/eupisi-ui/utils/apiUtils";

import { ErrorUserData } from "../../models/Error";
import { SaveResult } from "../../models/Shared/SaveResult";

const apiUrlPrefix: string = "error-reporting";

export const sendErrorReport = async (errorUserData: ErrorUserData) => {
  return api.post(`${apiUrlPrefix}/send`, errorUserData) as Promise<
    SaveResult<ErrorUserData>
  >;
};
