import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getEnrollmentDecisionByRequestId as getEnrollmentDecisionByRequestIdApi } from "../api/Request/RequestApi";
import { EnrollmentDecision } from "../models/Request/EnrollmentDecision";
import { RequestReviewStep } from "../models/Request/RequestStep";

interface EnrollmentDecisionState {
  enrollmentDecision: EnrollmentDecision;
  step: number;
}
let initialState: EnrollmentDecisionState = {
  enrollmentDecision: {} as EnrollmentDecision,
  step: RequestReviewStep.Review,
};

export const getEnrollmentDecisionByRequestId = createAsyncThunk(
  "request/enrollment-decision",
  async (id: number) => {
    const response = await getEnrollmentDecisionByRequestIdApi(id);
    return response;
  }
);

const enrollmentDecisioneSlice = createSlice({
  name: "enrollmentDecision",
  initialState: initialState,
  reducers: {
    clearEnrollmentDecisioneData(state) {
      state.enrollmentDecision = initialState.enrollmentDecision;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEnrollmentDecisionByRequestId.fulfilled,
      (state, { payload }) => {
        state.enrollmentDecision = { ...state.enrollmentDecision, ...payload };
      }
    );
  },
});

export const {
  clearEnrollmentDecisioneData,
} = enrollmentDecisioneSlice.actions;

export default enrollmentDecisioneSlice.reducer;
