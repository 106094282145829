export const adminWebAplicationUrl = process.env.REACT_APP_WEB_ADMIN;
export const webUserPortalAplicationUrl = process.env.REACT_APP_WEB_USER_PORTAL;
export const authRoute = `/auth`;

export const dashboard = (): string => "/dashboard";

export const aboutProgramRoute = "/aboutprogram";
export const helpAndSupportRoute = "/help-and-support";
export const faqGroupsAndItemsRoute = "/faq";
export const newErrorReporRoute = "/errorReport/new";
export const statementOfAccessibilityRoute = "/statement-of-accessibility";

export const importantDates = (): string => "/important-dates";
export const childrenList = (): string => "/children";

export const draftRequestUrl = (id: number): string =>
  `/requests/draft?id=${id}`;

export const draftRequestRoute = (): string => `/requests/draft`;

export const requestUrl = (id: number): string => `/requests?id=${id}`;

export const requestRoute = (): string => `/requests`;
