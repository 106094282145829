import { Typography } from "@king-ict/eupisi-ui/components";
import { hrDate } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";

import { ReactComponent as InfoIconBoy } from "../assets/images/boy.svg";
import { ReactComponent as InfoIconGirl } from "../assets/images/girl.svg";
import { GenderEnum } from "../models/Shared/GenderEnum";

export interface ChildDetailsProps {
  firstName?: string;
  lastName?: string;
  oib?: string;
  gender?: GenderEnum;
  dateOfBirth?: Date;
}

export const ChildDetails = ({
  firstName = "-",
  lastName = "-",
  oib,
  gender,
  dateOfBirth,
}: ChildDetailsProps) => {
  return (
    <>
      <Row className="d-flex flex-row align-items-center">
        <>
          <Col className="" xs={3} md={3}>
            {gender === GenderEnum.Male && <InfoIconBoy />}
            {gender === GenderEnum.Female && <InfoIconGirl />}
          </Col>
          <Col xs={9} md={9}>
            <Typography variant="mediumBold" component="h5">
              {firstName + " " + lastName}
            </Typography>
            <Typography component="h6">{"OIB " + oib}</Typography>
            <Typography component="h6">
              {(dateOfBirth && hrDate(dateOfBirth)) || "-"}
            </Typography>
          </Col>
        </>
      </Row>
    </>
  );
};
