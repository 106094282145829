enum QuestionDisplayTypeEnum {
  ReadOnly = 1,
  Text = 2,
  Select = 3,
  Radio = 4,
  Date = 5,
  Time = 6,
  Upload = 7,
  MultiSelect = 8,
  TextArea = 9,
}

export default QuestionDisplayTypeEnum;
