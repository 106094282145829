import { TFunction } from "i18next";
import * as yup from "yup";

export interface ErrorUserDataSchemeProps {
  t: TFunction;
}

export const ErrorUserDataScheme = ({ t }: ErrorUserDataSchemeProps) => {
  const requiredMsg = t("validations.required");
  const emailMsg = t("validations.emailValidation");

  return yup.object().shape({
    errorDetail: yup.string().required(requiredMsg),
    email: yup.string().email(emailMsg).required(requiredMsg),
  });
};
