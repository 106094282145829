import { useEffect, useState } from "react";

import {
  InfoPanel,
  MobileMenu,
  Spinner,
  Stepper,
  StepperProvider,
  useStepper,
} from "@king-ict/eupisi-ui/components";
import { useBreakpoint } from "@king-ict/eupisi-ui/hooks";
import { UploadFile } from "@king-ict/eupisi-ui/models";
import { unwrapResult } from "@reduxjs/toolkit";
import qs from "qs";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { useTypedSelector } from "../../app/rootReducer";
import { useAppDispatch } from "../../app/store";
import { useBusinessRule } from "../../hooks/businessRule/useBusinessRule";
import Request from "../../models/Request/Request";
import RequestStatusEnum from "../../models/Request/RequestStatusEnum";
import { RequestReviewStep as RequestReviewStepEnum } from "../../models/Request/RequestStep";
import { useGetCurrentSchoolYearQuery } from "../../queries/dataQuery";
import {
  clearEnrollmentDecisioneData,
  getEnrollmentDecisionByRequestId,
} from "../../slices/enrollmentDecisionSlice";
import { getRequestById } from "../../slices/requestSlice";
import EnrollmentDecisionStep from "./EnrollmentDecisionStep/EnrollmentDecisionStep";
import EnrollmentStep from "./EnrollmentStep/EnrollmentStep";
import InstitutionDataStep from "./InstitutionDataStep/InstitutionDataStep";
import styles from "./RequestPage.module.scss";
import RequestReviewStep from "./RequestReviewStep/RequestReviewStep";
import RequestReviewStepParentTwo from "./RequestReviewStep/RequestReviewStepParentTwo";
import RequestStepperActions from "./RequestStepperActions";
import RequestStepperHeader from "./RequestStepperHeader";
import { RequestStepperHeaderButtons } from "./RequestStepperHeaderButtons";

/**
 * If you are looking for the routing for the component, look in
 * Layout.tsx which contains the routing logic.
 * @returns
 */
export default function RequestPage() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = qs.parse(location.search.substring(1));
  const { id } = params;
  const { t } = useTranslation();
  const sizeBreakpoint = useBreakpoint();

  const {
    isRequestTypePO,
    isRequestTypePU,
    hasEnrollmentDecision,
    hasRegistration,
  } = useBusinessRule();

  const { data: schoolYear } = useGetCurrentSchoolYearQuery();
  const request: Request = useTypedSelector((state) => state.request.request);

  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isApplicantParentTwo, isApplicantParent } = useBusinessRule();

  useEffect(() => {
    setIsLoading(true);
    dispatch(clearEnrollmentDecisioneData());
    stepperApi.moveToSpecific(RequestReviewStepEnum.Review);
    if (id) {
      dispatch(getRequestById(+id))
        .then(unwrapResult)
        .then((results) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (id) {
      dispatch(getEnrollmentDecisionByRequestId(+id))
        .then(unwrapResult)
        .then((response) => {})
        .finally(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, history, schoolYear]);

  const stepReview = [
    {
      title: isRequestTypePO()
        ? t("request.steps.requestStepTE")
        : t("request.steps.requestStep"),
      substeps: [],
    },
  ];
  const stepDecision = {
    title: hasEnrollmentDecision() ? t("request.steps.decisionStep") : "-",
    substeps: [],
  };
  const stepRegistration = {
    title: hasRegistration() ? t("request.steps.enrollmentStep") : "-",
    substeps: [],
  };
  const stepInstitution = {
    title: "Ažuriranje ustanove",
    substeps: [],
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const getSteps = () => {
    if (isApplicantParent()) {
      return isRequestTypePU() &&
        request.statusId === RequestStatusEnum.OnSchoolCandidateList
        ? [...stepReview, stepDecision, stepRegistration, stepInstitution]
        : hasRegistration()
        ? [...stepReview, stepDecision, stepRegistration]
        : hasEnrollmentDecision()
        ? [...stepReview, stepDecision]
        : [...stepReview];
    } else return [...stepReview];
  };
  const [stepperState, stepperApi] = useStepper(getSteps());

  let getStepContent = (step: number, subStep?: number) => {
    switch (step) {
      case RequestReviewStepEnum.Review:
        if (isApplicantParent())
          return <RequestReviewStep moveToSpecificStep={() => {}} />;
        else if (isApplicantParentTwo())
          return <RequestReviewStepParentTwo moveToSpecificStep={() => {}} />;
        else return null;
      case RequestReviewStepEnum.EnrollmentDecision:
        return <EnrollmentDecisionStep moveToSpecificStep={() => {}} />;
      case RequestReviewStepEnum.Registration:
        return (
          <EnrollmentStep
            filesToUploadParent={getFilesToUpload}
            canUpload={getCanUpload}
            moveToSpecificStep={() => {}}
          ></EnrollmentStep>
        );
      case RequestReviewStepEnum.InstitutionProgramData:
        return <InstitutionDataStep moveToSpecificStep={() => {}} />;
      default:
        return <InfoPanel message={"Unknown step"} color="danger" fullWidth />;
    }
  };

  const [canUpload, setCanUpload] = useState<boolean>(false);

  const getCanUpload = (val: boolean) => {
    setCanUpload(val);
  };

  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>();

  const getFilesToUpload = (val: any) => {
    setFilesToUpload(val);
  };

  if (isLoading) return <Spinner show={true} />;
  return (
    <div className={styles.stepperWrapper}>
      <StepperProvider>
        <div className={styles.stepperContentWrapper}>
          {sizeBreakpoint === "xs" || sizeBreakpoint === "sm" ? (
            <Row>
              <MobileMenu open={openMenu} onClose={handleCloseMenu}>
                <div className={styles.mobileMenuButtons}>
                  <RequestStepperHeaderButtons
                    currentStep={stepperState.currentStep}
                    currentSubstep={stepperState.currentSubstep}
                  />
                </div>
                <aside>
                  <Stepper data={getSteps()} {...stepperState} />
                </aside>
              </MobileMenu>
              <Col xs={12}>
                <RequestStepperHeader
                  currentStep={stepperState.currentStep}
                  currentSubstep={stepperState.currentSubstep}
                  openMobileMenu={handleOpenMenu}
                />
                {isLoading ? (
                  <Spinner show={true} />
                ) : (
                  getStepContent(
                    stepperState.currentStep,
                    stepperState.currentSubstep
                  )
                )}

                <RequestStepperActions
                  filesToUpload={filesToUpload}
                  canUpload={canUpload}
                  stepperState={stepperState}
                  stepperApi={stepperApi}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col md={3} className={styles.sidebar}>
                  <aside>
                    <Stepper data={getSteps()} {...stepperState} />
                  </aside>
                </Col>
                <Col md={9} className={styles.content}>
                  <RequestStepperHeader
                    currentStep={stepperState.currentStep}
                    currentSubstep={stepperState.currentSubstep}
                    openMobileMenu={handleOpenMenu}
                  />
                  {isLoading ? (
                    <Spinner show={true} />
                  ) : (
                    getStepContent(
                      stepperState.currentStep,
                      stepperState.currentSubstep
                    )
                  )}
                  <RequestStepperActions
                    filesToUpload={filesToUpload}
                    canUpload={canUpload}
                    stepperState={stepperState}
                    stepperApi={stepperApi}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      </StepperProvider>
    </div>
  );
}
