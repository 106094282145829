import { CodeBook } from "@king-ict/eupisi-ui/models";
import { TFunction } from "i18next";
import * as yup from "yup";

import { PersonIdentification } from "../Shared/PersonIdentification";
import { OibValidationStringScheme } from "../ValidationScheme/OibValidationScheme";
import ParentshipTypeEnum from "./ParentshipTypeEnum";

interface Props {
  t: TFunction;
}

export const OtherParentOibValidation = (t: TFunction) => {
  const requiredMsg = t("validations.required");
  const oibFormat = t("validations.oibFormat");
  const oibFormatValidation = t("validations.oibValidation");

  return {
    person: yup
      .object({
        id: yup.number().notRequired(),
        firstName: yup.string().notRequired(),
        lastName: yup.string().notRequired(),
        dateOfBirth: yup.date().required(requiredMsg),
      })
      .transform(optionalPersonIdentificationObject)
      .shape(
        {
          oib: yup.string().when("alternativeDocumentNumber", {
            is: (alternativeDocumentNumber: string) =>
              alternativeDocumentNumber,
            then: yup.string().nullable(),
            otherwise: OibValidationStringScheme({ t }),
          }),
          alternativeDocumentNumber: yup.string().when("oib", {
            is: (oib: string) => oib,
            then: yup.string().nullable(),
            otherwise: yup.string().required(requiredMsg),
          }),
          alternativeDocumentTypeId: yup.number().when("oib", {
            is: (oib: string) => oib,
            then: yup.number().nullable(),
            otherwise: yup.number().required(requiredMsg),
          }),
          alternativeDocumentReasonId: yup.number().when("oib", {
            is: (oib: string) => oib,
            then: yup.number().nullable(),
            otherwise: yup.number().required(requiredMsg),
          }),
        },
        [["oib", "alternativeDocumentNumber"]]
      ),
  };
};

const OtherParentValidationSchema = ({ t }: Props) => {
  const requiredMsg = t("validations.required");

  return yup.object().shape({
    ...OtherParentOibValidation(t),
    firstName: yup.string().trim().nullable().required(requiredMsg),
    lastName: yup.string().trim().nullable().required(requiredMsg),
    professionName: yup.string().trim().nullable().required(requiredMsg),
    dateOfBirth: yup.date().required(requiredMsg),
    email: yup.string().nullable().email(t("validations.email")),
    genderId: yup.number().required(requiredMsg),
    professionalQualificationId: yup.number().required(requiredMsg),
    parentshipTypeId: yup.number().required(requiredMsg),
    custody: yup.string().when("parentshipTypeId", {
      is: (parentshipTypeId: ParentshipTypeEnum) =>
        parentshipTypeId === ParentshipTypeEnum.Custodian,
      then: yup.string().trim().nullable().required(requiredMsg),
      otherwise: yup.string().nullable().notRequired(),
    }),
  });
};

export default OtherParentValidationSchema;

function optionalPersonIdentificationObject(object: PersonIdentification) {
  if (object) {
    return object;
  } else {
    return undefined;
  }
}

function optionalCodeBook(object: CodeBook) {
  if (object && object?.id) {
    return object;
  } else {
    return undefined;
  }
}
