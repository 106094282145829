enum ParentAnswerTypeEnum {
  Fullname = "FullName",
  BirthYear = "BirthYear",
  BirthPlace = "BirthPlace",
  Profession = "Profession",
  Qualifications = "Qualifications",
  Address = "Address",
  Mobile = "Mobile",
  Email = "Email",
}

export default ParentAnswerTypeEnum;
