import { ChangeEvent } from "react";

import { SelectEvent } from "@king-ict/eupisi-ui/components";

import { useAppDispatch } from "../../app/store";
import { updateInsertQuestionAnswer } from "../../slices/requestSlice";
import MultipleChangeInformation from "./MultipleChangeInformation";

const useInput = () => {
  const dispatch = useAppDispatch();

  const getIndexSuffix = (index: number) => {
    return `[${index}]`;
  };

  const getIndexedName = (index: number, name: string) => {
    return `${name}${getIndexSuffix(index)}`;
  };

  const resolveEventData = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | SelectEvent
    >
  ) => {
    const { name } = event.target;
    let value;

    const inputEvent = event as ChangeEvent<HTMLInputElement>;
    if (inputEvent && inputEvent.target.type === "checkbox") {
      value = inputEvent.target.checked;
    } else if (inputEvent && inputEvent.target.type === "radio") {
      value = parseInt(event.target.value);
    } else {
      value = event.target.value;
    }

    return {
      name: name,
      value: value,
    };
  };

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | SelectEvent
    >,
    setState: (previousState: any) => void
  ) => {
    const { name, value } = resolveEventData(event);

    if (name) {
      setState((previousState: any) => ({
        ...previousState,
        [name]: value,
      }));
    }
  };

  const handleTextChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | SelectEvent
    >,
    index: number,
    setState: (previousState: any) => void,
    questionId: number
  ) => {
    const { name, value } = resolveEventData(event);

    const answerText = event.target.value as string;

    if (questionId && answerText) {
      dispatch(updateInsertQuestionAnswer({ questionId, answerText }));
    }

    const resolvedName =
      name && name.includes(getIndexSuffix(index))
        ? name.replace(getIndexSuffix(index), "")
        : name;

    if (resolvedName) {
      setState((previousState: any) => {
        return previousState.map((prevItem: any, i: number) => {
          if (i === index) return { ...prevItem, [resolvedName]: value };
          return prevItem;
        });
      });
    }
  };

  const handleNumberChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | SelectEvent
    >,
    index: number,
    setState: (previousState: any) => void,
    questionId: number
  ) => {
    const { name, value } = resolveEventData(event);

    const answerId = event.target.value as number;

    if (questionId && answerId) {
      dispatch(updateInsertQuestionAnswer({ questionId, answerId }));
    }

    const resolvedName =
      name && name.includes(getIndexSuffix(index))
        ? name.replace(getIndexSuffix(index), "")
        : name;

    if (resolvedName) {
      setState((previousState: any) => {
        return previousState.map((prevItem: any, i: number) => {
          if (i === index) return { ...prevItem, [resolvedName]: value };
          return prevItem;
        });
      });
    }
  };

  const handleNumberArrayChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | SelectEvent
    >,
    index: number,
    setState: (previousState: any) => void,
    questionId: number
  ) => {
    const { name, value } = resolveEventData(event);

    const answerIds = event.target.value as number[];

    if (questionId && answerIds && answerIds.length > 0) {
      dispatch(updateInsertQuestionAnswer({ questionId, answerIds }));
    }

    const resolvedName =
      name && name.includes(getIndexSuffix(index))
        ? name.replace(getIndexSuffix(index), "")
        : name;

    if (resolvedName) {
      setState((previousState: any) => {
        return previousState.map((prevItem: any, i: number) => {
          if (i === index) return { ...prevItem, [resolvedName]: value };
          return prevItem;
        });
      });
    }
  };

  const handleMultipleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | SelectEvent
    >,
    valueProp: string,
    collection: any[],
    changeInformations: MultipleChangeInformation[],
    setState: (previousState: any) => void
  ) => {
    const { value } = resolveEventData(event);
    const valueItem = collection.find(
      (c) => (c[valueProp] as string) === value
    );

    setState((previousState: any) => ({
      ...previousState,
      ...changeInformations.reduce(
        (previous, current) => ({
          ...previous,
          [current.stateProp]: valueItem
            ? valueItem[current.valueProp]
            : undefined,
        }),
        {}
      ),
    }));
  };

  return {
    getIndexedName,
    handleChange,
    handleTextChange,
    handleNumberChange,
    handleNumberArrayChange,
    handleMultipleChange,
  };
};

export default useInput;
