import AnswerTypeEnum from "./AnswerTypeEnum";
import QuestionDataTypeEnum from "./QuestionDataTypeEnum";
import QuestionDisplayTypeEnum from "./QuestionDisplayTypeEnum";
import QuestionnaireAnswer from "./QuestionnaireAnswer";

export default interface QuestionnaireQuestion {
  id: number;
  questionGroupQuestionId: number;
  sort: number;
  name: string;
  dataTypeId: QuestionDataTypeEnum;
  displayTypeId: QuestionDisplayTypeEnum;
  isRequired: boolean;
  isRepeatable: boolean;
  isVisible: boolean;
  answerLength: number;
  answerSource: string;
  parentQuestionGroupQuestionId?: number;
  parentAnswerId?: number;
  answers: QuestionnaireAnswer[];
}

export const isEligibleForAutomaticAnswering = (
  question: QuestionnaireQuestion
) => {
  return question.answerSource != null;
};

export const isConditionallyShown = (question: QuestionnaireQuestion) => {
  return (
    question.parentQuestionGroupQuestionId != null &&
    question.parentAnswerId != null
  );
};

export const getAnswerTypeAndSubType = (question: QuestionnaireQuestion) => {
  const answerSourceParts = question.answerSource.split(".") || [];
  const answerType = answerSourceParts[0] as AnswerTypeEnum;
  const answerSubType = answerSourceParts[1];

  return { answerType: answerType, answerSubType: answerSubType };
};
