import { DocumentList, Typography } from "@king-ict/eupisi-ui/components";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { getRequestDocument } from "../../../api/Request/RequestApi";
import { useTypedSelector } from "../../../app/rootReducer";
import { DocumentType } from "../../../models/Request/DocumentType";
import RequestTypeEnum from "../../../models/Request/RequestTypeEnum";
import { Attachment } from "../../../models/Shared/Attachment";
import { RequestInfoPanel } from "../../Request/RequestComponents/RequestInfoPanel";

interface Props {
  requestType: RequestTypeEnum;
  childDocuments?: Attachment[];
  medicalDocuments?: Attachment[];
  psychologyDocuments?: Attachment[];
  educationalRehabilitationDocuments?: Attachment[];
  otherDocuments?: Attachment[];
  psychophysicalConditionDecisionDocuments?: Attachment[];
  uniqueBodyOfExpertiseDocuments?: Attachment[];
  showOtherParentDocuments: boolean;
  showEdit: boolean;
  moveToSpecificStep?: () => void;
}

export default function DocumentationDetails({
  requestType,
  childDocuments,
  medicalDocuments,
  psychologyDocuments,
  educationalRehabilitationDocuments,
  otherDocuments,
  psychophysicalConditionDecisionDocuments,
  uniqueBodyOfExpertiseDocuments,
  showOtherParentDocuments,
  showEdit,
  moveToSpecificStep,
}: Props) {
  const { t } = useTranslation();

  const requestState = useTypedSelector((state) => state.request.request);

  const handleDocumentDownload = async (
    doc: Attachment,
    type: DocumentType
  ) => {
    if (doc && doc.id) {
      await getRequestDocument(doc.id, requestState.id!, type);
    }
  };

  const getDocumentPanel = (
    title: string,
    type: DocumentType,
    documents?: Attachment[]
  ) => {
    return (
      <>
        <Col md={4}>
          <Typography variant="smallHeading">{title}</Typography>
          {documents && documents.length ? (
            <>
              <DocumentList
                documents={documents}
                fullWidth
                removable={false}
                onDocumentClick={(doc) => handleDocumentDownload(doc, type)}
              />
            </>
          ) : (
            <Typography variant="small">
              {t("documentUpload.noUploadedDocuments")}
            </Typography>
          )}
        </Col>
      </>
    );
  };

  return (
    <RequestInfoPanel
      title={t("documentUpload.additionalDocumentation")}
      showEdit={showEdit}
      onEditClick={moveToSpecificStep}
    >
      <Row>
        {/*UniqueBodyOfExpertise*/}
        {requestType === RequestTypeEnum.TemporaryExemption &&
          getDocumentPanel(
            t("documentUpload.documentReview.uniqueBodyOfExpertiseDocuments"),
            DocumentType.UniqueBodyOfExpertise,
            uniqueBodyOfExpertiseDocuments
          )}
        {(requestType ===
          RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
          requestType === RequestTypeEnum.TemporaryExemption) &&
          getDocumentPanel(
            t("documentUpload.documentReview.medicalDocuments"),
            DocumentType.Medical,
            medicalDocuments
          )}
        {(requestType ===
          RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
          requestType === RequestTypeEnum.TemporaryExemption) &&
          getDocumentPanel(
            t("documentUpload.documentReview.psychologyDocuments"),
            DocumentType.Psychology,
            psychologyDocuments
          )}
        {(requestType ===
          RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
          requestType === RequestTypeEnum.TemporaryExemption) &&
          getDocumentPanel(
            t(
              "documentUpload.documentReview.educationalRehabilitationDocuments"
            ),
            DocumentType.EducationalRehabilitation,
            educationalRehabilitationDocuments
          )}
        {/*Child*/}
        {(requestType ===
          RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties ||
          requestType === RequestTypeEnum.TemporaryExemption ||
          requestType === RequestTypeEnum.EarlyEnrollment) &&
          getDocumentPanel(
            t("documentUpload.documentReview.childDocuments"),
            DocumentType.Child,
            childDocuments
          )}
        {requestType === RequestTypeEnum.RegularEnrollment &&
          getDocumentPanel(
            t("documentUpload.documentReview.childDocuments"),
            DocumentType.Child,
            childDocuments
          )}
        {/*PsychophysicalConditionDecision*/}
        {requestType === RequestTypeEnum.EarlyEnrollment &&
          getDocumentPanel(
            t(
              "documentUpload.documentReview.psychophysicalConditionDecisionDocuments"
            ),
            DocumentType.PsychophysicalConditionDecision,
            psychophysicalConditionDecisionDocuments
          )}
        {/* Other parent/ Roditelj 2 */}
        {showOtherParentDocuments &&
          getDocumentPanel(
            t("documentUpload.documentReview.otherDocuments"),
            DocumentType.OtherParent,
            otherDocuments
          )}
      </Row>
    </RequestInfoPanel>
  );
}
