import { InfoPanel } from "@king-ict/eupisi-ui/components";
import { Row, Col } from "react-bootstrap";

export interface RequestFormOIBCheckMessageProps {
  checkOibAlert?: string;
  checkOibSuccess?: string;
}
export const RequestFormOIBCheckMessage = ({
  checkOibAlert,
  checkOibSuccess,
}: RequestFormOIBCheckMessageProps) => {
  return (
    <>
      <Row>
        <Col md={12}>
          {!checkOibAlert || (
            <InfoPanel
              color="danger"
              message=""
              dismissible={false}
              fullWidth={true}
              timeoutSec={6}
            >
              {checkOibAlert}
            </InfoPanel>
          )}
          {!checkOibSuccess || (
            <InfoPanel
              color="success"
              message=""
              dismissible={false}
              fullWidth={true}
              timeoutSec={6}
            >
              {checkOibSuccess}
            </InfoPanel>
          )}
        </Col>
      </Row>
    </>
  );
};
