import { CodeBook } from "@king-ict/eupisi-ui/models";

export interface InstitutionLanguageModel extends CodeBook {
  // The CodeBook Id is the LanguageId and the Name is the LanguageName.
  modelId: SchoolingModelEnum;
  modelName: string;
  isEnrollmentModule: boolean;
}

export enum SchoolingModelEnum {
  A = 1,
  B,
  C,
}
