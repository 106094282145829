import { createSlice } from "@reduxjs/toolkit";
interface ApplicationState {
  // user: User;
  isEdited: boolean;
}

const initialState: ApplicationState = {
  isEdited: false,
};

const applicationSlice = createSlice({
  name: "application",
  initialState: initialState,
  reducers: {
    setIsEdited(state) {
      return {
        ...state,
        isEdited: true,
      };
    },
    resetIsEdited(state) {
      return {
        ...state,
        isEdited: false,
      };
    },
  },
});

export const { setIsEdited, resetIsEdited } = applicationSlice.actions;
export default applicationSlice.reducer;
