import { CodeBook, UploadFile } from "@king-ict/eupisi-ui/models";

import InstitutionProgram from "../Institution/InstitutionProgram";
import ParentNumberEnum from "../Person/ParentNumberEnum";
import RequestAnswer from "../Questionnaire/RequestAnswer";
import { Attachment } from "../Shared/Attachment";
import { HierarchicalCodeBook } from "../Shared/HierarchicalCodeBook";
import QuestionnaireQuestionGroupDto from "./InitialConversationQuestionGroup";
import { PersonDetails } from "./PersonDetails";
import RequestStatus from "./RequestStatusEnum";
import { RequestStep } from "./RequestStep";
import RequestTypeEnum from "./RequestTypeEnum";

export default interface Request {
  id?: number;
  uId: string;
  code?: string;
  statusId: RequestStatus;
  parentNumberId: ParentNumberEnum;
  applicantParentDetails: PersonDetails;
  otherParentDetails: PersonDetails;
  childDetails: PersonDetails;
  type: RequestTypeEnum;
  step: RequestStep;
  subStep?: number;

  institutionOutsideResidenceReasonId?: number;
  initialConversationQuestionGroups: QuestionnaireQuestionGroupDto[];

  questionnaireAnswers: RequestAnswer[];
  otherInstitutionSelect: CodeBook[];
  isInstitutionOutsideResidence: boolean;
  schoolYearId: number;

  institutionId: number;
  outsideEnrollmentAreaInstitutionId?: number;
  institutionProgramId?: number;
  institutionProgram?: InstitutionProgram;
  isPrimaryInstitutionCommercialProgramSelected: boolean;
  isSecondaryInstitutionCommercialProgramSelected: boolean;
  commercialProgramId?: number;
  isOutsideEnrollmentArea: boolean;
  outsideEnrollmentAreaInstitutionProgramId?: number;
  outsideEnrollmentAreaInstitutionProgram?: InstitutionProgram;
  institutionChangeReason?: InstitutionChangeReason;
  institutionChangeReasonOther?: string;
  createdByAdmin: boolean;
  createdByAppUserId?: number;
  outsideEnrollmentAreaCommercialProgramId?: number;
  mandatoryForeignLanguages?: HierarchicalCodeBook[];
  electiveSubjects?: HierarchicalCodeBook[];
  languageSchoolingModels?: HierarchicalCodeBook[];
  foreignLanguages?: HierarchicalCodeBook[];

  childUploadFiles?: UploadFile[];
  childAttachments?: Attachment[];
  educationalRehabilitationUploadFiles?: UploadFile[];
  educationalRehabilitationAttachments?: Attachment[];
  medicalUploadFiles?: UploadFile[];
  medicalAttachments?: Attachment[];
  otherUploadFiles?: UploadFile[];
  otherAttachments?: Attachment[];
  parentUploadFiles?: UploadFile[];
  parentAttachments?: Attachment[];
  psychologyUploadFiles?: UploadFile[];
  psychologyAttachments?: Attachment[];
  psychophysicalConditionDecisionUploadFiles?: UploadFile[];
  psychophysicalConditionDecisionAttachments?: Attachment[];
  uniqueBodyOfExpertiseUploadFiles?: UploadFile[];
  uniqueBodyOfExpertiseAttachments?: Attachment[];
}

export const RequestInitial: Request = {
  uId: "",
  statusId: {} as RequestStatus,
  parentNumberId: 2,
  applicantParentDetails: {} as PersonDetails,
  otherParentDetails: {} as PersonDetails,
  childDetails: {} as PersonDetails,
  initialConversationQuestionGroups: [],

  questionnaireAnswers: [],
  createdByAdmin: false,
  createdByAppUserId: undefined,
  type: RequestTypeEnum.RegularEnrollment,
  institutionOutsideResidenceReasonId: undefined,
  step: 0,
  otherInstitutionSelect: [],
  isInstitutionOutsideResidence: false,
  schoolYearId: 0,
  institutionId: 0,
  outsideEnrollmentAreaInstitutionId: 0,
  isOutsideEnrollmentArea: false,
  isPrimaryInstitutionCommercialProgramSelected: false,
  isSecondaryInstitutionCommercialProgramSelected: false,
};

export enum InstitutionChangeReason {
  ExtendedStay = 1,
  FullDayClasses,
  DevelopmentalDisabilities,
  BarrierFree,
  NationalMinorityLanguage,
  Tenancy,
  Relocation,
  FamilySituation,
  Other,
}
