export interface OibServiceResult<T> {
  item?: T;
  status: ServiceResultStatus;
}

export enum ServiceResultStatus {
  Unknown = 0,
  Success = 1,
  OibFailed = 2,
  BirthRegistryFailed = 4,
  MupFailed = 8,
  OibExists = 16,
  OibNotValid = 32,
  AddressNotFound = 64,
  OibExistsInSchoolCandidate = 128,
}

export enum ServiceResultError {
  Unknown = "Unknown",
  OibFailed = "OibFailed",
  BirthRegistryFailed = "BirthRegistryFailed",
  MupFailed = "MupFailed",
  OibExists = "OibExists",
  OibNotValid = "OibNotValid",
  AddressNotFound = "AddressNotFound",
  OibExistsInSchoolCandidate = "OibExistsInSchoolCandidate",
}
