import { useState } from "react";

import carnet from "../../assets/images/carnet.png";
import esf from "../../assets/images/esf.png";
import esif from "../../assets/images/esif.png";
import eu from "../../assets/images/eu.png";
import mzo from "../../assets/images/mzo.png";
import sduzrdd from "../../assets/images/sduzrdd.png";
import styles from "./AboutPage.module.scss";

export interface AboutPageProps {
  title: string;
  logo: string;
  version: string;
}

export const AboutPage = ({
  title,
  logo,
  version,
  ...props
}: AboutPageProps) => {
  const [year, setYear] = useState(new Date().getFullYear());

  return (
    <div className={styles.about}>
      <div className={styles.row}>
        <img className={styles.logo} src={logo} alt="" />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>{title}</div>
      </div>

      <div className={styles.border}></div>

      <div className={styles.row}>
        <div className={styles.cell}>
          <img className={styles.img} src={sduzrdd} alt="" />
        </div>
        <div className={styles.cell}>
          <img className={styles.img} src={mzo} alt="" />
        </div>
        <div className={styles.cell}>
          <img className={styles.img} src={carnet} alt="" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.cell}>
          <img className={styles.img} src={eu} alt="" />
        </div>
        <div className={styles.cell}>
          <img className={styles.img} src={esif} alt="" />
        </div>
        <div className={styles.cell}>
          <img className={styles.img} src={esf} alt="" />
        </div>
      </div>

      <div className={styles.textbox_first}>
        Projekt je sufinancirala Europska unija iz Europskog socijalnog fonda.
      </div>

      <div className={styles.textbox}>Inačica {version}</div>

      <div className={styles.textbox}>
        Copyright © Ministarstvo znanosti, obrazovanja i mladih {year}. Sva
        prava zadržana
      </div>
    </div>
  );
};
