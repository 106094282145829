enum OtherParentDataCheckStatusEnum {
  Unknown = 0,
  Success = 1,
  OibFailed = 2,
  BirthRegistryFailed = 4,
  MupFailed = 8,
  OibExists = 16,
  OibNotValid = 32,
  MupAddressNotFound = 64,
  OibExistsInSchoolCandidate = 128,
  SchoolCandidateDoesNotExist = 256,
  SchoolCandidateDifferentInstitution = 512,
  ParentChildNotFound = 1024,
  SameAsApplicantParent = 2048,
  DataMismatch = 4444,
  NoCustody = 5555,
}

export default OtherParentDataCheckStatusEnum;
