import { TextField } from "@king-ict/eupisi-ui/components";
import { CodeBook } from "@king-ict/eupisi-ui/models";
import { getYearFromDate } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useBusinessRule } from "../../../hooks/businessRule/useBusinessRule";
import ParentshipTypeEnum from "../../../models/Person/ParentshipTypeEnum";
import { PersonDetails } from "../../../models/Request/PersonDetails";
import { RequestInfoPanel } from "../../Request/RequestComponents/RequestInfoPanel";
interface Props {
  emptyValue: string;
  otherParent: PersonDetails;
  genders: CodeBook[];
  professionalQualifications: CodeBook[];
  parentshipTypes: CodeBook[];
  showEdit: boolean;
  moveToSpecificStep?: () => void;
}

export default function OtherParentDetails({
  emptyValue,
  otherParent,
  genders,
  professionalQualifications,
  parentshipTypes,
  showEdit,
  moveToSpecificStep,
}: Props) {
  const { t } = useTranslation();
  const { isApplicantParent, isApplicantParentTwo } = useBusinessRule();

  return (
    <RequestInfoPanel
      title={t("requestReviewStep.otherParent")}
      subTitle={t("person.personalInformation")}
      showEdit={showEdit}
      onEditClick={moveToSpecificStep}
    >
      {(isApplicantParent() || isApplicantParentTwo()) && (
        <>
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.firstName")}
                helperText={t("common.birthRegistry")}
                value={otherParent.firstName || emptyValue}
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.lastName")}
                helperText={t("common.birthRegistry")}
                value={otherParent.lastName || emptyValue}
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.oib")}
                helperText={t("common.oibApi")}
                value={otherParent.person?.oib || emptyValue}
                plaintext
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.yearOfBirth")}
                value={
                  otherParent?.dateOfBirth
                    ? getYearFromDate(otherParent?.dateOfBirth)
                    : emptyValue
                }
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.sex")}
                value={
                  genders?.find((x) => x.id === otherParent.genderId)?.name
                }
                plaintext
              />
            </Col>

            <Col md={4}>
              <TextField
                type="text"
                label={t("person.professionalQualification")}
                value={
                  professionalQualifications?.find(
                    (x) => x.id === otherParent.professionalQualificationId
                  )?.name || emptyValue
                }
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.profession")}
                value={otherParent.professionName || emptyValue}
                plaintext
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.phoneNumber")}
                value={otherParent.phoneNumber || emptyValue}
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type={otherParent.email ? "email" : "text"}
                label={t("person.email")}
                value={otherParent.email || emptyValue}
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.parentshipType")}
                value={
                  parentshipTypes?.find(
                    (x) => x.id === otherParent.parentshipTypeId
                  )?.name || emptyValue
                }
                plaintext
              />
            </Col>
          </Row>
          {otherParent.parentshipTypeId === ParentshipTypeEnum.Custodian && (
            <Row>
              <Col md={4}>
                <TextField
                  type="text"
                  label={t("person.custodian")}
                  value={otherParent.custody || emptyValue}
                  plaintext
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </RequestInfoPanel>
  );
}
