import { CodeBook, PagedResult } from "@king-ict/eupisi-ui/models/Common";
import { axiosBaseQuery } from "@king-ict/eupisi-ui/utils";
import { createApi } from "@reduxjs/toolkit/query/react";

import Announcement from "../models/Announcement/Announcement";
//import ImportantDateDto from "../models/ImportantDate/ImportantDateDto";
import ImportantDate from "../models/ImportantDate/ImportantDate";
import { InstitutionCodeBook } from "../models/Institution/InstitutionCodeBook";
import InstitutionDetails from "../models/Institution/InstitutionDetails";
import { SchoolYear } from "../models/SchoolYear";

const alternativeDocumentsUrlPrefix: string = "alternative-documents";
const otherInstitutionReasonsUrlPrefix: string = "otherInstitutionReasons";
const schoolYearsUrlPrefix: string = "school-years";
const importantDatesUrlPrefix: string = "importantDates";
const institutionsUrlPrefix: string = "institutions";

export interface IGetInstitutionQueryModel {
  institutionId: number;
  schoolYear: number;
}

export const dataApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    /*****************************************/
    /* Data */
    getAnnouncements: build.query<Announcement[], void>({
      query: () => "announcement",
    }),
    // getImportantDates: build.query<ImportantDate[], void>({
    //   query: () => `${importantDatesUrlPrefix}/public`,
    // }),
    getCurrentSchoolYear: build.query<SchoolYear, void>({
      query: () => `${schoolYearsUrlPrefix}/current`,
    }),
    getInstitutionDetails: build.query<
      InstitutionDetails,
      IGetInstitutionQueryModel
    >({
      query: (arg) =>
        `${institutionsUrlPrefix}/${arg?.institutionId}/${arg?.schoolYear}`,
    }),
    getImportantDateAllPublic: build.query<PagedResult<ImportantDate>, void>({
      query: () => `${importantDatesUrlPrefix}/public`,
    }),

    /*****************************************/
    /* CodeBook */
    getInstitutionCodeBookList: build.query<InstitutionCodeBook[], void>({
      query: () => `${institutionsUrlPrefix}/institutions-codebook-list`,
    }),
    getOtherInstitutionReasons: build.query<CodeBook[], void>({
      query: () => `${otherInstitutionReasonsUrlPrefix}`,
    }),
    getAlternativeDocumentReasons: build.query<CodeBook[], void>({
      query: () => `${alternativeDocumentsUrlPrefix}/alternative-code-reasons`,
    }),
    getAlternativeDocumentTypes: build.query<CodeBook[], void>({
      query: () => `${alternativeDocumentsUrlPrefix}/alternative-code-types`,
    }),
    getNationalities: build.query<CodeBook[], void>({
      query: () => "nationalities",
    }),
    getCitizenships: build.query<CodeBook[], void>({
      query: () => "citizenships",
    }),
    getNationalMinorities: build.query<CodeBook[], void>({
      query: () => "national-minorities",
    }),
    getCountries: build.query<CodeBook[], void>({
      query: () => "geography/countries",
    }),
    getGenders: build.query<CodeBook[], void>({
      query: () => "genders",
    }),
    getParentshipTypes: build.query<CodeBook[], void>({
      query: () => "parentship-types",
    }),
    getParentNumbers: build.query<CodeBook[], void>({
      query: () => "parent-numbers",
    }),
    getContactMethods: build.query<CodeBook[], void>({
      query: () => "contact-methods",
    }),
    getProfessionalQualifications: build.query<CodeBook[], void>({
      query: () => "professional-qualifications",
    }),
  }),
});

export const {
  /* Data */
  useGetAnnouncementsQuery,
  //useGetImportantDatesQuery,
  useGetCurrentSchoolYearQuery,
  useGetImportantDateAllPublicQuery,
  /**
   * Institutions which are active in the current
   * school year and have at least 1 active program.
   */
  useGetInstitutionCodeBookListQuery,
  useGetOtherInstitutionReasonsQuery,
  useGetAlternativeDocumentReasonsQuery,
  useGetAlternativeDocumentTypesQuery,
  useGetNationalitiesQuery,
  useGetCitizenshipsQuery,
  useGetNationalMinoritiesQuery,
  useGetCountriesQuery,
  useGetGendersQuery,
  useGetParentshipTypesQuery,
  useGetParentNumbersQuery,
  useGetContactMethodsQuery,
  useGetProfessionalQualificationsQuery,
  useGetInstitutionDetailsQuery,
} = dataApi;
