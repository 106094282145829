import { TextField, Typography } from "@king-ict/eupisi-ui/components";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import InstitutionDetails from "../../../models/Institution/InstitutionDetails";
import Request from "../../../models/Request/Request";
import CodeBook from "../../../models/Shared/CodeBook";
import { HierarchicalCodeBook } from "../../../models/Shared/HierarchicalCodeBook";
import { RequestInfoPanel } from "../../Request/RequestComponents/RequestInfoPanel";

/**
 * Displays both institutions
 */
interface Props {
  emptyValue: string;
  request: Request;
  institution: InstitutionDetails | undefined;
  primaryCommercialProgram?: CodeBook | undefined;
  secondaryCommercialProgram?: CodeBook | undefined;
  secondaryInstitution?: InstitutionDetails;
  showEdit: boolean;
  moveToSpecificStep?: () => void;
}

/**
 * Displays both institutions
 */
export default function InstitutionProgramDetails({
  emptyValue,
  institution,
  request,
  primaryCommercialProgram,
  secondaryCommercialProgram,
  secondaryInstitution,
  showEdit,
  moveToSpecificStep,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <RequestInfoPanel
        title={t("requestReviewStep.locationProgram")}
        subTitle={t("requestReviewStep.requestPrimaryInstitution")}
        showEdit={showEdit}
        onEditClick={moveToSpecificStep}
      >
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={t("requestReviewStep.institutionName")}
              value={institution?.name || emptyValue}
              plaintext
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={t("requestReviewStep.institutionProgram")}
              value={
                (request.institutionProgramId &&
                  institution?.programs.find(
                    (program) => program.id === request.institutionProgramId
                  )?.name) ||
                emptyValue
              }
              plaintext
            />
          </Col>
          {request.languageSchoolingModels?.some(
            (v) => v.parentId === institution?.id
          ) && (
            <Col md={4}>
              <TextField
                type="text"
                label={t("institutionDataStep.schoolingModels")}
                value={request.languageSchoolingModels
                  .filter((v) => v.parentId === institution?.id)
                  .map((v) => v.name)
                  .join(", ")}
                plaintext
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={t("requestReviewStep.institutionElectiveSubjects")}
              value={
                request.electiveSubjects
                  ?.filter((v) => v.parentId === institution?.id)
                  .map((v) => v.name)
                  .join(", ") || emptyValue
              }
              plaintext
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={t("institutionDataStep.foreignLanguage")}
              value={
                request.mandatoryForeignLanguages
                  ?.filter((v) => v.parentId === institution?.id)
                  .map((v) => v.name)
                  .join(", ") || emptyValue
              }
              plaintext
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={t("institutionDataStep.foreignProgramLanguage")}
              value={
                request.foreignLanguages
                  ?.filter((v) => v.parentId === institution?.id)
                  .map((v) => v.name)
                  .join(", ") || emptyValue
              }
              plaintext
            />
          </Col>
        </Row>
        {primaryCommercialProgram?.name && (
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={t("requestReviewStep.institutionCommertialProgram")}
                value={primaryCommercialProgram?.name || emptyValue}
                plaintext
              />
            </Col>
          </Row>
        )}
        {secondaryInstitution && (
          <>
            <Typography variant="smallHeading" className="pt-3">
              {t("requestReviewStep.requestSecondaryInstitution")}
            </Typography>
            <Row>
              <Col md={4}>
                <TextField
                  type="text"
                  label={t("requestReviewStep.institutionName")}
                  value={secondaryInstitution?.name || emptyValue}
                  plaintext
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={t("requestReviewStep.institutionProgram")}
                  value={
                    (request.outsideEnrollmentAreaInstitutionProgramId &&
                      secondaryInstitution?.programs?.find(
                        (program) =>
                          program.id ===
                          request.outsideEnrollmentAreaInstitutionProgramId
                      )?.name) ||
                    emptyValue
                  }
                  plaintext
                />
              </Col>
              {request.languageSchoolingModels?.some(
                (v) => v.parentId === secondaryInstitution?.id
              ) && (
                <Col md={4}>
                  <TextField
                    type="text"
                    label={t("institutionDataStep.schoolingModels")}
                    value={request.languageSchoolingModels
                      .filter((v) => v.parentId === secondaryInstitution?.id)
                      .map((v) => v.name)
                      .join(", ")}
                    plaintext
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col md={4}>
                <TextField
                  type="text"
                  label={t("requestReviewStep.institutionElectiveSubjects")}
                  value={
                    request.electiveSubjects
                      ?.filter((v) => v.parentId === secondaryInstitution?.id)
                      .map((v) => v.name)
                      .join(", ") || emptyValue
                  }
                  plaintext
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={t("institutionDataStep.foreignLanguage")}
                  value={
                    request.mandatoryForeignLanguages
                      ?.filter((v) => v.parentId === secondaryInstitution?.id)
                      .map((v) => v.name)
                      .join(", ") || emptyValue
                  }
                  plaintext
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={t("institutionDataStep.foreignProgramLanguage")}
                  value={
                    request.foreignLanguages
                      ?.filter((v) => v.parentId === secondaryInstitution?.id)
                      .map((v) => v.name)
                      .join(", ") || emptyValue
                  }
                  plaintext
                />
              </Col>
            </Row>
            {secondaryCommercialProgram?.name && (
              <Row>
                <Col md={4}>
                  <TextField
                    type="text"
                    label={t("requestReviewStep.institutionCommertialProgram")}
                    value={secondaryCommercialProgram?.name || emptyValue}
                    plaintext
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </RequestInfoPanel>
    </>
  );
}
