import { useEffect, useState } from "react";

import {
  ExpandablePanel,
  ExpandablePanelBody,
  ExpandablePanelHeader,
  DocumentList,
  Typography,
} from "@king-ict/eupisi-ui/components";
import { useTranslation } from "react-i18next";

// import { getGroupList, searchItem } from "../../api/Faq/FaqApi";
// import { ContentArea } from "../../components/ContentArea";
// import { Group, ItemSearch, Item } from "../../models/Faq";
// import { Attachment } from "../../models/Shared/Attachment";
import {
  getGroupList,
  searchItem,
  getFaqDocumentById,
} from "../../api/Faq/FaqApi";
import { ContentArea } from "../../components/ContentArea";
import { FormCard } from "../../components/FormCard";
import { ToolbarTop } from "../../components/ToolbarTop";
import { Group, Item, ItemSearch } from "../../models/Faq";
import { Attachment } from "../../models/Shared/Attachment";

export function Faq() {
  const { t } = useTranslation();

  const [groups, setGroups] = useState<Group[]>();
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    (async () => {
      var search: ItemSearch = {
        onlyStatus: true,
        page: 1,
        pageSize: 100,
      };
      var groupPromise = getGroupList();
      var itemPromise = searchItem(search);

      var groupList = await groupPromise;
      if (groupList !== null)
        setGroups(
          groupList.filter(
            (group) =>
              (group.faqApplicationDisplayId === 1 ||
                group.faqApplicationDisplayId === 3) &&
              group.status
          )
        );
      var itemList = await itemPromise;
      if (itemList !== null) setItems(itemList.results);
    })();
  }, []);

  const getGroups = () => {
    if (groups && groups.length > 0) {
      return groups.map((group, index) => (
        <div key={index}>
          {items.some((item) => item.group?.id === group.id) && (
            <FormCard>
              <ExpandablePanel clickableHeader={true}>
                <ExpandablePanelHeader>
                  <Typography color="primary">{group.name}</Typography>
                </ExpandablePanelHeader>
                <ExpandablePanelBody>
                  {getGroupAccordian(group.id)}
                </ExpandablePanelBody>
              </ExpandablePanel>
            </FormCard>
          )}
        </div>
      ));
    }
  };

  const getGroupAccordian = (id: number) => {
    if (!items || !items.find((item) => item.group?.id === id)) return null;

    var thisGroupItems = items.filter((item) => item.group?.id === id);

    return (
      <>
        {thisGroupItems.map((item, index) => (
          <div key={index}>
            <ExpandablePanel clickableHeader={true}>
              <ExpandablePanelHeader>
                <Typography color="light">{item.question}</Typography>
              </ExpandablePanelHeader>
              <ExpandablePanelBody>
                <div
                  dangerouslySetInnerHTML={{ __html: item.answer || "" }}
                  className="ml-5"
                ></div>
                {item.attachments && item.attachments.length > 0 && (
                  <DocumentList
                    documents={item.attachments}
                    removable={false}
                    onDocumentClick={handleGetDocument}
                  />
                )}
              </ExpandablePanelBody>
            </ExpandablePanel>
          </div>
        ))}
      </>
    );
  };

  const handleGetDocument = (doc: Attachment) => {
    if (doc && doc.id) {
      getFaqDocumentById(doc.id);
    }
  };

  return (
    <ContentArea>
      <ToolbarTop pageTitle={t("navigation.faq")}></ToolbarTop>
      <FormCard>{getGroups()}</FormCard>
    </ContentArea>
  );
}
