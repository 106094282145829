import { useBreakpoint } from "@king-ict/eupisi-ui/hooks";

export interface ContentAreaProps {
  children?: any;
}
export const ContentArea = ({ children }: ContentAreaProps) => {
  const sizeBreakpoint = useBreakpoint();
  return (
    <>
      {sizeBreakpoint === "xs" || sizeBreakpoint === "sm" ? (
        <div className="pt-3 pr-5 ">{children}</div>
      ) : (
        <div className="pr-5 mr-5 pt-3">{children}</div>
      )}
    </>
  );
};
