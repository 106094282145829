import { useEffect } from "react";

import { useKeycloak } from "@king-ict/eupisi-ui/hooks";

import { useAuth } from "../../hooks/auth";
import { useBusinessRule } from "../../hooks/businessRule/useBusinessRule";

export interface MatchParams {
  requestId: string;
  id: string;
}

export interface NavbarProps {
  setImpairedStyle: () => void;
  removeImpairedStyle: () => void;
}

export const Navbar = ({
  setImpairedStyle,
  removeImpairedStyle,
}: NavbarProps) => {
  const auth = useAuth();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    function mutationCallback(mutationRecord: MutationRecord[]) {
      if (mutationRecord && mutationRecord.length > 0) {
        const addedNodes = mutationRecord[0].addedNodes;
        if (addedNodes) {
          document
            .getElementById("nonimpaired")
            ?.addEventListener("click", (e) => {
              e.preventDefault();
              setImpairedStyle();
            });
          document
            .getElementById("impaired")
            ?.addEventListener("click", (e) => {
              e.preventDefault();
              removeImpairedStyle();
            });
          document
            .getElementById("nondyslexic")
            ?.addEventListener("click", (e) => {
              e.preventDefault();
              removeImpairedStyle();
            });
          document.getElementById("larger")?.addEventListener("click", (e) => {
            e.preventDefault();
            largerFont();
          });
          document.getElementById("default")?.addEventListener("click", (e) => {
            e.preventDefault();
            defaultFont();
          });
          document.getElementById("smaller")?.addEventListener("click", (e) => {
            e.preventDefault();
            smallerFont();
          });
        }
      }
    }
    const largerFont = () => {
      const currentFontSize = document.documentElement.style.fontSize;
      let currentFont = 100;
      if (currentFontSize != null && currentFontSize !== "") {
        const currentFontPercentage = currentFontSize.split("%")[0];
        currentFont = +currentFontPercentage;
      }

      if (currentFont < 120) {
        document.documentElement.style.fontSize = currentFont + 5 + "%";
      }
    };

    const defaultFont = () => {
      document.documentElement.style.fontSize = "100%";
    };

    const smallerFont = () => {
      const currentFontSize = document.documentElement.style.fontSize;
      let currentFont = 100;
      if (currentFontSize != null && currentFontSize !== "") {
        const currentFontPercentage = currentFontSize.split("%")[0];
        currentFont = +currentFontPercentage;
      }

      if (currentFont > 80) {
        document.documentElement.style.fontSize = currentFont - 5 + "%";
      }
    };
    const observer = new MutationObserver(mutationCallback);

    const config = {
      childList: true,
    };

    const target = document.getElementsByClassName("egradani_traka-holder");
    observer.observe(target[0], config);

    if (document.cookie.indexOf("cookieImpaired") !== -1) {
      setImpairedStyle();
    } else if (document.cookie.indexOf("cookieDyslexic") !== -1) {
      removeImpairedStyle();
    }

    return () => {
      observer.disconnect();
    };
  });

  const { isLoginEnabled } = useBusinessRule();

  const getUrl = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    params.delete("person");
    params.delete("forLegalIps");
    params.delete("forLegalSource");
    params.delete("toLegalIps");
    params.delete("toLegalSource");

    url.search = params.toString();
    return url;
  };

  useEffect(() => {
    const script = document.createElement("script");

    const userSession = auth.userInfo;
    if (userSession && userSession?.IDP?.toLowerCase() !== "nias") return;

    if (userSession && keycloak.authenticated) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const forLegalIps = urlParams.get("forLegalIps");
      const forPersonOib = urlParams.get("person");

      script.src = `${process.env.REACT_APP_NIAS_NAVBAR}?messageId=${
        userSession.messageId
      }&amp;navToken=${
        userSession.navToken
      }&logout_url=${keycloak.createLogoutUrl({
        redirectUri: process.env.REACT_APP_WEB_PUBLIC,
      })}`;

      if (!!forLegalIps) {
        const forLegalIzvorReg = urlParams.get("forLegalSource");
        const toLegalIps = urlParams.get("toLegalIps");
        const toLegalIzvorReg = urlParams.get("toLegalSource");

        script.src += `&ForLegalIps=${forLegalIps}&ForLegalIzvor_reg=${forLegalIzvorReg}&ToLegalIps=${toLegalIps}&ToLegalIzvor_reg=${toLegalIzvorReg}`;
      } else if (!!forPersonOib) {
        script.src += `&ForPersonOib=${forPersonOib}`;
      }
    } else {
      script.src = `${process.env.REACT_APP_NIAS_NAVBAR}?show_vision_impaired=true`;

      if (isLoginEnabled()) {
        script.src += `&login_url=${process.env.REACT_APP_WEB_PUBLIC}/#`;
      } else {
        script.src += "&show_login_button=false";
      }
    }

    script.src +=
      "&change_entity_url=" +
      encodeURIComponent(
        `${getUrl()}?person={ForPersonOib}&forLegalIps={ForLegalIps}&forLegalSource={ForLegalIzvor_reg}&toLegalIps={ToLegalIps}&toLegalSource={ToLegalIzvor_reg}`
      );

    script.addEventListener("load", function (e) {
      document
        .getElementsByClassName("zgl-button log_in")[0]
        ?.addEventListener("click", (e) => {
          e.preventDefault();
          keycloak.login({ idpHint: "saml-nias" });
        });
      document.getElementsByClassName("zgl-button log_out")[0]?.setAttribute(
        "href",
        keycloak.createLogoutUrl({
          redirectUri: process.env.REACT_APP_WEB_PUBLIC,
        })
      );
    });

    script.id = "nias-script";
    script.async = true;

    const scriptElement = document.getElementById(
      "nias-script"
    ) as HTMLScriptElement;
    if (scriptElement) {
      scriptElement.src = script.src;
    } else {
      document.body.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, isLoginEnabled, setImpairedStyle, removeImpairedStyle]);

  return (
    <>
      <div className="egradani_traka-holder"></div>
    </>
  );
};
