import React from "react";

import { Typography, IconWrapper, Label } from "@king-ict/eupisi-ui/components";
import { ReactComponent as MenuMoreIcon } from "@king-ict/eupisi-ui/components/Icon/MenuMore.svg";
import { useBreakpoint } from "@king-ict/eupisi-ui/hooks";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../app/rootReducer";
import { ChildDetails } from "../../components/ChildDetails";
import RequestTypeEnum from "../../models/Request/RequestTypeEnum";
import { useGetCurrentSchoolYearQuery } from "../../queries/dataQuery";
import { FormatUtils, MsgUtils } from "../../utils";
import { RequestStepperHeaderButtons } from "./RequestStepperHeaderButtons";

interface Props {
  currentStep: number;
  currentSubstep?: number;
  openMobileMenu: () => void;
}

export default function RequestStepperHeader({
  currentStep,
  currentSubstep,
  openMobileMenu,
}: Props) {
  const { t } = useTranslation();

  const { data: schoolYear } = useGetCurrentSchoolYearQuery();
  const request = useTypedSelector((state) => state.request.request);
  const breakpointSize = useBreakpoint();
  const isMobile = () => {
    return breakpointSize === "xs" || breakpointSize === "sm";
  };

  const getRequestPageTitle = (requestType: RequestTypeEnum) => {
    let pageTitle = "";
    switch (requestType) {
      case RequestTypeEnum.RegularEnrollment:
        pageTitle = t("request.requestTitle");
        break;
      case RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties:
        pageTitle = t("request.requestTitleTUR");
        break;
      case RequestTypeEnum.EarlyEnrollment:
        pageTitle = t("request.requestTitleEarlyEnrollment");
        break;
      case RequestTypeEnum.TemporaryExemption:
        pageTitle = t("request.requestTitleEnrollmentDelay");
        break;
    }
    return `${pageTitle} ${schoolYear?.name}`;
  };

  return (
    <>
      <Row>
        <Col md={12} xs={10}>
          <Typography variant="baseBold">
            {getRequestPageTitle(request.type)}
          </Typography>
        </Col>
        {isMobile() && (
          <div onClick={openMobileMenu}>
            <Col xs={2}>
              <IconWrapper color="primary">
                <MenuMoreIcon />
              </IconWrapper>
            </Col>
          </div>
        )}
      </Row>
      <Row className="d-flex flex-row align-items-center">
        <Col md={4} xs={12}>
          <ChildDetails
            firstName={request.childDetails.firstName}
            lastName={request.childDetails.lastName}
            oib={request.childDetails?.person?.oib ?? ""}
            gender={request.childDetails?.genderId}
            dateOfBirth={request.childDetails?.dateOfBirth}
          />
        </Col>
        <Col md={3} xs={12}>
          <Label
            className="mt-0 mb-3 text-center"
            fullWidth={true}
            color={FormatUtils.getRequestStatusColor(request.statusId)}
          >
            {MsgUtils.getRequestStatusName(request.statusId)}
          </Label>
        </Col>
        <Col md={1}></Col>
        {!isMobile() && (
          <Col md={4} xs={12} className="text-right">
            <RequestStepperHeaderButtons
              currentStep={currentStep}
              currentSubstep={currentSubstep}
            />
          </Col>
        )}
      </Row>
    </>
  );
}
