import React, { useState, useEffect, useContext, createContext } from "react";

import { useKeycloak } from "@king-ict/eupisi-ui/hooks";
import { useHistory } from "react-router";

import {
  login as apiLogin,
  logout as apiLogout,
  impersonate as apiImpersonate,
  unimpersonate as apiUnimpersonate,
} from "../../api/User/UserApi";
import {
  UserInfo,
  UserInterface as User,
  UserPermission,
} from "../../models/User";
import { dashboard } from "../../routes/routes";

const authContext = createContext<UseProvidedAuthInterface>({} as any);

// =========== PROVIDER ===========

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: any }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// =========== useAuth HOOK ===========

interface UseProvidedAuthInterface {
  isLoading: boolean;
  authenticated: boolean;
  user: User | undefined;
  currentRole: UserPermission | undefined;
  roles: Array<UserPermission>;
  userInfo: UserInfo;

  login: (params?: any) => Promise<any>;
  logout: () => Promise<any>;
  roleChange: (id?: number) => Promise<any>;
  hasPremission: (premissionId: number) => boolean;
  impersonate: (id: string) => Promise<any>;
  unimpersonate: () => Promise<any>;
}

// Provider hook that creates auth object and handles state
function useProvideAuth(): any {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [currentRole, setCurentRole] = useState<UserPermission | undefined>();
  const [roles, setRoles] = useState<Array<UserPermission>>([]);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();

  useEffect(() => {
    let isAuthenticated = !!keycloak.authenticated;

    if (!isAuthenticated) {
      // If it is not authenticated clean user data
      setUserData();
      login();
      history.push(dashboard());
    } else if (!authenticated) {
      // If is a new login fetch data from API
      checkLogin();
    }
    setUserInfo(keycloak.idTokenParsed as UserInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak]);

  const setUserData = (
    currentRole?: UserPermission,
    roles?: Array<UserPermission>
  ) => {
    setUser(currentRole ? currentRole.user : undefined);
    setCurentRole(currentRole);
    setRoles(roles || []);
    setAuthenticated(!!currentRole);
  };

  const login = (params?: any) => {
    if (keycloak.authenticated) {
      checkLogin();
    } else if (params) {
      keycloak.login(params);
    } else {
      if (process.env.REACT_APP_KEYCLOAK_IDPHINT) {
        keycloak.login({ idpHint: process.env.REACT_APP_KEYCLOAK_IDPHINT });
      } else {
        keycloak.login();
      }
    }
  };

  const checkLogin = () => {
    setIsLoading(true);

    let ret = new Promise(async (resolve, reject) => {
      try {
        // Login and just get a cooki
        (async () => {
          await apiLogin();
          await setParentRole()
            .catch((y) => resolve(y))
            .catch((y) => reject(y));
        })();
      } catch (error) {
        console.log("[useAuth hook] Login ERROR !");
        console.log(error);
        // logout();
        setIsLoading(false);
        // reject(error);
      }
    });

    return ret;
  };

  const logout = () => {
    setIsLoading(true);

    let ret = new Promise(async (resolve, reject) => {
      await apiLogout();
      keycloak.logout();

      setUserData();

      setIsLoading(false);
      resolve(true);
    });
    return ret;
  };

  const setParentRole = () => {
    setIsLoading(true);

    let ret = new Promise(async (resolve, reject) => {
      try {
        let currentUserRole = await apiLogin();
        setUserData(currentUserRole, []);
        resolve(true);
      } catch (error) {
        console.log("[useAuth hook] Change role ERROR !");
        console.log(error);
        //logout();
        reject(error);
      }

      setIsLoading(false);
    });
    return ret;
  };

  const hasPremission = (premissionId: number) => {
    if (!currentRole || !currentRole.rolePermissions) return false;
    return currentRole.rolePermissions.includes(premissionId);
  };

  const impersonate = (id: string) => {
    setIsLoading(true);
    let ret = new Promise(async (resolve, reject) => {
      try {
        let response = await apiImpersonate(id);
        setUserData(response);
        window.location.href = dashboard();
        resolve(response);
      } catch (error) {
        console.log("[useAuth hook] Impersonate ERROR !");
        console.log(error);
        logout();
        reject(error);
      }
      setIsLoading(false);
    });
    return ret;
  };

  const unimpersonate = () => {
    setIsLoading(true);
    let ret = new Promise(async (resolve, reject) => {
      try {
        let response = await apiUnimpersonate();
        setUserData(response);
        window.location.href = dashboard();
        resolve(response);
      } catch (error) {
        console.log("[useAuth hook] Unimpersonate ERROR !");
        console.log(error);
        logout();
        reject(error);
      }
      setIsLoading(false);
    });
    return ret;
  };

  return {
    isLoading,
    authenticated,
    user,
    currentRole,
    roles,
    userInfo,
    login,
    logout,
    hasPremission,
    impersonate,
    unimpersonate,
  };
}
