import { CodeBook } from "@king-ict/eupisi-ui/models";
import { TFunction } from "i18next";
import * as yup from "yup";

import { PersonIdentification } from "../Shared/PersonIdentification";
import { OibValidationStringScheme } from "../ValidationScheme/OibValidationScheme";

interface Props {
  t: TFunction;
}

const ChildValidationSchema = ({ t }: Props) => {
  const requiredMsg = t("validations.required");

  return yup.object().shape({
    firstName: yup.string().trim().nullable().required(requiredMsg),
    lastName: yup.string().trim().nullable().required(requiredMsg),
    person: yup
      .object({
        id: yup.number().notRequired(),
        oib: OibValidationStringScheme({ t }),

        firstName: yup.string().nullable().notRequired(),
        lastName: yup.string().nullable().notRequired(),
        dateOfBirth: yup.date().nullable().notRequired(),
      })
      .transform(optionalPersonIdentificationObject),
    genderId: yup.number().required(requiredMsg),
    cityOfBirth: yup.string().nullable().required(requiredMsg),
    countryOfBirthId: yup.number().required(requiredMsg),
    citizenshipId: yup.number().required(requiredMsg),
    // nationalMinority: yup
    //   .object({
    //     id: yup.number().required(requiredMsg),
    //     name: yup.string().nullable(),
    //   })
    //   .transform(optionalCodeBookObject),
    nationalityId: yup.number().required(requiredMsg),
  });
};

export default ChildValidationSchema;

function optionalCodeBookObject(object: CodeBook) {
  if (object && object?.id) {
    return object;
  } else {
    return undefined;
  }
}

function optionalPersonIdentificationObject(object: PersonIdentification) {
  if (object) {
    return object;
  } else {
    return undefined;
  }
}
