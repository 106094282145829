import { TFunction } from "i18next";
import * as yup from "yup";

import InstitutionProgram from "../Institution/InstitutionProgram";
import { HierarchicalCodeBook } from "../Shared/HierarchicalCodeBook";
import Request from "./Request";

interface Props {
  t: TFunction;
}

const RequestInstitutionDataStepValidationSchema = ({ t }: Props) => {
  const requiredMsg = t("validations.required");

  /**
   * Checks if the specified institutionId is contained in the array. If
   * institutionId is undefined, the result is true (no validation error).
   * @param institutionId
   * @param arr
   * @returns true if the institutionId is undefined; else whether the institutionId is in the array.
   */
  const isInArray = (
    institutionId?: number,
    arr?: HierarchicalCodeBook[]
  ): boolean => {
    return institutionId
      ? arr != undefined && arr.some((v) => v.parentId === institutionId)
      : true;
  };

  return yup.object().shape({
    institutionProgramId: yup.number().nullable().required(requiredMsg),
    institutionProgram: yup
      .object({
        id: yup.number().nullable().required(requiredMsg),
        institutionId: yup.number().nullable().required(requiredMsg),
        isEnrollmentModule: yup.boolean().required(requiredMsg),
        isHandicappedProgram: yup.boolean().required(requiredMsg),
        isInternationalProgram: yup.boolean().required(requiredMsg),
        isNationalMinorityProgram: yup.boolean().required(requiredMsg),
        name: yup.string().nullable(),
      })
      .nullable()
      .required(requiredMsg),
    languageSchoolingModels: yup
      .array<HierarchicalCodeBook>()
      .nullable()
      .test(
        "language-schooling-model-institution",
        requiredMsg,
        (
          values: HierarchicalCodeBook[] | null | undefined,
          context
        ): boolean => {
          const institutionProgram = (context.parent as Request)
            ?.institutionProgram;
          if (
            institutionProgram &&
            institutionProgram.isNationalMinorityProgram
          ) {
            var institutionId = (institutionProgram as InstitutionProgram)
              ?.institutionId;
            return isInArray(institutionId, values ?? undefined);
          } else return true;
        }
      ),
    foreignLanguages: yup
      .array<HierarchicalCodeBook>()
      .nullable()
      .test(
        "foreign-language-institution",
        requiredMsg,
        (
          values: HierarchicalCodeBook[] | null | undefined,
          context
        ): boolean => {
          const institutionProgram = (context.parent as Request)
            ?.institutionProgram;
          if (institutionProgram && institutionProgram.isInternationalProgram) {
            var institutionId = (institutionProgram as InstitutionProgram)
              ?.institutionId;
            return isInArray(institutionId, values ?? undefined);
          } else return true;
        }
      ),
    commercialProgramId: yup
      .number()
      .when("isPrimaryInstitutionCommercialProgramSelected", {
        is: true,
        then: yup.number().nullable().required(requiredMsg),
        otherwise: yup.number().nullable().notRequired(),
      }),
    mandatoryForeignLanguages: yup
      .array<HierarchicalCodeBook>()
      .nullable()
      .test(
        "mandatory-foreign-language-institution",
        requiredMsg,
        (
          values: HierarchicalCodeBook[] | null | undefined,
          context
        ): boolean => {
          const institutionProgram = (context.parent as Request)
            ?.institutionProgram;
          var institutionId = (institutionProgram as InstitutionProgram)
            ?.institutionId;
          return isInArray(institutionId, values ?? undefined);
        }
      ),
    // ELECTIVE SUBJECTS ARE NOT REQUIRED FIELD electiveSubjects: yup.array().transform(arrayCheck).min(1, requiredMsg),
  });
};

export default RequestInstitutionDataStepValidationSchema;
