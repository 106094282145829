import {
  Label,
  Spinner,
  Table,
  TableMetadata,
  Typography,
  useTable,
} from "@king-ict/eupisi-ui/components";
import { useBreakpoint } from "@king-ict/eupisi-ui/hooks";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ContentArea } from "../../components/ContentArea";
import { ToolbarTop } from "../../components/ToolbarTop";
import ImportantDate from "../../models/ImportantDate/ImportantDate";
import { useGetImportantDateAllPublicQuery } from "../../queries/dataQuery";
import { FormatUtils } from "../../utils";

export function ImportantDatePage() {
  const { t } = useTranslation();

  const breakpointSize = useBreakpoint();
  const isMobile = () => {
    return breakpointSize === "xs" || breakpointSize === "sm";
  };

  const {
    data: importantDates,
    isLoading,
  } = useGetImportantDateAllPublicQuery();

  const importantDatesTableMetadata: TableMetadata<ImportantDate> = {
    rowKey: "id",
    columns: [
      {
        cellClassName: "text-left",
        columnKey: "eventName",
        header: t("importantDate.eventName"),
        accessor: (item: ImportantDate) => {
          return (
            <div className={"text-nowrap text-left"}>{item.eventName}</div>
          );
        },
      },
      {
        columnKey: "dateTimeFrom",
        header: t("importantDate.dateTimeFrom"),
        accessor: (item: ImportantDate) => {
          return (
            <div className={"text-nowrap text-left"}>
              {FormatUtils.hrDateWithTime(item.dateTimeFrom)}
            </div>
          );
        },
      },
      {
        columnKey: "dateTimeTo",
        header: t("importantDate.dateTimeTo"),
        accessor: (item: ImportantDate) => {
          return (
            <div className={"text-nowrap text-left"}>
              {FormatUtils.hrDateWithTime(item.dateTimeTo)}
            </div>
          );
        },
      },
      {
        columnKey: "status",
        header: t("importantDate.status"),
        accessor: (item: ImportantDate) => {
          return (
            <>
              <Label
                color={FormatUtils.getStatusLabelColor(item)}
                outline={false}
                fullWidth={false}
              >
                {FormatUtils.getStatusBadgeString(item)}
              </Label>
            </>
          );
        },
      },
    ],
    rowToolTip: () => undefined,
  };

  var [tableProps] = useTable<{}>({
    metadata: importantDatesTableMetadata,
    data: (importantDates?.results ?? []) as ImportantDate[],
    pagination: false,
    paginationPage: 1,
    paginationPageSize: 9999999 as any,
    totalItems: importantDates?.rowCount ?? 0,
    defaultSort: { order: 1, sortBy: 1 },
  });

  const getMobileList = () => {
    return (
      <>
        <div>
          {importantDates &&
            importantDates.results.map((item, index) => {
              return (
                <div key={item.id.toString()}>
                  <hr />
                  <Row>
                    <Col xs={12}>
                      <Typography variant="baseBold" component="div">
                        {t("importantDate.eventName")}
                      </Typography>
                      <Typography>{item.eventName}</Typography>
                    </Col>
                    <Col xs={6}>
                      <Typography variant="baseBold" component="div">
                        {t("importantDate.dateTimeFrom")}
                      </Typography>
                      <Typography>
                        {FormatUtils.hrDateWithTime(item.dateTimeFrom)}
                      </Typography>
                    </Col>
                    <Col xs={6}>
                      <Typography variant="baseBold" component="div">
                        {t("importantDate.dateTimeTo")}
                      </Typography>
                      <Typography>
                        {FormatUtils.hrDateWithTime(item.dateTimeTo)}
                      </Typography>
                    </Col>
                    <Col md={2}>
                      <Label
                        color={FormatUtils.getStatusLabelColor(item)}
                        outline={false}
                        fullWidth={true}
                        className="text-center"
                      >
                        {FormatUtils.getStatusBadgeString(item)}
                      </Label>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div>
        <hr className="mt-4 mb-5" />
      </>
    );
  };

  if (isLoading) return <Spinner show={true}></Spinner>;

  const getTableList = () => {
    return (
      <>
        <div>
          <Table {...tableProps} />
        </div>
      </>
    );
  };

  return (
    <ContentArea>
      <ToolbarTop pageTitle="Vremenik događaja"></ToolbarTop>
      {isMobile() ? <>{getMobileList()}</> : <>{getTableList()}</>}
    </ContentArea>
  );
}
