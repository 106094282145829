import {
  DocumentList,
  InfoPanel,
  Typography,
} from "@king-ict/eupisi-ui/components";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { getAnnouncementDocumentById } from "../../api/Announcements/announcementApi";
import { ContentArea } from "../../components/ContentArea";
import Announcement from "../../models/Announcement/Announcement";
import { Attachment } from "../../models/Shared/Attachment";
import {
  useGetAnnouncementsQuery,
  useGetCitizenshipsQuery,
  useGetGendersQuery,
  useGetNationalitiesQuery,
  useGetNationalMinoritiesQuery,
  useGetParentNumbersQuery,
  useGetParentshipTypesQuery,
  useGetContactMethodsQuery,
  useGetProfessionalQualificationsQuery,
} from "../../queries/dataQuery";
import { FormatUtils } from "../../utils";
import styles from "./DashboardPage.module.scss";

export default function Dashboard() {
  const { t } = useTranslation();

  const { data: announcements } = useGetAnnouncementsQuery();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: nationalities } = useGetNationalitiesQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: citizenships } = useGetCitizenshipsQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: nationalMinorities } = useGetNationalMinoritiesQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: countries } = useGetNationalMinoritiesQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: genders } = useGetGendersQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: parentNumbers } = useGetParentNumbersQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: parentshipTypes } = useGetParentshipTypesQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: contactMethods } = useGetContactMethodsQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: professionalQualifications,
  } = useGetProfessionalQualificationsQuery();

  const handleGetDocument = (doc: Attachment) => {
    if (doc && doc.id) {
      getAnnouncementDocumentById(doc.id);
    }
  };

  const getAnnouncementMessage = (itemList: Announcement[]) => {
    return (
      <>
        {itemList.map((val) => (
          <Row key={`r_${val.id}`} className="mt-1 pt-0">
            <Col key={`c_${val.id}`} md={12}>
              <InfoPanel
                key={`i_${val.id}`}
                color={val.isSticky ? `info` : `light`}
                message=""
                fullWidth={true}
                showIcon={false}
              >
                <p className={styles.announcementHeading}>{val.title}</p>
                <hr className={styles.divider} />
                <p className={styles.announcementDate}>
                  {FormatUtils.hrDateWithTime(val.dateFrom)}
                </p>
                <br />
                <div
                  className={styles.announcementMessage}
                  dangerouslySetInnerHTML={{ __html: val.text }}
                />
                <br />
                <br />
                <DocumentList
                  removable={false}
                  documents={val.attachments ?? []}
                  onDocumentClick={handleGetDocument}
                />
              </InfoPanel>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  return (
    <ContentArea>
      {announcements && <>{getAnnouncementMessage(announcements)}</>}
      {!announcements && (
        <Row className="mt-4">
          <Col md={12} className={styles.emptyList}>
            <Typography variant="largeBold" component="h2">
              {t("common.noData")}
            </Typography>
          </Col>
        </Row>
      )}
    </ContentArea>
  );
}
