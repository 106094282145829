import { TFunction } from "i18next";
import * as yup from "yup";

import { PersonIdentification } from "../Shared/PersonIdentification";
import { OibValidationStringScheme } from "../ValidationScheme/OibValidationScheme";

interface Props {
  t: TFunction;
}

export const OtherParentBasicInfoValidation = (t: TFunction) => {
  const requiredMsg = t("validations.required");

  return {
    firstName: yup.string().trim().nullable().required(requiredMsg),
    lastName: yup.string().trim().nullable().required(requiredMsg),
    person: yup
      .object({
        id: yup.number().notRequired(),
        oib: OibValidationStringScheme({ t }),

        firstName: yup.string().nullable().notRequired(),
        lastName: yup.string().nullable().notRequired(),
        dateOfBirth: yup.date().nullable().notRequired(),
      })
      .transform(optionalPersonIdentificationObject),
  };
};

const OtherParentValidationSchema = ({ t }: Props) => {
  return yup.object().shape({
    ...OtherParentBasicInfoValidation(t),
  });
};

export default OtherParentValidationSchema;

function optionalPersonIdentificationObject(object: PersonIdentification) {
  if (object) {
    return object;
  } else {
    return undefined;
  }
}
