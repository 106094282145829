import { useState } from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

import { ReactComponent as LogoBlack } from "../../assets/images/logo-black.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import styles from "./Footer.module.scss";

export interface FooterProps {
  downloadUserManual?: () => any;
  downloadTermsOfService?: () => any;
  items?: Array<FooterItem>;
  logoColor?: "normal" | "black";
}

export interface FooterItem {
  label: string;
  callback?: (item: FooterItem) => {};
}

export const Footer = ({
  downloadUserManual,
  downloadTermsOfService,
  items,
  logoColor = "normal",
}: FooterProps) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <Container>
        <Row className={styles.row}>
          <Col xs="12" sm="12" md="3" lg="2" className={styles.logo}>
            {logoColor === "normal" && (
              <Logo aria-label={t("accessibility.footerLogo")} />
            )}
            {logoColor === "black" && (
              <LogoBlack aria-label={t("accessibility.footerLogo")} />
            )}
          </Col>

          {items ? (
            <Col className={styles.links} xs="12" sm="12" md="6" lg="6">
              {items.map((item, ix) => (
                <button
                  key={ix}
                  onClick={() => item.callback && item.callback(item)}
                >
                  {" "}
                  {item.label}{" "}
                </button>
              ))}
            </Col>
          ) : (
            <Col className={styles.links} xs="12" sm="12" md="6" lg="6">
              {downloadTermsOfService === undefined ? null : (
                <button onClick={() => downloadTermsOfService()}>
                  {t("footer.terms")}
                </button>
              )}
              {downloadUserManual === undefined ? null : (
                <button onClick={() => downloadUserManual()}>
                  {t("footer.support")}
                </button>
              )}
            </Col>
          )}

          <Col className={styles.copyright} xs="12" sm="12" md="3" lg="4">
            <span>
              {t("footer.copyright").replace("${year}", year.toString())}
            </span>
            <br />
            <span>{t("footer.copyright2")}</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
