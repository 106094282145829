import { useTranslation } from "react-i18next";

import { AboutPage } from "../../components/AboutPage/AboutPage";
import { ContentArea } from "../../components/ContentArea";
import { ToolbarTop } from "../../components/ToolbarTop";

export default function AboutProgram() {
  const { t } = useTranslation();
  return (
    <ContentArea>
      <ToolbarTop pageTitle={t("navigation.aboutProgram")}></ToolbarTop>
      <AboutPage
        title={t("navigation.title")}
        version={t("navigation.appVersion")}
        logo={"\\eUpisiLogo.png"}
      />
    </ContentArea>
  );
}
