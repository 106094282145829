import { useState } from "react";

import { Button, TextField } from "@king-ict/eupisi-ui/components";
import { useValidation } from "@king-ict/eupisi-ui/hooks/validation";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";

import { useAuth } from "../hooks/auth";
import {
  OibValidationModel,
  OibValidationScheme,
} from "../models/ValidationScheme/OibValidationScheme";

export const UserLoginBox = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [oibImpersonate, setOibImpersonate] = useState<OibValidationModel>();

  const [oibErrors, doValidations] = useValidation({
    data: oibImpersonate!,
    validationSchema: OibValidationScheme,
  });

  const handleLogOut = async () => {
    if (auth.currentRole?.impersonator) auth.unimpersonate();
    else auth.logout();
  };

  const UserName = () => {
    if (!auth.user) return null;
    return (
      <>
        {auth.currentRole?.impersonator && (
          <>
            <span className="text-danger">
              {auth.currentRole?.impersonator.user.firstName}{" "}
            </span>
            <span className="text-danger">
              {" "}
              {auth.currentRole?.impersonator.user.lastName} -{" "}
            </span>
            <span className="text-danger">
              {" "}
              {auth.currentRole?.impersonator.user.oib} -{" "}
            </span>
            <span className="text-danger">
              <b> {"ADMINISTRATOR"}</b>{" "}
            </span>
            <br />
          </>
        )}
        <span> {auth.user.firstName} </span>
        <span> {auth.user.lastName} - </span>
        <span> {auth.user.oib} </span>
      </>
    );
  };

  const closeImpersonationModal = () => {
    setShowModal(false);
  };

  const handleImpersonateClick = async () => {
    doValidations();
    closeImpersonationModal();
    auth.impersonate(oibImpersonate?.oib!);
  };

  const handleOibImpersonationInput = (e: any) => {
    let oibImpersonation = e.target.value as string;
    doValidations();
    setOibImpersonate({ oib: oibImpersonation });
  };

  return (
    <Nav className="me-auto">
      <NavDropdown title={UserName()} id="basic-nav-dropdown">
        <NavDropdown.Item onClick={(e) => handleLogOut()}>
          {t("login.logoutButton")}
        </NavDropdown.Item>
        {auth.currentRole?.canImpersonate === true && (
          <>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => setShowModal(true)}>
              {t("login.impersonate")}
            </NavDropdown.Item>
            <Modal
              size="lg"
              backdrop="static"
              centered
              animation={false}
              show={showModal}
              onClose={closeImpersonationModal}
              onHide={closeImpersonationModal}
            >
              <Modal.Header>{t("common.user.pageNameDetails")}</Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col md={12}>
                      <TextField
                        type="text"
                        label={t("common.user.oib")}
                        placeholder={t("common.user.oib")}
                        onChange={handleOibImpersonationInput}
                        value={oibImpersonate?.oib!}
                        errorText={
                          oibErrors?.oib ? t("validations.oibFormat") : ""
                        }
                      ></TextField>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={closeImpersonationModal}
                  size="sm"
                  outline={true}
                >
                  {t("common.quit")}
                </Button>
                <Button onClick={handleImpersonateClick} size="sm">
                  {t("login.impersonate")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </NavDropdown>
    </Nav>
  );
};
