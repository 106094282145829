import { Typography, Button } from "@king-ict/eupisi-ui/components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export interface RequestFormCardProps {
  children?: any;
  cardTitle?: string;
  cardSubTitle?: string;
  showRefresh?: boolean;
  refreshTitle?: string;
  onClickRefresh?: (event: React.MouseEvent<any, MouseEvent>) => void;
}
export const RequestFormCard = ({
  children,
  cardTitle = "",
  cardSubTitle = "",
  showRefresh = false,
  refreshTitle,
  onClickRefresh,
}: RequestFormCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pb-4 request-form-card">
        <Row className="border-bottom border-1 mb-4 pb-2">
          <Col md={8} xs={6}>
            <Typography component="span" variant="mediumBold">
              {cardTitle}
            </Typography>
            <Typography component="span" variant="base" className="pl-2">
              {cardSubTitle}
            </Typography>
          </Col>
          {showRefresh && (
            <Col md={4} xs={6} className="text-right">
              <Button onClick={onClickRefresh} outline size="sm">
                {refreshTitle || t("request.refresh")}
              </Button>
            </Col>
          )}
        </Row>
        {children}
      </div>
    </>
  );
};
