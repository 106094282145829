import { useEffect, useState } from "react";

import { Button, InfoPanel, Typography } from "@king-ict/eupisi-ui/components";
import qs from "qs";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

import { RootState } from "../../../app/rootReducer";
import { useAuth } from "../../../hooks/auth";
import { useBusinessRule } from "../../../hooks/businessRule/useBusinessRule";
import RequestTypeEnum from "../../../models/Request/RequestTypeEnum";
import {
  useGetGendersQuery,
  useGetParentshipTypesQuery,
  useGetProfessionalQualificationsQuery,
} from "../../../queries/dataQuery";
import { childrenList } from "../../../routes/routes";
import OtherParentDetails from "../../RequestInfo/RequestDetails/OtherParentDetails";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";

interface Props {
  moveToSpecificStep: (step: number, substep?: number) => void;
}

export default function RequestReviewStepParentTwo({
  moveToSpecificStep,
}: Props) {
  const location = useLocation();
  const params = qs.parse(location.search.substring(1));
  const { id } = params;
  const { t } = useTranslation();

  const request = useSelector((state: RootState) => state.request.request);

  const [validRequest, setValidRequest] = useState<boolean>(false);

  const auth = useAuth();

  useEffect(() => {
    if (
      request.id === undefined ||
      request.id === null ||
      Number(id) !== request.id
    ) {
      setValidRequest(false);
      //history.push(childrenList());
    } else {
      if (Number(id) === request.id) {
        if (isParentRequestAllowed() === true) {
          setValidRequest(true);
        } else {
          setValidRequest(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, validRequest]);

  const { isApplicantParentTwo, isRequestDraft } = useBusinessRule();

  const history = useHistory();

  const isParentRequestAllowed = () => {
    if (request.otherParentDetails.person.oib !== auth.user?.oib) {
      return false;
    } else {
      return true;
    }
  };

  const emptyValue = "-";

  const { data: genders } = useGetGendersQuery();
  const {
    data: professionalQualifications,
  } = useGetProfessionalQualificationsQuery();
  const { data: parentshipTypes } = useGetParentshipTypesQuery();

  const GetMainInfoTitle = (isDraft: boolean, type: RequestTypeEnum) => {
    let title = "";
    switch (type) {
      case RequestTypeEnum.RegularEnrollment:
      case RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties:
        title = "Pregled predanog zahtjeva za upis.";
        break;
      case RequestTypeEnum.TemporaryExemption:
        title = "Pregled predanog zahtjeva za privremeno oslobađanje.";
        break;
      case RequestTypeEnum.EarlyEnrollment:
        title = "Pregled predanog zahtjeva za prijevremeni upis.";
        break;
      default:
        break;
    }
    return title;
  };
  const GetMainInfoMessage = (isDraft: boolean, type: RequestTypeEnum) => {
    let title = "";
    switch (type) {
      case RequestTypeEnum.RegularEnrollment:
      case RequestTypeEnum.RegularEnrollmentForChildrenWithLearningDifficulties:
        title =
          "U slučaju da je potrebno izmijeniti neki podataka obratite se administratoru škole.";
        break;
      case RequestTypeEnum.TemporaryExemption:
        title = "Pregled predanog zahtjeva za privremeno oslobađanje.";
        break;
      case RequestTypeEnum.EarlyEnrollment:
        title = "Pregled predanog zahtjeva za prijevremeni upis.";
        break;
      default:
        break;
    }
    return title;
  };

  return (
    <>
      {validRequest && isApplicantParentTwo() ? (
        <>
          <RequestFormTopMessage
            title={GetMainInfoTitle(isRequestDraft(), request.type)}
            infoMessage={GetMainInfoMessage(isRequestDraft(), request.type)}
          />
          <RequestFormCard cardTitle={"Pregled predanog zahtjeva"}>
            {request.otherParentDetails && (
              <>
                <OtherParentDetails
                  emptyValue={emptyValue}
                  otherParent={request.otherParentDetails}
                  genders={genders!}
                  professionalQualifications={professionalQualifications!}
                  parentshipTypes={parentshipTypes!}
                  showEdit={isRequestDraft()}
                  moveToSpecificStep={() => {}}
                />
                <hr />
              </>
            )}
          </RequestFormCard>
        </>
      ) : (
        <>
          <InfoPanel
            message={t("common.defaultErrorMessage")}
            color="danger"
            fullWidth
          />
          <Row>
            <Col md={6} xs={6}>
              <Typography component="p">
                {t("request.unauthorizedAccessError")}
              </Typography>
            </Col>
            <Col md={6} xs={6} className="text-right">
              <Button
                onClick={() => {
                  history.push(childrenList());
                }}
                size="sm"
                color="primary"
                outline
              >
                {t("request.prevStepButton")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
