import { api } from "@king-ict/eupisi-ui/utils";

import Person from "../../models/Person/Person";
import Questionnaire from "../../models/Questionnaire/Questionnaire";
import { DocumentType } from "../../models/Request/DocumentType";
import { EnrollmentDecision } from "../../models/Request/EnrollmentDecision";
import QuestionnaireQuestionGroupDto from "../../models/Request/InitialConversationQuestionGroup";
import Request from "../../models/Request/Request";
import { RequestPersonType } from "../../models/Request/RequestPersonType";
import { RequestStep } from "../../models/Request/RequestStep";
import RequestStepType from "../../models/Request/RequestStepType";
import RequestTypeEnum from "../../models/Request/RequestTypeEnum";
import { OtherParentValidationResult } from "../../models/Shared/OtherParentValidationResult";
import { SaveResult } from "../../models/Shared/SaveResult";

export interface SaveRegistrationFormRequestDTO {
  fileName: string;
  fileData: string;
}

const apiUrlPrefix: string = "requests";
const questionnairesUrlPrefix: string = "questionnaires";

/**
 * Updates the service data for the provided person. The newly updated Request
 * is returned.
 * @param requestId The Request.id
 * @param requestPersonType The person to update - Parent, Child, OtherParent. The OIB must exist.
 * @returns The newly updated Request as a SaveResult.
 */
export const updatePersonServiceData = async (
  requestId: number,
  requestPersonType: RequestPersonType
) => {
  return api.get(
    `${apiUrlPrefix}/update-service-data/${requestId}/${requestPersonType}`
  ) as Promise<SaveResult<Request>>;
};

/**
 * Creates the OtherParent details on the Request from the service data using
 * the otherParentPerson information.
 * This can only be called from the OtherParentData step/substep.
 * @param requestId The Request.id
 * @param otherParentPerson OIB, FirstName, and LastName of the OtherParent.
 * @returns The newly updated Request as a SaveResult.
 */
export const createOtherParentServiceData = async (
  requestId: number,
  otherParentPerson: Person
) => {
  return api.post(
    `${apiUrlPrefix}/create-other-parent/${requestId}`,
    otherParentPerson
  ) as Promise<OtherParentValidationResult<Request>>;
};

/**
 * Deletes the OtherParent data from the Request and returns the newly updated Request.
 * This can only be called from the OtherParentData step/substep.
 * @param requestId The Request.id
 * @returns The newly updated Request as a SaveResult.
 */
export const deleteOtherParent = async (requestId: number) => {
  return api.get(`${apiUrlPrefix}/delete-other-parent/${requestId}`) as Promise<
    SaveResult<Request>
  >;
};

export const createRequest = async (
  childOib: string,
  type: RequestTypeEnum,
  institutionId?: number
) => {
  var url = institutionId
    ? `${apiUrlPrefix}/create/${type}/${childOib}/${institutionId}`
    : `${apiUrlPrefix}/create/${type}/${childOib}`;
  return api.post(url) as Promise<SaveResult<Request>>;
};

export const getRequest = async (id: number) => {
  return api.get(`${apiUrlPrefix}/get/${id}`) as Promise<Request>;
};

export const saveRequest = async (
  item: Request,
  stepType?: RequestStepType
) => {
  return api.post(
    stepType ? `${apiUrlPrefix}/save/${stepType}` : `${apiUrlPrefix}/save`,
    item
  ) as Promise<SaveResult<Request>>;
};

export const deleteDraftRequest = async (id: number) => {
  return api.delete(`${apiUrlPrefix}/delete/${id}`) as Promise<any>;
};

export const setDraftRequestStep = async (item: Request, step: RequestStep) => {
  return api.post(`${apiUrlPrefix}/set-step/${step}`, item) as Promise<
    SaveResult<Request>
  >;
};

export const getRequestDocument = async (
  id: number,
  requestId: number,
  type: DocumentType
) => {
  return api.download(
    `${apiUrlPrefix}/document/${id}/${requestId}/${type}`
  ) as Promise<any>;
};

/*InitialConversationQuestionnaire*/
export const getInitialConversationQuestionnaire = async () => {
  return api.get(
    `${questionnairesUrlPrefix}/initial-conversation`
  ) as Promise<Questionnaire>;
};
export const getInitialConversationByRequestId = async (requestId: number) => {
  return api.get(
    `${apiUrlPrefix}/${requestId}/initial-conversation`
  ) as Promise<QuestionnaireQuestionGroupDto[]>;
};
export const getInitialConversationQuestionnaireDownload = async (
  requestId: number
) => {
  return api.download(
    `${questionnairesUrlPrefix}/export/${requestId}`
  ) as Promise<any>;
};

export const cancelRequestByRequestId = async (requestId: number) => {
  return api.get(`${apiUrlPrefix}/cancel/${requestId}`) as Promise<any>;
};

export const getEnrollmentDecisionByRequestId = async (requestId: number) => {
  return api.get(
    `${apiUrlPrefix}/enrollment-decision/${requestId}`
  ) as Promise<EnrollmentDecision>;
};

export const getRegistrationFormByRequestId = async (requestId: number) => {
  return api.get(
    `${apiUrlPrefix}/registration-form/${requestId}`
  ) as Promise<any>;
};

export const saveRegistrationFormByRequestId = async (
  requestId: number,
  files: any
) => {
  return api.post(
    `${apiUrlPrefix}/registration-form/${requestId}`,
    files
  ) as Promise<any>;
};
