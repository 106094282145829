import { api } from "@king-ict/eupisi-ui/utils/apiUtils";

import { UserPermissionList, UserPermission } from "../../models/User";
import User from "../../models/User/User";

const apiUrlPrefix: string = "users";

export const login = async () => {
  return api.get(`${apiUrlPrefix}/login`) as Promise<UserPermission>;
};

export const logout = async () => {
  return api.get(`${apiUrlPrefix}/logout`) as Promise<void>;
};

export const roleChange = async (id?: number) => {
  if (id)
    return api.get(
      `${apiUrlPrefix}/role-change/${id}`
    ) as Promise<UserPermissionList>;
  else
    return api.get(
      `${apiUrlPrefix}/role-change`
    ) as Promise<UserPermissionList>;
};

export const impersonate = async (oib: string) => {
  return api.get(
    `${apiUrlPrefix}/impersonate/${oib}`
  ) as Promise<UserPermission>;
};

export const unimpersonate = async () => {
  return api.get(`${apiUrlPrefix}/unimpersonate`) as Promise<UserPermission>;
};
