import { useEffect, useState } from "react";

import {
  Button,
  TextField,
  Modal,
  TextArea,
} from "@king-ict/eupisi-ui/components";
import { useInput, useValidation } from "@king-ict/eupisi-ui/hooks";
import { nameof } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { sendErrorReport } from "../../api/Error/ErrorApi";
import { ConfirmModal } from "../../components/ConfirmModal";
import { ContentArea } from "../../components/ContentArea";
import { FormCard } from "../../components/FormCard";
import { ToolbarTop } from "../../components/ToolbarTop";
import { useAuth } from "../../hooks/auth/useAuth";
import { ErrorUserData } from "../../models/Error/ErrorUserData";
import { ErrorUserDataScheme } from "../../models/Error/ErrorUserDataScheme";

export const ErrorReportCreate = () => {
  const [errorData, setErrorData] = useState<ErrorUserData>(
    {} as ErrorUserData
  );
  const { t } = useTranslation();
  const auth = useAuth();
  const { handleChange } = useInput();

  const [confirmState, setConfirmState] = useState<any>();
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
  const handleSend = async () => {
    handleToggleMessageModal();
  };

  const handleToggleMessageModal = () => {
    const isValid = doValidations();
    if (isValid) {
      setConfirmState("confirm");
    }
  };

  const handleToggleCancelModal = () => {
    if (isSent) window.close();
    setIsCancelModalOpened(
      (openConfirmSuccessModal) => !openConfirmSuccessModal
    );
  };

  const handleConfirmCancelModal = () => {
    window.close();
  };

  const handleConfirmMessageModal = async () => {
    setErrorData(errorData);
    setIsSent(true);
    try {
      const result = await sendErrorReport(errorData);
      if (result && result.isValid) setConfirmState("message");
    } catch (error) {
      setConfirmState("error");
    }
  };

  useEffect(() => {
    (async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      setErrorData({
        ...errorData,
        id:
          params?.get("id") !== "undefined" || params?.get("id") !== null
            ? params?.get("id")
            : null,
        message: params.get("message"),
        date: params.get("date"),
        url: params.get("url"),
        browser: checkBrowser(),
        userOib: auth.user?.oib ?? "unknown",
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errors, doValidations] = useValidation({
    data: errorData,
    validationSchema: ErrorUserDataScheme,
  });

  const checkBrowser = () => {
    switch (true) {
      case (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1:
        return "Opera";
      case navigator.userAgent.indexOf("Edg") !== -1:
        return "Edge";
      case navigator.userAgent.indexOf("Chrome") !== -1:
        return "Chrome";
      case navigator.userAgent.indexOf("Safari") !== -1:
        return "Safari";
      case navigator.userAgent.indexOf("Firefox") !== -1:
        return "Firefox";
      case navigator.userAgent.indexOf("MSIE") !== -1:
        return "IE";
      default:
        return "unknown";
    }
  };

  return (
    <>
      <ContentArea>
        <ToolbarTop pageTitle={`${t("errorData.header")}`} />
        <FormCard>
          <Row>
            <Col>
              <TextArea
                label={t("errorData.errorDetail")}
                ariaLabel={t("errorData.errorDetail")}
                value={errorData.errorDetail || ""}
                name={nameof<typeof errorData>("errorDetail")}
                onChange={(e) => handleChange(e, setErrorData)}
                maxLength={2000}
                rows={8}
                required
                errorText={errors?.errorDetail}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                label={t("errorData.dataDetail")}
                ariaLabel={t("errorData.dataDetail")}
                value={errorData.dataDetail || ""}
                name={nameof<typeof errorData>("dataDetail")}
                onChange={(e) => handleChange(e, setErrorData)}
                placeholder="Upišite podatke sa kojima ste radili u trenutku greške ukoliko su relevantni za prijavu"
                maxLength={2000}
                rows={8}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <TextField
                label={t("errorData.phone")}
                value={errorData.phone || ""}
                name={nameof<typeof errorData>("phone")}
                onChange={(e) => handleChange(e, setErrorData)}
              />
            </Col>
            <Col md="6">
              <TextField
                label={t("errorData.email")}
                value={errorData.email || ""}
                name={nameof<typeof errorData>("email")}
                onChange={(e) => handleChange(e, setErrorData)}
                type="email"
                required
                errorText={errors?.email}
              />
            </Col>
            <Col></Col>
          </Row>
        </FormCard>
        <FormCard>
          <Row>
            <Col></Col>
            <Col>
              <Button
                className="mr-1 float-right"
                color="primary"
                onClick={handleSend}
                size="sm"
                disabled={isSent}
              >
                {t("common.sendError")}
              </Button>
              <Button
                className="mr-1 float-right"
                color="primary"
                outline
                onClick={() => handleToggleCancelModal()}
                size="sm"
              >
                {t("common.quit")}
              </Button>
            </Col>
          </Row>
        </FormCard>
      </ContentArea>

      <Modal
        type={"confirm"}
        title={t("common.cancelModalTitle")}
        cancelText={t("common.no")}
        confirmText={t("common.yes")}
        open={isCancelModalOpened}
        onClose={handleToggleCancelModal}
        onConfirm={handleConfirmCancelModal}
      >
        {t("common.cancelModalMessage")}
      </Modal>

      <ConfirmModal
        title={t("errorData.header")}
        confirmState={confirmState}
        confirmMessage={t("errorData.confirmSendErrorReportMessage")}
        successMesage={`${t("errorData.emailSuccessfullySent")}`}
        errorMessage={t("common.requestModel.errorMessage")}
        onClickClose={() => window.close()}
        onClickConfirm={handleConfirmMessageModal}
      />
    </>
  );
};
