import { PagedResult } from "@king-ict/eupisi-ui/models";
import { api } from "@king-ict/eupisi-ui/utils/apiUtils";

import { Item, Group, ItemSearch } from "../../models/Faq";

const apiUrlPrefix: string = "faq";

export const getGroupById = async (id: number) => {
  return api.get(`${apiUrlPrefix}/group/${id}`) as Promise<Group>;
};

export const getGroupList = async () => {
  return api.get(`${apiUrlPrefix}/group`) as Promise<Group[]>;
};

export const getItemById = async (id: number) => {
  return api.get(`${apiUrlPrefix}/item/${id}`) as Promise<Item>;
};

export const searchItem = async (search: ItemSearch) => {
  return api.post(`${apiUrlPrefix}/search`, search) as Promise<
    PagedResult<Item>
  >;
};

export const getFaqDocumentById = async (id: number) => {
  return api.download(`${apiUrlPrefix}/document/${id}`) as Promise<any>;
};
