import { useEffect, useState } from "react";

import {
  Button,
  InfoPanel,
  Select,
  SelectData,
  useStepperDispatch,
  TextField,
  Checkbox,
  Modal,
  Typography,
} from "@king-ict/eupisi-ui/components";
import { useValidation } from "@king-ict/eupisi-ui/hooks";
import {
  nameof,
  getYearFromDate,
  codeBookToSelectData,
} from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  createOtherParentServiceData,
  deleteOtherParent,
} from "../../../api/Request/RequestApi";
import { useTypedSelector } from "../../../app/rootReducer";
import OtherParentDataCheckStatusEnum from "../../../models/Person/OtherParentDataCheckStatusEnum";
import OtherParentFirstValidationSchema from "../../../models/Person/OtherParentFirstValidationSchema";
import OtherParentValidationSchema from "../../../models/Person/OtherParentValidationSchema";
import ParentshipTypeEnum from "../../../models/Person/ParentshipTypeEnum";
import Person from "../../../models/Person/Person";
import { PersonDetails } from "../../../models/Request/PersonDetails";
import CodeBook from "../../../models/Shared/CodeBook";
import { PersonIdentification } from "../../../models/Shared/PersonIdentification";
import {
  useGetAlternativeDocumentReasonsQuery,
  useGetAlternativeDocumentTypesQuery,
  useGetGendersQuery,
  useGetParentshipTypesQuery,
  useGetProfessionalQualificationsQuery,
} from "../../../queries/dataQuery";
import {
  updateRequest,
  updateRequestOtherParent,
} from "../../../slices/requestSlice";
import { MsgUtils } from "../../../utils";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";

export default function OtherParentDataStep() {
  const [showParentAlert, setShowParentAlert] = useState<string | undefined>();
  const [showParentSuccess, setShowParentSuccess] = useState<
    string | undefined
  >();
  const [isOtherParentEmpty, setIsOtherParentEmpty] = useState<boolean>();
  const [modalVisible, setModalVisible] = useState(false);

  const request = useTypedSelector((state) => state.request.request);

  const otherParentState = useTypedSelector<PersonDetails>(
    (state) => state.request.request.otherParentDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOtherParentEmpty(!otherParentState || otherParentState === null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useStepperDispatch({
    type: "next",
    payload: () => {
      return handleStep();
    },
  });

  useStepperDispatch({
    type: "previous",
    payload: () => {
      return handleStep();
    },
  });

  const {
    data: professionalQualifications,
  } = useGetProfessionalQualificationsQuery();
  const {
    data: alternativeDocumentCodeTypes,
  } = useGetAlternativeDocumentTypesQuery();
  const {
    data: alternativeDocumentCodeReasons,
  } = useGetAlternativeDocumentReasonsQuery();
  const { data: parentshipTypes } = useGetParentshipTypesQuery();
  const { data: genders } = useGetGendersQuery();
  const referenceBirthday = new Date(1980, 1, 1);

  const { t } = useTranslation();

  const [doesNotHaveOIB, setDoesNotHaveOIB] = useState<boolean>(
    !!otherParentState?.person?.alternativeDocumentNumber
  );

  const [errors, doValidations] = useValidation({
    data: otherParentState,
    validationSchema: OtherParentValidationSchema,
  });

  const [errorsFirstValidation, doFirstValidations] = useValidation({
    data: otherParentState,
    validationSchema: OtherParentFirstValidationSchema,
  });

  const getParentTwoBirthYears = (): CodeBook[] => {
    let arr = [] as CodeBook[];
    let year = getYearFromDate(new Date());
    for (var i = year - 100; i <= year - 12; i++) {
      arr.push({ id: i, name: i.toString() });
    }
    return arr;
  };

  const handleCheckData = async () => {
    if (!request?.id) return null;
    const isValid = doFirstValidations();
    if (isValid) {
      createOtherParentServiceData(
        request.id,
        otherParentState.person as Person
      ).then((results) => {
        if (
          results &&
          results.status === OtherParentDataCheckStatusEnum.Success
        ) {
          setShowParentSuccess(undefined);
          setShowParentAlert(undefined);
          setShowParentSuccess(t("common.oibServiceResultStatus.success"));
          if (results.item) {
            // //postavi godinu rođenja na onu dobivenu sa servisa
            // const newPerson: PersonIdentification = {
            //   ...otherParentState?.person,
            //   dateOfBirth: results.item.dateOfBirth,
            // };
            // const newApplicantOtherParent: PersonDetails = {
            //   ...otherParentState,
            //   dateOfBirth: results.item.dateOfBirth,
            //   sex: {
            //     id: results.item.sex.id,
            //   } as CodeBook,
            //   person: newPerson,
            // };
            dispatch(updateRequest(results.item));
          }
        } else {
          setShowParentSuccess(undefined);
          setShowParentAlert(undefined);
          setShowParentAlert(
            t(MsgUtils.getOtherParentValidationMessage(results.status))
          );
        }
      });
    }
  };

  const handleDeleteOtherParentData = (e: any) => {
    if (!request.id) return null;
    setModalVisible(false);
    deleteOtherParent(request.id).then((results) => {
      if (results && results.isValid) {
        if (results.result) {
          setIsOtherParentEmpty(true);
          setDoesNotHaveOIB(false);
          dispatch(updateRequest(results.result));
        }
      }
    });
  };

  const handleStep = () => {
    if (otherParentState) {
      const isValid = doValidations();

      if (isValid && !showParentAlert) {
        dispatch(updateRequestOtherParent(otherParentState));
      } else {
        return false;
      }
    }
  };

  const handleParentshipTypeChange = (e: any) => {
    const newParentshipTypeId = e.target.value as number;

    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      parentshipTypeId: newParentshipTypeId,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleDoesHaveOIBClicked = () => {
    dispatch(updateRequestOtherParent({} as PersonDetails));
    setShowParentSuccess(undefined);
    setShowParentAlert(undefined);
    setDoesNotHaveOIB(!doesNotHaveOIB);
  };
  const handleFirstNameChange = (e: any) => {
    const newFirstName = e.target.value as string;
    const newPerson: PersonIdentification = {
      ...otherParentState?.person,
      firstName: newFirstName,
    };
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      firstName: newFirstName,
      person: newPerson,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleLastNameChange = (e: any) => {
    const newLastName = e.target.value as string;
    const newPerson: PersonIdentification = {
      ...otherParentState?.person,
      lastName: newLastName,
    };
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      lastName: newLastName,
      person: newPerson,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleOIBChange = (e: any) => {
    //const newOIB = e.target.value as string;
    const newPerson: PersonIdentification = {
      ...otherParentState?.person,
      oib: e.target.value as string,
    };
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      person: newPerson,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleParentTwoYearOfBirthChange = (e: any) => {
    var year = Number.parseInt(e.target.value);

    const dateOfBirth = new Date(
      otherParentState.dateOfBirth ?? referenceBirthday
    );
    dateOfBirth.setUTCFullYear(year);

    const newPerson: PersonIdentification = {
      ...otherParentState?.person,
      dateOfBirth: dateOfBirth,
    };
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      dateOfBirth: dateOfBirth,
      person: newPerson,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleProffesionalQualificationChange = (e: any) => {
    const newProffesionalQualificationId = e.target.value as number;

    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      professionalQualificationId: newProffesionalQualificationId,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleAlternativeDocumentNumberChange = (e: any) => {
    const newPerson: PersonIdentification = {
      ...otherParentState?.person,
      alternativeDocumentNumber: e.target.value as string,
    };
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      person: newPerson,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleAlternativeDocumentTypeChange = (e: any) => {
    const newAlternativeDocumentTypeId = e.target.value as number;

    const newPerson: PersonIdentification = {
      ...otherParentState?.person,
      alternativeDocumentTypeId: newAlternativeDocumentTypeId,
    };
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      person: newPerson,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleAlternativeDocumentReasonChange = (e: any) => {
    const newAlternativeDocumentReasonId = e.target.value as number;

    const newPerson: PersonIdentification = {
      ...otherParentState?.person,
      alternativeDocumentReasonId: newAlternativeDocumentReasonId,
    };
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      person: newPerson,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handlePhoneChange = (e: any) => {
    const newPhoneNumber = e.target.value as string;

    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      phoneNumber: newPhoneNumber,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleGenderChange = (e: any) => {
    const newGenderId = e.target.value as number;

    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      genderId: newGenderId,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value as string;

    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      email: newEmail,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleProfessionChange = (e: any) => {
    const newProfession = e.target.value as string;

    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      professionName: newProfession,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };
  const handleCustodyChange = (e: any) => {
    const newCustody = e.target.value as string;
    const newApplicantOtherParent: PersonDetails = {
      ...otherParentState,
      custody: newCustody,
    };
    dispatch(updateRequestOtherParent(newApplicantOtherParent));
  };

  const emptyValue = "";

  return (
    <>
      <RequestFormTopMessage
        infoMessage={t("person.mainInfoMessageOtherParent")}
      />
      <RequestFormCard cardTitle={t("request.basicInformation")}>
        <Row className="pb-3">
          <Col md={4}>
            <Checkbox
              label={t("otherParentDataStep.doesHaveOIB")}
              name={"doesntHaveOIB"}
              checked={doesNotHaveOIB}
              onChange={handleDoesHaveOIBClicked}
              disabled={!isOtherParentEmpty}
            />
          </Col>
          {/* BRISANJE PODATAKA O RODITELJU2 */}
          {!isOtherParentEmpty && (
            <Col md={4}>
              <Button
                size="sm"
                outline={true}
                onClick={() => setModalVisible(true)}
              >
                {t("request.deleteOtherParentButton")}
              </Button>
            </Col>
          )}
        </Row>
        {/* ZAMJENSKI DOKUMENT */}
        {doesNotHaveOIB && (
          <Row>
            <Col md={4}>
              <TextField
                required
                type="text"
                label={t("person.alternativeDocumentNumber")}
                name={nameof<typeof otherParentState.person>(
                  "alternativeDocumentNumber"
                )}
                value={
                  otherParentState?.person?.alternativeDocumentNumber ||
                  emptyValue
                }
                onChange={handleAlternativeDocumentNumberChange}
                errorText={
                  errors?.person?.alternativeDocumentNumber ||
                  errorsFirstValidation?.person?.alternativeDocumentNumber
                }
                disabled={!isOtherParentEmpty}
              />
            </Col>
            <Col md={4}>
              <Select
                required
                label={t("person.alternativeDocumentType")}
                name={nameof<typeof otherParentState.person>(
                  "alternativeDocumentTypeId"
                )}
                value={
                  otherParentState?.person?.alternativeDocumentTypeId ||
                  undefined
                }
                onChange={handleAlternativeDocumentTypeChange}
                selectData={
                  {
                    valueProp: "id",
                    textProp: "name",
                    data: alternativeDocumentCodeTypes || [],
                  } as SelectData<CodeBook>
                }
                errorText={
                  errors?.person?.alternativeDocumentTypeId ||
                  errorsFirstValidation?.person?.alternativeDocumentTypeId
                }
                disabled={!isOtherParentEmpty}
              />
            </Col>
            <Col md={4}>
              <Select
                required
                label={t("person.alternativeDocumentReason")}
                name={nameof<typeof otherParentState.person>(
                  "alternativeDocumentReasonId"
                )}
                value={
                  otherParentState?.person?.alternativeDocumentReasonId ||
                  undefined
                }
                onChange={handleAlternativeDocumentReasonChange}
                selectData={
                  {
                    valueProp: "id",
                    textProp: "name",
                    data: alternativeDocumentCodeReasons || [],
                  } as SelectData<CodeBook>
                }
                errorText={
                  errors?.person?.alternativeDocumentReasonId ||
                  errorsFirstValidation?.person?.alternativeDocumentReasonId
                }
                disabled={!isOtherParentEmpty}
              />
            </Col>
          </Row>
        )}
        {/* IME I PREZIME*/}
        <Row>
          <Col md={4}>
            <TextField
              required
              type="text"
              label={t("person.firstName")}
              name={nameof<typeof otherParentState>("firstName")}
              value={otherParentState?.firstName || emptyValue}
              onChange={handleFirstNameChange}
              errorText={errors?.firstName || errorsFirstValidation?.firstName}
              disabled={
                (!doesNotHaveOIB && !!showParentSuccess) || !isOtherParentEmpty
              }
            />
          </Col>
          <Col md={4}>
            <TextField
              required
              type="text"
              label={t("person.lastName")}
              name={nameof<typeof otherParentState>("lastName")}
              value={otherParentState?.lastName || emptyValue}
              onChange={handleLastNameChange}
              errorText={errors?.lastName || errorsFirstValidation?.lastName}
              disabled={
                (!doesNotHaveOIB && !!showParentSuccess) || !isOtherParentEmpty
              }
            />
          </Col>
          {!doesNotHaveOIB && (
            <Col md={4}>
              <TextField
                required
                type="text"
                label={t("person.oib")}
                name={nameof<typeof otherParentState.person>("oib")}
                value={otherParentState?.person?.oib || emptyValue}
                onChange={handleOIBChange}
                helperText={t("common.oibApi")}
                errorText={
                  errors?.person?.oib || errorsFirstValidation?.person?.oib
                }
                maxLength={11}
                disabled={
                  (!doesNotHaveOIB && !!showParentSuccess) ||
                  !isOtherParentEmpty
                }
              />
            </Col>
          )}
        </Row>
        {/*OIB CHECK*/}
        {!doesNotHaveOIB && isOtherParentEmpty && (
          <Row>
            <Col md={4}>
              <Button
                color="primary"
                outline
                onClick={handleCheckData}
                size="sm"
              >
                {t("otherParentDataStep.checkData")}
              </Button>
            </Col>
            <Col md={8}>
              {!showParentAlert || (
                <InfoPanel
                  color="danger"
                  message=""
                  dismissible={false}
                  fullWidth={true}
                >
                  {showParentAlert}
                </InfoPanel>
              )}
              {!showParentSuccess || (
                <InfoPanel
                  color="success"
                  message=""
                  dismissible={false}
                  fullWidth={true}
                >
                  {showParentSuccess}
                </InfoPanel>
              )}
            </Col>
          </Row>
        )}
        {/* OTHER DATA*/}
        <>
          <Row>
            <Col md={2}>
              <Select
                required
                label={t("person.yearOfBirth")}
                name={"item.parent.oib"}
                value={getYearFromDate(otherParentState?.dateOfBirth)}
                selectData={codeBookToSelectData(getParentTwoBirthYears())}
                onChange={handleParentTwoYearOfBirthChange}
                helperText={t("common.birthRegistry")}
                errorText={
                  errors?.person?.dateOfBirth
                    ? t("validations.required")
                    : undefined
                }
                disabled={!doesNotHaveOIB || !isOtherParentEmpty}
              />
            </Col>
            <Col md={2}>
              <Select
                required
                label={t("person.sex")}
                name={nameof<typeof otherParentState>("genderId")}
                value={otherParentState?.genderId}
                onChange={handleGenderChange}
                selectData={
                  {
                    valueProp: "id",
                    textProp: "name",
                    data: genders?.filter((gender) => gender.id > 0) || [],
                  } as SelectData<CodeBook>
                }
                errorText={errors?.genderId}
                disabled={!doesNotHaveOIB || !isOtherParentEmpty}
              />
            </Col>
            <Col md={4}>
              <Select
                required
                label={t("person.professionalQualification")}
                name={nameof<typeof otherParentState>(
                  "professionalQualificationId"
                )}
                value={otherParentState?.professionalQualificationId}
                onChange={handleProffesionalQualificationChange}
                selectData={
                  {
                    valueProp: "id",
                    textProp: "name",
                    data: professionalQualifications || [],
                  } as SelectData<CodeBook>
                }
                errorText={errors?.professionalQualificationId}
              />
            </Col>
            <Col md={4}>
              <TextField
                required
                type="text"
                label={t("person.profession")}
                name={nameof<typeof otherParentState>("professionName")}
                value={otherParentState?.professionName}
                onChange={handleProfessionChange}
                errorText={errors?.professionName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.phoneNumber")}
                name={nameof<typeof otherParentState>("phoneNumber")}
                value={otherParentState?.phoneNumber || emptyValue}
                onChange={handlePhoneChange}
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={t("person.email")}
                name={nameof<typeof otherParentState>("email")}
                value={otherParentState?.email || emptyValue}
                onChange={handleEmailChange}
                errorText={errors?.email}
              />
            </Col>
            <Col md={4}>
              <Select
                required
                label={t("person.parentshipType")}
                name={nameof<typeof otherParentState>("parentshipTypeId")}
                value={otherParentState?.parentshipTypeId}
                onChange={handleParentshipTypeChange}
                selectData={
                  {
                    valueProp: "id",
                    textProp: "name",
                    data: parentshipTypes || [],
                  } as SelectData<CodeBook>
                }
                errorText={errors?.parentshipTypeId}
              />
            </Col>
          </Row>

          {otherParentState?.parentshipTypeId ===
          ParentshipTypeEnum.Custodian ? (
            <Row>
              <Col md={4}>
                <TextField
                  required
                  type="text"
                  label={`${t("person.custodian")}`}
                  name={nameof<typeof otherParentState>("custody")}
                  value={otherParentState?.custody || emptyValue}
                  onChange={handleCustodyChange}
                  errorText={errors?.custody}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </>
      </RequestFormCard>
      <Modal
        type={"confirm"}
        title={t("request.deleteOtherParentConfirmationTitle")}
        open={modalVisible}
        confirmText={t("request.deleteConfirmationYes")}
        cancelText={t("common.no")}
        onClose={() => setModalVisible(false)}
        onConfirm={handleDeleteOtherParentData}
      >
        <Typography component="div" variant="medium">
          {t("request.deleteOtherParentTitle")}
        </Typography>
      </Modal>
    </>
  );
}
