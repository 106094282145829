import { ResponseHandler } from "@king-ict/eupisi-ui/components";

import Layout from "../features/Layout/Layout";
import { useAuth } from "../hooks/auth";
import Routing from "../routes/Routing";
import "./App.css";

function App() {
  require("../variablesImpared.scss");

  const auth = useAuth();

  if (!auth.authenticated) return null;

  return (
    <>
      <Layout>
        <ResponseHandler closeOnConfirm={true} />
        <Routing />
      </Layout>
    </>
  );
}

export default App;
