import React from "react";

import { Switch, Route, Redirect } from "react-router";

import { dashboard as dashboardRoute } from "./routes";

export default function Routing() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={dashboardRoute()} />
      </Route>
    </Switch>
  );
}
