import React, { useState } from "react";

import {
  Select,
  SelectData,
  useStepperDispatch,
  TextField,
} from "@king-ict/eupisi-ui/components";
import { useValidation } from "@king-ict/eupisi-ui/hooks";
import { CodeBook } from "@king-ict/eupisi-ui/models";
import { hrDate, nameof } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { updatePersonServiceData } from "../../../api/Request/RequestApi";
import { useTypedSelector } from "../../../app/rootReducer";
import { useAppDispatch } from "../../../app/store";
import ApplicantParentValidationSchema from "../../../models/Person/ApplicantParentValidationSchema";
import ParentNumberEnum from "../../../models/Person/ParentNumberEnum";
import ParentshipTypeEnum from "../../../models/Person/ParentshipTypeEnum";
import { PersonDetails } from "../../../models/Request/PersonDetails";
import { ServiceResultError } from "../../../models/Shared/OibServiceResult";
import {
  useGetCitizenshipsQuery,
  useGetContactMethodsQuery,
  useGetCountriesQuery,
  useGetGendersQuery,
  useGetNationalitiesQuery,
  useGetNationalMinoritiesQuery,
  useGetParentNumbersQuery,
  useGetParentshipTypesQuery,
  useGetProfessionalQualificationsQuery,
} from "../../../queries/dataQuery";
import {
  updateRequest,
  updateRequestApplicant,
  updateRequestOtherParent,
  updateRequestParentNumber,
} from "../../../slices/requestSlice";
import { MsgUtils } from "../../../utils";
import {
  RequestFormTopMessage,
  RequestFormCard,
  RequestFormOIBCheckMessage,
} from "../RequestComponents";

export default function ApplicantParentDataStep() {
  const applicantParentState = useTypedSelector(
    (state) => state.request.request.applicantParentDetails
  );
  const request = useTypedSelector((state) => state.request.request);

  const [showParentOibAlert, setShowParentOibAlert] = useState<
    string | undefined
  >();
  const [showParentOibSuccess, setShowParentOibSuccess] = useState<
    string | undefined
  >();
  const [showRefreshOIB, setShowRefreshOIB] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  useStepperDispatch({
    type: "next",
    payload: () => {
      return handleNextStep();
    },
  });

  const { data: genders } = useGetGendersQuery();
  const { data: nationalMinorities } = useGetNationalMinoritiesQuery();
  const { data: nationalities } = useGetNationalitiesQuery();
  const { data: countries } = useGetCountriesQuery();
  const { data: contactMethods } = useGetContactMethodsQuery();
  const { data: citizenships } = useGetCitizenshipsQuery();
  const {
    data: professionalQualifications,
  } = useGetProfessionalQualificationsQuery();
  const { data: parentNumbers } = useGetParentNumbersQuery();
  const { data: parentshipTypes } = useGetParentshipTypesQuery();

  const { t } = useTranslation();

  const [applicantErrors, doApplicantValidations] = useValidation({
    data: applicantParentState,
    validationSchema: ApplicantParentValidationSchema,
  });

  const handleParentNumberChange = (e: any) => {
    const newParentNumber = e.target.value as number;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      parentNumberId: newParentNumber,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    dispatch(updateRequestParentNumber(e.target.value as ParentNumberEnum));
    if (newApplicantParent.parentNumberId === ParentNumberEnum.OneParent) {
      dispatch(updateRequestOtherParent({} as PersonDetails));
    }
    setShowRefreshOIB(false);
  };

  const handleContactMethodChange = (e: any) => {
    const newContactMethod = e.target.value as number;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      contactMethodId: newContactMethod,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleNationalityChange = (e: any) => {
    const newNationality = e.target.value as number;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      nationalityId: newNationality,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleNationalMinorityChange = (e: any) => {
    const newNationalMinority = e.target.value as number;
    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      nationalMinorityId: newNationalMinority,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleCustodyChange = (e: any) => {
    const newCustody = e.target.value as string;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      custody: newCustody,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleParentshipTypeChange = (e: any) => {
    const newParentshipType = e.target.value as number;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      parentshipTypeId: newParentshipType,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleProfessionalQualificationChange = (e: any) => {
    const newProfessionalQualification = e.target.value as number;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      professionalQualificationId: newProfessionalQualification,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleProfessionNameChange = (e: any) => {
    const newProfessionName = e.target.value as string;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      professionName: newProfessionName,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handlePhoneChange = (e: any) => {
    const newPhoneNumber = e.target.value as string;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      phoneNumber: newPhoneNumber,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value as string;

    const newApplicantParent: PersonDetails = {
      ...applicantParentState,
      email: newEmail,
    };
    dispatch(updateRequestApplicant(newApplicantParent));
    setShowRefreshOIB(false);
  };

  const handleRefresh = () => {
    if (!request?.id) return null;
    setShowParentOibAlert("");
    setShowParentOibSuccess("");
    updatePersonServiceData(request?.id, 1).then((results) => {
      if (results?.result && results.isValid) {
        dispatch(updateRequest(results.result));
        setShowParentOibSuccess(
          t("common.oibServiceResultStatus.successRefresh")
        );
      } else if (
        results?.result &&
        !results.isValid &&
        results.validationFailures &&
        results.validationFailures[0]
      ) {
        setShowParentOibAlert(
          t(
            MsgUtils.getOibErrorMessageNew(
              results.validationFailures[0].message as ServiceResultError
            )
          )
        );
      } else {
        setShowParentOibAlert(
          t(MsgUtils.getOibErrorMessageNew(ServiceResultError.Unknown))
        );
      }
    });
  };

  const handleNextStep = () => {
    if (applicantParentState) {
      const isValid = doApplicantValidations();
      //&& doRequestValidations();
      console.log(applicantErrors);
      console.log(applicantParentState);
      //console.log(requestErrors);

      if (isValid) {
        dispatch(updateRequestApplicant(applicantParentState));
      } else {
        return isValid;
      }
    } else {
      return false;
    }
  };

  const emptyValue = "-";

  return (
    <>
      <RequestFormTopMessage
        title={t("person.mainInfoTitle")}
        infoMessage={t("person.mainInfoMessage")}
      />
      <RequestFormOIBCheckMessage
        checkOibAlert={showParentOibAlert}
        checkOibSuccess={showParentOibSuccess}
      />
      <RequestFormCard
        cardTitle={t("request.basicInformation")}
        showRefresh={showRefreshOIB}
        onClickRefresh={handleRefresh}
      >
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.firstName")}
              helperText={t("common.birthRegistry")}
              value={applicantParentState.firstName || emptyValue}
              plaintext
              bold
              errorText={applicantErrors?.firstName}
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.lastName")}
              helperText={t("common.birthRegistry")}
              value={applicantParentState.lastName || emptyValue}
              plaintext
              bold
              errorText={applicantErrors?.lastName}
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.oib")}
              helperText={t("common.oibApi")}
              value={applicantParentState.person?.oib || emptyValue}
              plaintext
              bold
              errorText={applicantErrors?.person?.oib}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.dateOfBirth")}
              helperText={t("common.birthRegistry")}
              value={
                applicantParentState && applicantParentState.dateOfBirth
                  ? hrDate(applicantParentState.dateOfBirth)
                  : emptyValue
              }
              plaintext
              bold
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.sex")}
              helperText={t("common.birthRegistry")}
              value={
                genders?.find((x) => x.id === applicantParentState.genderId)
                  ?.name
              }
              plaintext
              bold
              errorText={applicantErrors?.genderId}
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.birthplace")}
              helperText={t("common.birthRegistry")}
              value={applicantParentState.cityOfBirth || emptyValue}
              plaintext
              bold
              errorText={applicantErrors?.cityOfBirth}
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.countryBirth")}
              helperText={t("common.birthRegistry")}
              value={
                countries?.find(
                  (x) => x.id === applicantParentState.countryOfBirthId
                )?.name || emptyValue
              }
              plaintext
              bold
              errorText={applicantErrors?.countryOfBirthId}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.citizenship")}
              helperText={t("common.birthRegistry")}
              value={
                citizenships?.find(
                  (x) => x.id === applicantParentState.citizenshipId
                )?.name || emptyValue
              }
              plaintext
              bold
              errorText={applicantErrors?.citizenshipId}
            />
          </Col>
          <Col md={4}>
            <Select
              emptyOption={{ value: undefined, text: "" }}
              label={t("person.nationalMinority")}
              name={nameof<typeof applicantParentState>("nationalMinorityId")}
              value={applicantParentState.nationalMinorityId}
              onChange={handleNationalMinorityChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: nationalMinorities! || [],
                } as SelectData<CodeBook>
              }
            />
          </Col>
          <Col md={4}>
            <Select
              required
              label={t("person.nationality")}
              name={nameof<typeof applicantParentState>("nationalityId")}
              value={applicantParentState.nationalityId}
              onChange={handleNationalityChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: nationalities || [],
                } as SelectData<CodeBook>
              }
              errorText={applicantErrors?.nationalityId}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Select
              required
              label={t("person.parentshipType")}
              name={nameof<typeof applicantParentState>("parentshipTypeId")}
              value={applicantParentState.parentshipTypeId || 0}
              onChange={handleParentshipTypeChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: parentshipTypes || [],
                } as SelectData<CodeBook>
              }
              errorText={applicantErrors?.parentshipTypeId}
            />
          </Col>
          <Col md={4}>
            <Select
              required
              label={t("person.professionalQualification")}
              name={nameof<typeof applicantParentState>(
                "professionalQualificationId"
              )}
              value={applicantParentState?.professionalQualificationId}
              onChange={handleProfessionalQualificationChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: professionalQualifications || [],
                } as SelectData<CodeBook>
              }
              errorText={applicantErrors?.professionalQualificationId}
            />
          </Col>
          <Col md={4}>
            <TextField
              required
              type="text"
              label={t("person.profession")}
              name={nameof<typeof applicantParentState>("professionName")}
              value={applicantParentState?.professionName || ""}
              onChange={handleProfessionNameChange}
              errorText={applicantErrors?.professionName}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <TextField
              required
              type="text"
              label={t("person.phoneNumber")}
              name={nameof<typeof applicantParentState>("phoneNumber")}
              value={applicantParentState.phoneNumber || ""}
              //value={applicant?.phoneNumber || ""}
              onChange={handlePhoneChange}
              //onChange={(e) => handleChange(e, setApplicant)}
              errorText={applicantErrors?.phoneNumber}
            />
          </Col>
          <Col md={4}>
            <TextField
              required
              type="text"
              label={t("person.email")}
              name={nameof<typeof applicantParentState>("email")}
              value={applicantParentState.email || ""}
              //value={applicant?.email || ""}
              onChange={handleEmailChange}
              //onChange={(e) => handleChange(e, setApplicant)}
              errorText={applicantErrors?.email}
            />
          </Col>
          <Col md={4}>
            <Select
              required
              label={t("person.parentNumber")}
              name={nameof<typeof applicantParentState>("parentNumberId")}
              value={applicantParentState.parentNumberId}
              onChange={handleParentNumberChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: parentNumbers || [],
                } as SelectData<CodeBook>
              }
              errorText={applicantErrors?.parentNumberId}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Select
              required
              label={t("person.contactMethod")}
              name={nameof<typeof applicantParentState>("contactMethodId")}
              value={applicantParentState.contactMethodId}
              onChange={handleContactMethodChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: contactMethods || [],
                } as SelectData<CodeBook>
              }
              errorText={applicantErrors?.contactMethodId}
            />
          </Col>
        </Row>
      </RequestFormCard>
      <Row>
        <Col md={12}>
          {applicantParentState.parentshipTypeId ===
          ParentshipTypeEnum.Custodian ? (
            <TextField
              required
              type="text"
              label={`${t("person.custodian")}`}
              name={nameof<typeof applicantParentState>("custody")}
              value={applicantParentState.custody || ""}
              onChange={handleCustodyChange}
              errorText={applicantErrors?.custody}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <RequestFormCard
        cardTitle={t("person.permanentResidenceInformation1")}
        cardSubTitle={t("person.permanentResidenceInformation2")}
        showRefresh={false}
      >
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={`${t("person.permanentResidence")} - ${t(
                "person.country"
              )}`}
              value={
                countries?.find(
                  (x) => x.id === applicantParentState.residence?.countryId
                )?.name || emptyValue
              }
              plaintext
              bold
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={`${t("person.permanentResidence")} - ${t(
                "person.municipality"
              )}`}
              value={
                applicantParentState.residence?.permanentAddress
                  ?.municipality ||
                applicantParentState.residence?.address ||
                emptyValue
              }
              plaintext
              bold
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={`${t("person.permanentResidence")} - ${t("person.town")}`}
              value={
                applicantParentState.residence?.permanentAddress?.town ||
                emptyValue
              }
              plaintext
              bold
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <TextField
              type="text"
              label={`${t("person.permanentResidence")} - ${t(
                "person.postalCode"
              )}`}
              value={
                applicantParentState.residence?.permanentAddress?.postalCode ||
                emptyValue
              }
              plaintext
              bold
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={`${t("person.permanentResidence")} - ${t(
                "person.street"
              )}`}
              value={
                applicantParentState.residence?.permanentAddress?.street ||
                emptyValue
              }
              plaintext
              bold
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={`${t("person.permanentResidence")} - ${t(
                "person.streetNumber"
              )}`}
              value={
                applicantParentState.residence?.permanentAddress?.houseNumber ||
                emptyValue
              }
              plaintext
              bold
            />
          </Col>
        </Row>
      </RequestFormCard>
      {applicantParentState.residence?.temporaryAddress && (
        <RequestFormCard
          cardTitle={t("person.temporaryResidenceInformation1")}
          cardSubTitle={t("person.temporaryResidenceInformation2")}
        >
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.country"
                )}`}
                value={
                  countries?.find(
                    (v) => v.id === applicantParentState.residence?.countryId
                  )?.name || emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.municipality"
                )}`}
                value={
                  applicantParentState.residence?.temporaryAddress
                    ?.municipality || emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.town"
                )}`}
                value={
                  applicantParentState.residence?.temporaryAddress?.town ||
                  emptyValue
                }
                plaintext
                bold
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.postalCode"
                )}`}
                value={
                  applicantParentState.residence?.temporaryAddress
                    ?.postalCode || emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.street"
                )}`}
                value={
                  applicantParentState.residence?.temporaryAddress?.street ||
                  emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.streetNumber"
                )}`}
                value={
                  applicantParentState.residence?.temporaryAddress
                    ?.houseNumber || emptyValue
                }
                plaintext
                bold
              />
            </Col>
            {/* <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.streetNumberAddition"
                )}`}
                value={
                  applicantParentState.residence?.temporaryAddress
                    ?.houseNumber || emptyValue
                }
                plaintext
                bold
              />
            </Col> */}
          </Row>
        </RequestFormCard>
      )}
    </>
  );
}
