import { useTranslation } from "react-i18next";

import { ContentArea } from "../../components/ContentArea";
import { ToolbarTop } from "../../components/ToolbarTop";

export default function HelpAndSupport() {
  const { t } = useTranslation();

  return (
    <ContentArea>
      <ToolbarTop pageTitle={t("navigation.helpAndSupport")} />
      <p>
        Ako imate dvojbi, pitanja ili problema vezanih uz rad sa sustavom,
        možete kontaktirati CARNET-ovu Podršku obrazovnom sustavu (radnim danom
        od 8:00 do 20:00).
      </p>
      <p>Molimo Vas da kod svakog upita OBAVEZNO navedete sljedeće podatke:</p>
      <ul>
        <li>Ime i prezime</li>
        <li>OIB</li>
        <li>Kontakt telefon/mobitel</li>
        <li>
          Detaljan opis problema uz sve informacije za koje smatrate da bi mogle
          pridonijeti bržem rješavanju Vašeg upita
        </li>
      </ul>
      <p>Kontakt podaci CARNET podrške:</p>
      <ul>
        <li>
          E-pošta: <a href="mailto:helpdesk@skole.hr">helpdesk@skole.hr</a>
        </li>
        <li>
          Telefon: <a href="tel:+385016661500">01 6661 500</a>
        </li>
      </ul>
    </ContentArea>
  );
}
