import React, { /* useCallback , */ useEffect, useState } from "react";

import {
  DocumentList,
  DocumentsModal,
  InfoPanel,
  Typography,
} from "@king-ict/eupisi-ui/components";
import { UploadFile } from "@king-ict/eupisi-ui/models";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  getRegistrationFormByRequestId,
  getRequestDocument,
} from "../../../api/Request/RequestApi";
import { RootState } from "../../../app/rootReducer";
import { DocumentType } from "../../../models/Request/DocumentType";
import { Attachment } from "../../../models/Shared/Attachment";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";

interface Props {
  moveToSpecificStep: (step: number, substep?: number) => void;
  canUpload: (val: boolean) => void;
  filesToUploadParent: (files: UploadFile[]) => void;
}

export interface SignedAttachmentResponseModel {
  id: number;
  fileID: string;
  fileName: string;
  fileExtension: string;
  fileSizeKB: number;
}

export default function EnrollmentStep({
  moveToSpecificStep,
  canUpload,
  filesToUploadParent,
}: Props) {
  const { t } = useTranslation();
  const request = useSelector((state: RootState) => state.request.request);

  const [attachments, setAttachments] = useState<Attachment[]>();
  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>();

  const passToParentCanUpload = (val: any) => {
    canUpload(val);
  };

  const passToParentFilesToUpload = (val: any) => {
    filesToUploadParent(val!);
  };

  const signedAttachmentsCheck = async () => {
    await getRegistrationFormByRequestId(request.id!).then((response) => {
      if (response) {
        if (
          response.signedAttachments !== null &&
          response.signedAttachments !== undefined
        ) {
          if (response.signedAttachments.length > 0) {
            setAttachments(response.signedAttachments);
          }
        }
      }
    });
  };

  useEffect(() => {
    signedAttachmentsCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let registrationEnrollmentDocumentId: number = -1;

  const handleEnrollmentRegistrationDownload: any = async (
    doc: Attachment,
    type: DocumentType
  ) => {
    await getRegistrationFormByRequestId(request.id!).then((response) => {
      if (response) {
        if (response.attachment !== null && response.attachment !== undefined) {
          registrationEnrollmentDocumentId = response.attachment.id;
        }
      }
    });

    if (registrationEnrollmentDocumentId > 0) {
      await getRequestDocument(
        registrationEnrollmentDocumentId,
        request.id!,
        DocumentType.RegistrationForm
      ).then((x) => console.log("downloaded enrollment registration document"));
    }
  };

  const canUploadRegistrationForm = () => {
    if (request.statusId === 12 || request.statusId === 8) {
      return true;
    } else {
      return false;
    }
  };

  const [showUploadModal, setShowUploadModal] = useState<
    DocumentType | undefined
  >();

  const handleFileChange = async (files: File[]) => {
    let uploadedFiles: UploadFile[] = [];
    console.log("aydhjasdhas");
    if (files && files.length > 0) {
      files.forEach((file) => {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        var uploadedFile = {
          fileName: file.name,
          fileData: fileReader.result,
        } as UploadFile;
        uploadedFiles.push(uploadedFile);
      });
    }
  };

  const handleUploadFileChange = async (files: UploadFile[]) => {
    switch (showUploadModal) {
      case DocumentType.RegistrationForm:
        setShowUploadModal(undefined);

        let _tempFilesToUpload: UploadFile[] = [];
        files.forEach((file) => {
          _tempFilesToUpload.push(file);
        });

        filesToUpload?.forEach((x) => {
          _tempFilesToUpload.push(x);
        });

        if (_tempFilesToUpload) {
          if (_tempFilesToUpload.length > 0) {
            passToParentCanUpload(true);
          }
        }

        passToParentFilesToUpload(_tempFilesToUpload);
        setFilesToUpload(_tempFilesToUpload);

        /* files.forEach(async (file) => {
          await saveRegistrationFormByRequestId(request.id!, file).then(
            (response) => {
              if (response.result.signedAttachments) {
                setAttachments(response.result.signedAttachments);
              }
            }
          );
        }); */

        break;
      default:
        break;
    }
  };

  const handleRemoveTempUploadFile = (file: UploadFile) => {
    if (filesToUpload) {
      let fileIndex = filesToUpload?.findIndex(
        (x) => x.fileName.toLowerCase() === file.fileName.toLowerCase()
      );

      if (fileIndex > -1) {
        let newList = filesToUpload.filter((x) => x.fileName !== file.fileName);
        if (newList) {
          if (newList.length > 0) {
            passToParentCanUpload(true);
          } else {
            passToParentCanUpload(false);
          }
        }
        setFilesToUpload(newList);
        passToParentFilesToUpload(newList);
      }
    }
  };

  const handleUploadDocumentClick = (documentType: DocumentType) => {
    setShowUploadModal(documentType);
  };

  const handleDocumentDownload = async (
    doc: Attachment,
    type: DocumentType
  ) => {
    if (doc && doc.id) {
      await getRequestDocument(doc.id, request.id!, type);
    }
  };

  return (
    <>
      {request.statusId !== 9 && (
        <RequestFormTopMessage
          title={t("enrollmentStep.mainInfoTitle")}
          infoMessage={t("enrollmentStep.mainInfoMessage")}
        />
      )}

      {request.statusId !== 9 && (
        <RequestFormCard
          cardTitle={t("enrollmentStep.mainInfoTitle")}
          cardSubTitle={""}
          refreshTitle={t("common.download")}
          showRefresh
          onClickRefresh={handleEnrollmentRegistrationDownload}
        >
          <InfoPanel
            message={t("enrollmentStep.enrollmentParentsGuideInfo")}
            color="warning"
          ></InfoPanel>
        </RequestFormCard>
      )}

      <RequestFormCard
        cardTitle={t("enrollmentStep.signedEnrollmentDocument")}
        cardSubTitle={""}
        showRefresh={canUploadRegistrationForm()}
        refreshTitle={t("documentUpload.attachDocument")}
        onClickRefresh={
          canUploadRegistrationForm()
            ? () => handleUploadDocumentClick(DocumentType.RegistrationForm)
            : () => {}
        }
        key={DocumentType.RegistrationForm}
      >
        <Row>
          <Col md={6}>
            <Typography variant="baseBold" className="border-bottom">
              {t("request.documentAttachmentList")}
            </Typography>
            <DocumentList
              documents={attachments ?? []}
              onDocumentClick={(doc) =>
                handleDocumentDownload(doc, DocumentType.RegistrationForm)
              }
              onRemoveClick={(doc) => console.log(doc)}
              removable={false}
            />
          </Col>

          {request.statusId !== 9 && (
            <Col md={6}>
              <Typography
                variant="baseBold"
                color="light"
                className="border-bottom"
              >
                {t("request.documentUpdateFileList")}
              </Typography>
              <DocumentList
                documents={filesToUpload ?? []}
                /* onDocumentClick={(doc) =>
                handleDocumentDownload(doc, DocumentType.RegistrationForm)
              } */
                onRemoveClick={(doc) => handleRemoveTempUploadFile(doc)}
                removable={true}
              />
            </Col>
          )}
        </Row>
      </RequestFormCard>

      <DocumentsModal
        title={t("documentUpload.documentModal.title")}
        infoPanelText={t("documentUpload.documentModal.info")}
        types={t("documentUpload.documentModal.types")}
        allDocuments={[]}
        maxSize={10}
        info={""}
        filesAction={handleFileChange}
        open={showUploadModal !== undefined}
        onClose={() => setShowUploadModal(undefined)}
        onConfirm={handleUploadFileChange}
        hideOldDocuments={true}
        btnCancelText={t("common.quit")}
        btnOkText={t("common.addNew")}
      />
    </>
  );
}
