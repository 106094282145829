import React, { useEffect, useState } from "react";

import {
  useStepperDispatch,
  TextField,
  Select,
  Checkbox,
  AutocompleteMultipleSelect,
  SelectData,
  Spinner,
} from "@king-ict/eupisi-ui/components";
import { useValidation } from "@king-ict/eupisi-ui/hooks";
import { codeBookToSelectData, nameof } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../app/rootReducer";
import { useAppDispatch } from "../../../app/store";
import InstitutionDetails, {
  InstitutionContact,
} from "../../../models/Institution/InstitutionDetails";
import {
  InstitutionLanguageModel,
  SchoolingModelEnum,
} from "../../../models/Institution/InstitutionLanguageModel";
import { CommercialProgram } from "../../../models/Request/CommercialProgram";
import Request, {
  InstitutionChangeReason,
} from "../../../models/Request/Request";
import RequestInstitutionDataStepValidationSchema from "../../../models/Request/RequestInstitutionDataStepValidationSchema";
import RequestOtherInstitutionDataStepValidationSchema from "../../../models/Request/RequestOtherInstitutionDataStepValidationSchema";
import CodeBook from "../../../models/Shared/CodeBook";
import { HierarchicalCodeBook } from "../../../models/Shared/HierarchicalCodeBook";
import {
  useGetInstitutionCodeBookListQuery,
  useGetInstitutionDetailsQuery,
} from "../../../queries/dataQuery";
import { updateRequest } from "../../../slices/requestSlice";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";

export interface InstitutionDataStepProps {
  moveToSpecificStep?: () => void;
  showEditFromRequest?: boolean;
}

export default function InstitutionDataStep({
  moveToSpecificStep,
  showEditFromRequest,
}: InstitutionDataStepProps) {
  const emptyValue = "-";
  const emptySelectData = {
    valueProp: "id",
    textProp: "name",
    data: [] as any[],
  } as SelectData<CodeBook>;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const item: Request = useSelector(
    (state: RootState) => state.request.request
  );

  const [isOutsideEnrollmentArea, setIsOutsideEnrollmentArea] = useState(
    item.isOutsideEnrollmentArea
  );

  const [
    programInstitutionIdOutside,
    setProgramInstitutionIdOutside,
  ] = useState(item.outsideEnrollmentAreaInstitutionProgram?.institutionId);

  const { data: primaryInstitution } = useGetInstitutionDetailsQuery({
    institutionId: item?.institutionId! ?? 0,
    schoolYear: item?.schoolYearId!,
  });

  const { data: secondaryInstitution } = useGetInstitutionDetailsQuery({
    institutionId: programInstitutionIdOutside ?? 0,
    schoolYear: item?.schoolYearId!,
  });

  const reasonInstitutionChange = useSelector(
    (state: RootState) => state.request.request.institutionChangeReason
  );

  const [changeReason, setChangeReason] = useState(reasonInstitutionChange);

  const isPrimaryCommercialCheckedSelector = useSelector(
    (state: RootState) =>
      state.request.request.isPrimaryInstitutionCommercialProgramSelected
  );
  const [isPrimaryCommercialChecked, setIsPrimaryCommercialChecked] = useState(
    isPrimaryCommercialCheckedSelector
  );

  const isSecondaryCommercialCheckedSelector = useSelector(
    (state: RootState) =>
      state.request.request.isSecondaryInstitutionCommercialProgramSelected
  );

  const [
    isSecondaryCommercialChecked,
    setIsSecondaryCommercialChecked,
  ] = useState(isSecondaryCommercialCheckedSelector);

  const [
    primaryInstitutionLanguageModelList,
    setPrimaryInstitutionLanguageModelList,
  ] = useState<InstitutionLanguageModel[]>([]);
  const [
    secondaryInstitutionLanguageModelList,
    setSecondaryInstitutionLanguageModelList,
  ] = useState<InstitutionLanguageModel[]>([]);

  useEffect(() => {
    if (
      item.commercialProgramId! !== undefined &&
      item.commercialProgramId > 0 === true
    ) {
      setIsPrimaryCommercialChecked(true);
    } else {
      setIsPrimaryCommercialChecked(false);
    }

    if (
      item.outsideEnrollmentAreaCommercialProgramId! !== undefined &&
      item.outsideEnrollmentAreaCommercialProgramId > 0 === true
    ) {
      setIsSecondaryCommercialChecked(true);
    } else {
      setIsSecondaryCommercialChecked(false);
    }
  }, [item.commercialProgramId, item.outsideEnrollmentAreaCommercialProgramId]);

  const { data: institutions } = useGetInstitutionCodeBookListQuery();

  const [filteredInstitutions, setFilteredInstitutions] = useState<CodeBook[]>(
    []
  );

  useEffect(() => {
    if (secondaryInstitution !== undefined) {
      setProgramInstitutionIdOutside(secondaryInstitution?.id!);
    }
  }, [
    secondaryInstitution,
    item.schoolYearId,
    item.outsideEnrollmentAreaInstitutionProgram?.institutionId,
    programInstitutionIdOutside,
  ]);

  // Sets State variable primaryInstitutionLanguageModelList to available
  // items based on the selected institutionProgram. It does not update
  // item properties.
  useEffect(() => {
    if (primaryInstitution && item.institutionProgram) {
      const languageModelArr =
        primaryInstitution?.languageSchoolingModels?.filter(
          (v) =>
            primaryInstitution.programs.find(
              (p) => p.id === item.institutionProgram?.id
            )?.isNationalMinorityProgram || v.modelId === SchoolingModelEnum.C
        ) ?? [];
      setPrimaryInstitutionLanguageModelList(languageModelArr);
    } else {
      setPrimaryInstitutionLanguageModelList([]);
    }
  }, [primaryInstitution, item.institutionProgram]);

  // Sets State variable secondaryInstitutionLanguageModelList to available
  // items based on the selected outsideEnrollmentAreaInstitutionProgram. It does not update
  // item properties.
  useEffect(() => {
    if (secondaryInstitution && item.outsideEnrollmentAreaInstitutionProgram) {
      const languageModelArr =
        secondaryInstitution?.languageSchoolingModels?.filter(
          (v) =>
            secondaryInstitution.programs.find(
              (p) => p.id === item.outsideEnrollmentAreaInstitutionProgram?.id
            )?.isNationalMinorityProgram || v.modelId === SchoolingModelEnum.C
        ) ?? [];
      setSecondaryInstitutionLanguageModelList(languageModelArr);
    } else {
      setSecondaryInstitutionLanguageModelList([]);
    }
  }, [secondaryInstitution, item.outsideEnrollmentAreaInstitutionProgram]);

  const [
    errorRequestInstitution,
    doValidationsRequestInstitution,
  ] = useValidation({
    data: item,
    validationSchema: RequestInstitutionDataStepValidationSchema,
  });

  const [
    errorRequestOtherInstitution,
    doValidationsRequestOtherInstitution,
  ] = useValidation({
    data: item,
    validationSchema: RequestOtherInstitutionDataStepValidationSchema,
  });

  useStepperDispatch({
    type: "next",
    payload: () => {
      return handleStep();
    },
  });

  useStepperDispatch({
    type: "previous",
    payload: () => {
      return handleStep();
    },
  });

  const handleStep = () => {
    //provjerimo da li je odabrana druga škola pa treba i nju validirati
    if (item.isOutsideEnrollmentArea) {
      const isValidPrimaryInstitution = doValidationsRequestInstitution();
      const isValidSecondaryInstitution = doValidationsRequestOtherInstitution();
      if (isValidPrimaryInstitution && isValidSecondaryInstitution) {
        return true;
      } else return false;
    } else {
      //ili validiramo samo predefiniranu školu
      const isValid = doValidationsRequestInstitution();
      console.log(item);
      console.log(isValid);
      console.log(errorRequestInstitution);

      if (isValid) {
        return true;
      } else return false;
    }
  };

  /**
   * Handles the primary institution program change and resets the
   * primary institution (removes) languageSchoolingModels and foreignLanguages
   * @param e
   */
  const handleProgramChange = (e: any) => {
    const newProgramId = e.target.value as number;
    const program = primaryInstitution?.programs.find(
      (v) => v.id === newProgramId
    );

    const newRequest: Request = {
      ...item,
      institutionProgramId: newProgramId,
      institutionProgram: program,
      languageSchoolingModels:
        item.languageSchoolingModels?.filter(
          (v) => v.parentId !== primaryInstitution?.id
        ) ?? [],
      foreignLanguages:
        item.foreignLanguages?.filter(
          (v) => v.parentId !== primaryInstitution?.id
        ) ?? [],
    };

    dispatch(updateRequest(newRequest));
  };

  /**
   * Handles the secondary institution program change and resets the
   * secondary institution (removes) languageSchoolingModels and foreignLanguages
   * @param e
   */
  const handleOtherInstitutionProgramChange = (e: any) => {
    const newProgramId = e.target.value as number;
    const program = secondaryInstitution?.programs.find(
      (v) => v.id === newProgramId
    );

    const newRequest: Request = {
      ...item,
      outsideEnrollmentAreaInstitutionProgramId: newProgramId,
      outsideEnrollmentAreaInstitutionProgram: program,
      languageSchoolingModels:
        item.languageSchoolingModels?.filter(
          (v) => v.parentId !== secondaryInstitution?.id
        ) ?? [],
      foreignLanguages:
        item.foreignLanguages?.filter(
          (v) => v.parentId !== secondaryInstitution?.id
        ) ?? [],
    };

    dispatch(updateRequest(newRequest));
  };

  const handleInstitutionOutsideResidenceReasonChange = (e: any) => {
    const newReasonId = e.target.value as number;

    const newRequest: Request = {
      ...item,
      institutionChangeReason: newReasonId,
    };
    setChangeReason(newReasonId);
    dispatch(updateRequest(newRequest));
  };

  const handlePrimaryInstituteLanguageSchoolingModelsChange = (e: any) => {
    const newSchoolingModelIds = e.target.value as number[];
    var arr =
      item.languageSchoolingModels?.filter(
        (v) => v.parentId !== primaryInstitution?.id
      ) ?? [];
    var langs = primaryInstitution?.languageSchoolingModels.filter((v) =>
      newSchoolingModelIds.some((val) => val === v.id)
    );

    if (langs) {
      langs.map((lang) => {
        arr.push({
          id: lang.id,
          name: lang.name,
          parentId: primaryInstitution?.id,
        });
      });
    }
    const newRequest: Request = {
      ...item,
      languageSchoolingModels: arr,
    };
    dispatch(updateRequest(newRequest));
  };

  const handleOtherInstitutionSchoolingModelsChange = (e: any) => {
    const newSchoolingModelIds = e.target.value as number[];
    var arr =
      item.languageSchoolingModels?.filter(
        (v) => v.parentId !== secondaryInstitution?.id
      ) ?? [];
    var langs = secondaryInstitution?.languageSchoolingModels.filter((v) =>
      newSchoolingModelIds.some((val) => val === v.id)
    );
    if (langs) {
      langs.map((lang) => {
        arr.push({
          id: lang.id,
          name: lang.name,
          parentId: secondaryInstitution?.id,
        });
      });
    }
    const r: Request = {
      ...item,
      languageSchoolingModels: arr,
    };
    dispatch(updateRequest(r));
  };

  const handlePrimaryInstituteMandatoryForeignLanguageChange = (e: any) => {
    const newForeignLanguageId = e.target.value as number;
    var arr =
      item.mandatoryForeignLanguages?.filter(
        (v) => v.parentId !== primaryInstitution?.id
      ) ?? [];
    var lang = primaryInstitution?.mandatoryForeignLanguages.find(
      (v) => v.id === newForeignLanguageId
    );
    if (lang) {
      arr.push({
        id: lang.id,
        name: lang.name,
        parentId: primaryInstitution?.id,
      });
      const newRequest: Request = {
        ...item,
        mandatoryForeignLanguages: arr,
      };

      dispatch(updateRequest(newRequest));
    }
  };

  const handleOtherInstitutionMandatoryForeignLanguageChange = (e: any) => {
    const newForeignLanguageId = e.target.value as number;
    var arr =
      item.mandatoryForeignLanguages?.filter(
        (v) => v.parentId !== secondaryInstitution?.id
      ) ?? [];
    var lang = secondaryInstitution?.mandatoryForeignLanguages.find(
      (v) => v.id === newForeignLanguageId
    );
    if (lang) {
      arr.push({
        id: lang.id,
        name: lang.name,
        parentId: secondaryInstitution?.id,
      });
      const r: Request = {
        ...item,
        mandatoryForeignLanguages: arr,
      };
      //setForeignLangs(arr);
      dispatch(updateRequest(r));
    }
  };

  const handlePrimaryInstituteElectiveSubjectChange = (e: any) => {
    const newElectiveSubjectIds = e.target.value as number[];
    var arr =
      item.electiveSubjects?.filter(
        (v) => v.parentId !== primaryInstitution?.id
      ) ?? [];
    var sub = primaryInstitution?.electiveSubjects.filter(
      (v) => newElectiveSubjectIds.find((s) => s === v.id) !== undefined
    );
    if (sub) {
      sub.map((v) => {
        const newItem = {
          id: v.id,
          name: v.name,
          parentId: primaryInstitution?.id,
        } as HierarchicalCodeBook;
        arr?.push(newItem);
        return newItem;
      });
    }
    const newRequest: Request = {
      ...item,
      electiveSubjects: arr,
    };

    dispatch(updateRequest(newRequest));
  };

  const handleOtherInstitutionElectiveSubjectChange = (e: any) => {
    const newElectiveSubjectIds = e.target.value as number[];
    var arr =
      item.electiveSubjects?.filter(
        (v) => v.parentId !== secondaryInstitution?.id
      ) ?? [];
    var sub = secondaryInstitution?.electiveSubjects.filter(
      (v) => newElectiveSubjectIds.find((s) => s === v.id) !== undefined
    );
    if (sub) {
      sub.map((v) => {
        const newItem = {
          id: v.id,
          name: v.name,
          parentId: secondaryInstitution?.id,
        } as HierarchicalCodeBook;
        arr?.push(newItem);
        return newItem;
      });
    }
    const r: Request = {
      ...item,
      electiveSubjects: arr,
    };
    //setElectiveSubjects(arr);
    dispatch(updateRequest(r));
  };

  const handleCommercialProgramChange = (e: any) => {
    const newCommercialProgramId = e.target.value as number;
    const r: Request = {
      ...item,
      commercialProgramId: newCommercialProgramId,
    };
    dispatch(updateRequest(r));
  };

  const handleOtherInstitutionCommercialProgramChange = (e: any) => {
    const newCommercialProgramId = e.target.value as number;
    const r: Request = {
      ...item,
      outsideEnrollmentAreaCommercialProgramId: newCommercialProgramId,
    };
    dispatch(updateRequest(r));
  };

  const handlePrimaryInstituteForeignLanguageChange = (e: any) => {
    const val = e.target.value as number;
    var arr =
      item.foreignLanguages?.filter(
        (v) => v.parentId !== primaryInstitution?.id
      ) ?? [];
    var lang = primaryInstitution?.foreignLanguages.find((v) => v.id === val);

    if (lang) {
      arr.push({
        id: lang.id,
        name: lang.name,
        parentId: primaryInstitution?.id,
      });
      const newRequest: Request = {
        ...item,
        foreignLanguages: arr,
      };

      dispatch(updateRequest(newRequest));
    }
  };

  const handleSecondaryInstituteForeignLanguageChange = (e: any) => {
    const newNationalMinorityLanguageId = e.target.value as number;
    var arr =
      item.foreignLanguages?.filter(
        (v) => v.parentId !== secondaryInstitution?.id
      ) ?? [];
    var lang = secondaryInstitution?.foreignLanguages.find(
      (v) => v.id === newNationalMinorityLanguageId
    );

    if (lang) {
      arr.push({
        id: lang.id,
        name: lang.name,
        parentId: secondaryInstitution?.id,
      });
      const r: Request = {
        ...item,
        foreignLanguages: arr,
      };
      //setForeignLangs(arr);
      dispatch(updateRequest(r));
    }
  };

  const handleCommercialProgramSelectedChange = () => {
    const c = item.commercialProgramId;

    const r: Request = {
      ...item,
      commercialProgramId: c ? undefined : CommercialProgram.FullDayClasses,
      isPrimaryInstitutionCommercialProgramSelected: !item?.isPrimaryInstitutionCommercialProgramSelected,
    };

    dispatch(updateRequest(r));
    setIsPrimaryCommercialChecked(
      !item?.isPrimaryInstitutionCommercialProgramSelected
    );
  };

  const handleOtherInstitutionCommercialProgramSelectedChange = (e: any) => {
    const c = item.outsideEnrollmentAreaCommercialProgramId;
    const r: Request = {
      ...item,
      outsideEnrollmentAreaCommercialProgramId: c
        ? undefined
        : CommercialProgram.FullDayClasses,
      isSecondaryInstitutionCommercialProgramSelected: !item.isSecondaryInstitutionCommercialProgramSelected,
    };

    setIsSecondaryCommercialChecked(
      !item.isSecondaryInstitutionCommercialProgramSelected
    );
    dispatch(updateRequest(r));
  };

  const handleInstitutionOutsideResidenceSelectedChange = () => {
    const val = !item.isOutsideEnrollmentArea;

    const r: Request = {
      ...item,
      outsideEnrollmentAreaInstitutionId: programInstitutionIdOutside,
      isOutsideEnrollmentArea: val,
    };
    setIsOutsideEnrollmentArea(val);
    dispatch(updateRequest(r));
  };

  const handleSecondaryInstitutionChange = (e: any) => {
    const newInstitutions = e.target.value as CodeBook[];

    //Flag value for determination of institution removal - we are setting it to undefined if removed
    const institutionCodebook =
      newInstitutions.length > 0 ? newInstitutions[0] : undefined;

    const r: Request = {
      ...item,
      outsideEnrollmentAreaInstitutionId: institutionCodebook?.id, // ?? Not sure what this is but it appears to be the secondary institution id.
      outsideEnrollmentAreaInstitutionProgramId: undefined, // This is some type of change. Clear this.
      outsideEnrollmentAreaCommercialProgramId: undefined, // This is some type of change. Clear this.
      outsideEnrollmentAreaInstitutionProgram: undefined, // This is some type of change. Clear this.
      electiveSubjects:
        item.electiveSubjects?.filter(
          (v) => v.parentId === primaryInstitution?.id
        ) ?? [],
      mandatoryForeignLanguages:
        item.mandatoryForeignLanguages?.filter(
          (v) => v.parentId === primaryInstitution?.id
        ) ?? [],
      foreignLanguages:
        item.foreignLanguages?.filter(
          (v) => v.parentId === primaryInstitution?.id
        ) ?? [],
      languageSchoolingModels:
        item.languageSchoolingModels?.filter(
          (v) => v.parentId === primaryInstitution?.id
        ) ?? [],
    };

    // Not sure what these do. Update component scoped state values for secondary institution selections
    setProgramInstitutionIdOutside(institutionCodebook?.id); // It appears this should be the secondary institution Id

    dispatch(updateRequest(r));
  };

  const handleInstitutionFilterChange = async (filter: string) => {
    // This is actually the SecondaryInstitution changed.
    let inst = institutions?.filter(
      (i) =>
        i.name.toLowerCase().includes(filter.toLowerCase()) &&
        i.id !== primaryInstitution?.id &&
        i.countyId === primaryInstitution?.address.countyId &&
        !i.isHandicapped
    );
    setFilteredInstitutions(inst ?? []);
  };

  const getHasCommercialPrograms = (
    institution?: InstitutionDetails
  ): boolean => {
    return (
      (institution?.institutionSchoolYearOther?.allDayClass ?? false) ||
      (institution?.institutionSchoolYearOther?.extendedStayClass ?? false)
    );
  };

  const getCommercialPrograms = (
    institution: InstitutionDetails
  ): CodeBook[] => {
    var arrayObjects = [] as CodeBook[];
    for (const [propertyKey, propertyValue] of Object.entries(
      CommercialProgram
    )) {
      var num = Number(propertyKey);
      if (Number.isNaN(Number(propertyKey))) {
        continue;
      }
      var tranKey = "commercialProgram." + propertyValue;
      var trans = t(tranKey);
      arrayObjects.push({ id: num, name: trans });
    }
    if (!(institution.institutionSchoolYearOther?.allDayClass ?? false))
      arrayObjects = arrayObjects.filter(
        (v) => v.id !== CommercialProgram.FullDayClasses
      );
    if (!(institution.institutionSchoolYearOther?.extendedStayClass ?? false))
      arrayObjects = arrayObjects.filter(
        (v) => v.id !== CommercialProgram.ExtendedStay
      );
    return arrayObjects;
  };

  const getInstitutionContactInformation = (
    institution: InstitutionDetails
  ) => {
    var contactInformationArr = [] as InstitutionContact[];
    if (institution.institutionSchoolYearOther?.telephoneNumber) {
      contactInformationArr.push({
        contactType: "Telefon",
        contactValue: institution.institutionSchoolYearOther.telephoneNumber,
      });
    }
    if (institution.institutionSchoolYearOther?.email) {
      contactInformationArr.push({
        contactType: "e-mail",
        contactValue: institution.institutionSchoolYearOther.email,
      });
    }
    return (
      <>
        {contactInformationArr.map((val) => (
          <Row key={val.contactValue}>
            <Col md={2}>{val.contactType}</Col>
            <Col md={4}>{val.contactValue}</Col>
          </Row>
        ))}
      </>
    );
  };

  const getInstitutionChangeReasons = (): CodeBook[] => {
    const arrayObjects = [] as CodeBook[];
    for (const [propertyKey, propertyValue] of Object.entries(
      InstitutionChangeReason
    )) {
      var num = Number(propertyKey);
      if (Number.isNaN(Number(propertyKey))) {
        continue;
      }
      var tranKey = "institutionChangeReason." + propertyValue;
      var trans = t(tranKey);
      arrayObjects.push({ id: num, name: trans });
    }
    return arrayObjects;
  };

  const handleInstitutionChangeReasonOtherChange = (e: any) => {
    const val = e.target.value as string;
    const r: Request = {
      ...item,
      institutionChangeReasonOther: val,
    };
    dispatch(updateRequest(r));
  };
  if (!item) return <Spinner show={true} />;
  return (
    <>
      <RequestFormTopMessage
        title={t("institutionDataStep.mainInfoTitle")}
        infoMessage={t("institutionDataStep.mainInfoMessage")}
      />

      {primaryInstitution && (
        <>
          <RequestFormCard
            cardTitle={t("institutionDataStep.basicInformation1")}
            cardSubTitle={t("institutionDataStep.basicInformation2")}
          >
            <Row>
              <Col md={6}>
                <TextField
                  type="text"
                  label={t("institutionDataStep.schoolName")}
                  value={primaryInstitution?.name || emptyValue}
                  plaintext
                  bold
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <TextField
                  type="text"
                  label={t("institutionDataStep.county")}
                  value={primaryInstitution?.address?.county || emptyValue}
                  plaintext
                  bold
                />
              </Col>
              <Col md={3}>
                <TextField
                  type="text"
                  label={t("institutionDataStep.municipality")}
                  value={
                    primaryInstitution?.address?.municipality || emptyValue
                  }
                  plaintext
                  bold
                />
              </Col>
              <Col md={3}>
                <TextField
                  type="text"
                  label={t("institutionDataStep.town")}
                  value={primaryInstitution?.address?.town || emptyValue}
                  plaintext
                  bold
                />
              </Col>
              <Col md={3}>
                <TextField
                  type="text"
                  label={t("institutionDataStep.street")}
                  value={primaryInstitution?.address?.street || emptyValue}
                  plaintext
                  bold
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Select
                  required={true}
                  emptyOption={{ value: undefined, text: "" }}
                  label={t("institutionDataStep.program")}
                  name={"programs"}
                  value={item.institutionProgramId}
                  onChange={handleProgramChange}
                  selectData={codeBookToSelectData(
                    primaryInstitution?.programs?.filter(
                      (program) =>
                        program.isEnrollmentModule &&
                        !program.isHandicappedProgram
                    )
                  )}
                  errorText={
                    errorRequestInstitution?.institutionProgramId ||
                    errorRequestInstitution?.institutionProgram
                  }
                />
              </Col>
              {primaryInstitutionLanguageModelList.length > 0 && (
                <Col md={6}>
                  <Select
                    multiple
                    emptyOption={{ value: undefined, text: "" }}
                    label={t("institutionDataStep.schoolingModels")}
                    name={"schoolingModel"}
                    value={item.languageSchoolingModels
                      ?.filter((v) => v.parentId === item.institutionId)
                      .map((v) => v.id)}
                    onChange={
                      handlePrimaryInstituteLanguageSchoolingModelsChange
                    }
                    selectData={codeBookToSelectData(
                      primaryInstitutionLanguageModelList
                    )}
                    errorText={errorRequestInstitution?.languageSchoolingModels}
                  />
                </Col>
              )}
            </Row>

            {primaryInstitution &&
              primaryInstitution.programs.find(
                (v) => v.id === item.institutionProgramId
              )?.isInternationalProgram && (
                <Row>
                  <Col md={6}>
                    <Select
                      required={true}
                      emptyOption={{ value: undefined, text: "" }}
                      label={t("institutionDataStep.languages")}
                      name={"straniJezici"}
                      value={
                        item.foreignLanguages?.find(
                          (v) => v.parentId === item.institutionId
                        )?.id
                      }
                      onChange={handlePrimaryInstituteForeignLanguageChange}
                      selectData={codeBookToSelectData(
                        primaryInstitution?.foreignLanguages
                      )}
                      errorText={errorRequestInstitution?.foreignLanguages}
                    />
                  </Col>
                </Row>
              )}
            <Row>
              <Col md={6}>
                <Checkbox
                  label={t("institutionDataStep.CommercialProgramSelect")}
                  // checked={
                  //   (item.commercialProgramId ?? undefined) !== undefined &&
                  //   getHasCommercialPrograms(primaryInstitution)
                  // }
                  checked={isPrimaryCommercialChecked}
                  onChange={handleCommercialProgramSelectedChange}
                  disabled={!getHasCommercialPrograms(primaryInstitution)}
                />
              </Col>

              {item.commercialProgramId && (
                <Col md={6}>
                  <Select
                    //emptyOption={{ value: undefined, text: "" }}
                    value={item.commercialProgramId}
                    onChange={handleCommercialProgramChange}
                    selectData={codeBookToSelectData(
                      getCommercialPrograms(primaryInstitution!)
                    )}
                    errorText={errorRequestInstitution?.commercialProgramId}
                  />
                </Col>
              )}
            </Row>
          </RequestFormCard>
          <RequestFormCard
            cardTitle={t(
              "institutionDataStep.foreignLanguageElectiveSubjectSelection"
            )}
          >
            <Row>
              <Col md={6}>
                <Select
                  emptyOption={{ value: undefined, text: "" }}
                  label={t("institutionDataStep.foreignLanguage")}
                  required={true}
                  value={
                    item.mandatoryForeignLanguages?.find(
                      (v) => v.parentId === item.institutionId
                    )?.id
                  }
                  onChange={
                    handlePrimaryInstituteMandatoryForeignLanguageChange
                  }
                  selectData={codeBookToSelectData(
                    primaryInstitution?.mandatoryForeignLanguages
                  )}
                  errorText={errorRequestInstitution?.mandatoryForeignLanguages}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Select
                  required={false}
                  multiple
                  emptyOption={{ value: undefined, text: "" }}
                  label={t("institutionDataStep.electiveSubject")}
                  value={item.electiveSubjects
                    ?.filter((v) => v.parentId === primaryInstitution?.id)
                    .map((v) => v.id)}
                  onChange={handlePrimaryInstituteElectiveSubjectChange}
                  selectData={codeBookToSelectData(
                    primaryInstitution?.electiveSubjects
                  )}
                  //errorText={errorRequestInstitution?.electiveSubjects}
                />
              </Col>
            </Row>
          </RequestFormCard>
          <RequestFormCard
            cardTitle={t("institutionDataStep.institutionContact1")}
            cardSubTitle={t("institutionDataStep.institutionContact2")}
          >
            {primaryInstitution
              ? getInstitutionContactInformation(primaryInstitution)
              : ""}
          </RequestFormCard>
          <RequestFormCard
            cardTitle={t(
              "institutionDataStep.institutionOutsideResidenceSubtitle"
            )}
          >
            <Row>
              <Col md={6}>
                <Checkbox
                  label={t(
                    "institutionDataStep.institutionOutsideResidenceSelect"
                  )}
                  name={nameof<typeof item>("isOutsideEnrollmentArea")}
                  checked={isOutsideEnrollmentArea}
                  onChange={handleInstitutionOutsideResidenceSelectedChange}
                />
              </Col>
            </Row>
          </RequestFormCard>

          {item.isOutsideEnrollmentArea && (
            <>
              <RequestFormCard
                cardTitle={t(
                  "institutionDataStep.institutionOutsideResidenceSubtitle"
                )}
              >
                <Row>
                  <Col md={6}>
                    <Select
                      required={true}
                      emptyOption={{ value: undefined, text: "" }}
                      label={t(
                        "institutionDataStep.institutionOutsideResidenceReason"
                      )}
                      value={changeReason}
                      onChange={handleInstitutionOutsideResidenceReasonChange}
                      selectData={codeBookToSelectData(
                        getInstitutionChangeReasons()
                      )}
                      errorText={
                        errorRequestOtherInstitution?.institutionChangeReason
                      }
                    />
                  </Col>
                  {item.institutionChangeReason ===
                    InstitutionChangeReason.Other && (
                    <Col md={6}>
                      <TextField
                        label={t("institutionDataStep.institutionChangeReason")}
                        value={item.institutionChangeReasonOther}
                        onChange={handleInstitutionChangeReasonOtherChange}
                        errorText={
                          errorRequestOtherInstitution?.institutionChangeReasonOther
                        }
                      />
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col md={6}>
                    <AutocompleteMultipleSelect<CodeBook>
                      required={true}
                      key={secondaryInstitution?.id}
                      label={t("institutionDataStep.schoolName")}
                      name={"institutionList"}
                      value={
                        secondaryInstitution
                          ? [
                              {
                                id: secondaryInstitution.id,
                                name: secondaryInstitution.name,
                              },
                            ]
                          : []
                      }
                      valueProp="id"
                      textProp="name"
                      onChange={handleSecondaryInstitutionChange}
                      selectOptions={filteredInstitutions}
                      onFilterChange={handleInstitutionFilterChange}
                      maximumSelectedItems={1}
                      errorText={
                        secondaryInstitution
                          ? undefined
                          : errorRequestOtherInstitution?.outsideEnrollmentAreaInstitutionProgramId
                      }
                    />
                  </Col>
                </Row>
              </RequestFormCard>
              {secondaryInstitution && secondaryInstitution.id && (
                <>
                  <Row>
                    <Col md={4}>
                      <TextField
                        type="text"
                        label={t("institutionDataStep.county")}
                        value={
                          secondaryInstitution?.address?.county || emptyValue
                        }
                        plaintext
                        bold
                      />
                    </Col>
                    <Col md={4}>
                      <TextField
                        type="text"
                        label={t("institutionDataStep.municipality")}
                        value={
                          secondaryInstitution?.address?.municipality ||
                          emptyValue
                        }
                        plaintext
                        bold
                      />
                    </Col>
                    <Col md={4}>
                      <TextField
                        type="text"
                        label={t("institutionDataStep.town")}
                        value={
                          secondaryInstitution?.address?.town || emptyValue
                        }
                        plaintext
                        bold
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Select
                        emptyOption={{ value: undefined, text: "" }}
                        required={true}
                        label={t("institutionDataStep.program")}
                        name={nameof<typeof secondaryInstitution>("programs")}
                        value={item.outsideEnrollmentAreaInstitutionProgramId}
                        onChange={handleOtherInstitutionProgramChange}
                        selectData={
                          codeBookToSelectData(
                            secondaryInstitution?.programs?.filter(
                              (v) =>
                                v.isEnrollmentModule && !v.isHandicappedProgram
                            )
                          ) || emptySelectData
                        }
                        errorText={
                          errorRequestOtherInstitution?.outsideEnrollmentAreaInstitutionProgramId ||
                          errorRequestOtherInstitution?.outsideEnrollmentAreaInstitutionProgram
                        }
                      />
                    </Col>
                    {secondaryInstitutionLanguageModelList.length > 0 && (
                      <Col md={6}>
                        <Select
                          multiple
                          emptyOption={{ value: undefined, text: "" }}
                          label={t("institutionDataStep.schoolingModels")}
                          name={"schoolingModel"}
                          value={item.languageSchoolingModels
                            ?.filter(
                              (v) => v.parentId === secondaryInstitution.id
                            )
                            .map((v) => v.id)}
                          onChange={handleOtherInstitutionSchoolingModelsChange}
                          selectData={codeBookToSelectData(
                            secondaryInstitutionLanguageModelList
                          )}
                          errorText={
                            errorRequestOtherInstitution?.languageSchoolingModels
                          }
                        />
                      </Col>
                    )}
                  </Row>

                  {secondaryInstitution &&
                    secondaryInstitution.programs.find(
                      (v) =>
                        v.id ===
                        item.outsideEnrollmentAreaInstitutionProgram?.id
                    )?.isInternationalProgram && (
                      <Row>
                        <Col md={6}>
                          <Select
                            required={true}
                            emptyOption={{ value: undefined, text: "" }}
                            label={t("institutionDataStep.languages")}
                            name={"straniJezici"}
                            value={
                              item.foreignLanguages?.find(
                                (v) => v.parentId === secondaryInstitution.id
                              )?.id
                            }
                            onChange={
                              handleSecondaryInstituteForeignLanguageChange
                            }
                            selectData={
                              codeBookToSelectData(
                                secondaryInstitution?.foreignLanguages
                              ) || emptySelectData
                            }
                            errorText={
                              errorRequestOtherInstitution?.foreignLanguages
                            }
                          />
                        </Col>
                      </Row>
                    )}

                  <Row>
                    <Col md={6}>
                      <Checkbox
                        label={t("institutionDataStep.CommercialProgramSelect")}
                        checked={isSecondaryCommercialChecked}
                        onChange={
                          handleOtherInstitutionCommercialProgramSelectedChange
                        }
                        disabled={
                          !getHasCommercialPrograms(secondaryInstitution)
                        }
                      />
                    </Col>

                    {item.outsideEnrollmentAreaCommercialProgramId && (
                      <Col md={6}>
                        <Select
                          // emptyOption={{ value: undefined, text: "" }}
                          value={item.outsideEnrollmentAreaCommercialProgramId}
                          onChange={
                            handleOtherInstitutionCommercialProgramChange
                          }
                          selectData={
                            codeBookToSelectData(
                              getCommercialPrograms(secondaryInstitution!)
                            ) || emptySelectData
                          }
                          errorText={
                            errorRequestOtherInstitution?.outsideEnrollmentAreaCommercialProgramId
                          }
                        />
                      </Col>
                    )}
                  </Row>
                  <RequestFormCard
                    cardTitle={t(
                      "institutionDataStep.foreignLanguageElectiveSubjectSelection"
                    )}
                  >
                    <Row>
                      <Col md={6}>
                        <Select
                          required={true}
                          emptyOption={{ value: undefined, text: "" }}
                          label={t("institutionDataStep.foreignLanguage")}
                          value={
                            item.mandatoryForeignLanguages?.find(
                              (v) => v.parentId === secondaryInstitution?.id
                            )?.id
                          }
                          onChange={
                            handleOtherInstitutionMandatoryForeignLanguageChange
                          }
                          selectData={
                            codeBookToSelectData(
                              secondaryInstitution?.mandatoryForeignLanguages
                            ) || emptySelectData
                          }
                          errorText={
                            errorRequestOtherInstitution?.mandatoryForeignLanguages
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Select
                          multiple
                          required={false}
                          emptyOption={{ value: undefined, text: "" }}
                          label={t("institutionDataStep.electiveSubject")}
                          value={item.electiveSubjects
                            ?.filter(
                              (v) => v.parentId === secondaryInstitution?.id
                            )
                            .map((v) => v.id)}
                          onChange={handleOtherInstitutionElectiveSubjectChange}
                          selectData={
                            codeBookToSelectData(
                              secondaryInstitution?.electiveSubjects
                            ) || emptySelectData
                          }
                          //errorText={errorRequestOtherInstitution?.electiveSubjects}
                        />
                      </Col>
                    </Row>
                  </RequestFormCard>
                  <RequestFormCard
                    cardTitle={t("institutionDataStep.institutionContact1")}
                    cardSubTitle={t("institutionDataStep.institutionContact3")}
                  >
                    {secondaryInstitution
                      ? getInstitutionContactInformation(secondaryInstitution)
                      : ""}
                  </RequestFormCard>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
