enum ChildAnswerTypeEnum {
  Name = "FullName",
  Sex = "Sex",
  Birth = "Birth",
  Oib = "OIB",
  Age = "Age",
  Address = "Address",
}

export default ChildAnswerTypeEnum;
