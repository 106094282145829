import { TFunction } from "i18next";
import * as yup from "yup";

import QuestionDisplayTypeEnum from "../Questionnaire/QuestionDisplayTypeEnum";
import QuestionnaireQuestionDto from "../Request/InitialConversationQuestionDataItem";

interface Props {
  t: TFunction;
}

const InitialConversationQuestionDataItemValidationSchema = ({ t }: Props) => {
  const requiredMsg = t("validations.required");

  return yup.array().of(
    yup
      .object()
      .shape({
        answerId: yup
          .mixed()
          .when(
            ["isRequired", "displayTypeId", "parentQuestionGroupQuestionId"],
            {
              is: (
                isRequired: boolean,
                displayTypeId: number,
                parentQuestionGroupQuestionId?: number
              ) =>
                isRequired &&
                (displayTypeId === QuestionDisplayTypeEnum.Select ||
                  displayTypeId === QuestionDisplayTypeEnum.Radio) &&
                parentQuestionGroupQuestionId === null,
              then: yup
                .number()
                .nullable()
                .required(requiredMsg)
                .min(1, requiredMsg),
              otherwise: yup.number().nullable(),
            }
          ),
        answerIds: yup
          .mixed()
          .when(
            ["isRequired", "displayTypeId", "parentQuestionGroupQuestionId"],
            {
              is: (
                isRequired: boolean,
                displayTypeId: number,
                parentQuestionGroupQuestionId?: number
              ) =>
                isRequired &&
                displayTypeId === QuestionDisplayTypeEnum.MultiSelect &&
                parentQuestionGroupQuestionId === null,
              then: yup.mixed().nullable().required(requiredMsg),
              otherwise: yup.mixed().nullable(),
            }
          ),
        answer: yup
          .string()
          .when(
            ["isRequired", "displayTypeId", "parentQuestionGroupQuestionId"],
            {
              is: (
                isRequired: boolean,
                displayTypeId: number,
                parentQuestionGroupQuestionId?: number
              ) =>
                isRequired &&
                (displayTypeId === QuestionDisplayTypeEnum.Text ||
                  displayTypeId === QuestionDisplayTypeEnum.TextArea) &&
                parentQuestionGroupQuestionId === null,
              then: yup.string().nullable().trim().required(requiredMsg),
              otherwise: yup.string().nullable(),
            }
          ),
        documents: yup
          .mixed()
          .when(["isRequired", "displayTypeId", "isVisible"], {
            is: (
              isRequired: boolean,
              displayTypeId: number,
              isVisible: boolean
            ) =>
              isRequired &&
              isVisible &&
              displayTypeId === QuestionDisplayTypeEnum.Upload,
            then: yup.mixed().nullable().required(requiredMsg),
            otherwise: yup.mixed().nullable(),
          }),
      })
      .test("checkConditionalAnswer", "isRequired", function (value) {
        if (value.parentQuestionGroupQuestionId != null) {
          const parrentQuestion = this.parent.find(
            (x: QuestionnaireQuestionDto) =>
              x.questionGroupQuestionId === value.parentQuestionGroupQuestionId
          );

          let currentValue;
          let parentQuestionValue;
          let path;

          switch (value.displayTypeId) {
            case QuestionDisplayTypeEnum.Text:
            case QuestionDisplayTypeEnum.TextArea:
            case QuestionDisplayTypeEnum.Time:
            case QuestionDisplayTypeEnum.Date:
              currentValue = value.answer?.trim();
              path = `${this.path}.answer`;
              break;
            case QuestionDisplayTypeEnum.Select:
            case QuestionDisplayTypeEnum.Radio:
              currentValue = value.answerId;
              path = `${this.path}.answerId`;
              break;
            case QuestionDisplayTypeEnum.MultiSelect:
              currentValue = value.answerIds;
              path = `${this.path}.answerIds`;
              break;
            case QuestionDisplayTypeEnum.Upload:
              currentValue = value.documents;
              path = `${this.path}.documents`;
              break;
          }

          switch (parrentQuestion.displayTypeId) {
            case QuestionDisplayTypeEnum.Select:
            case QuestionDisplayTypeEnum.Radio:
              parentQuestionValue = parrentQuestion.answerId;
              if (
                parentQuestionValue !== undefined &&
                parentQuestionValue === value.parentAnswerId &&
                (currentValue === undefined || currentValue === "")
              ) {
                return this.createError({ path: path, message: requiredMsg });
              }
              break;
            case QuestionDisplayTypeEnum.MultiSelect:
              parentQuestionValue = parrentQuestion.answerIds;
              if (
                parentQuestionValue?.includes(value.parentAnswerId) &&
                (currentValue === undefined || currentValue === "")
              ) {
                return this.createError({ path: path, message: requiredMsg });
              }
              break;
          }
          return true;
        } else {
          return true;
        }
      })
  );
};

export default InitialConversationQuestionDataItemValidationSchema;
