import { CodeBook, UploadFile } from "@king-ict/eupisi-ui/models";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import {
  getRequest,
  getInitialConversationByRequestId as getInitialConversationByRequestIdApi,
} from "../api/Request/RequestApi";
import { RootState } from "../app/rootReducer";
import ParentNumberEnum from "../models/Person/ParentNumberEnum";
import QuestionnaireQuestionGroup from "../models/Questionnaire/QuestionnaireQuestionGroup";
import RequestAnswer from "../models/Questionnaire/RequestAnswer";
import InitialConversationQuestionDataItem from "../models/Request/InitialConversationQuestionDataItem";
import InitialConversationQuestionGroup from "../models/Request/InitialConversationQuestionGroup";
import { PersonDetails } from "../models/Request/PersonDetails";
import Request, { RequestInitial } from "../models/Request/Request";
import RequestListItem from "../models/Request/RequestListItem";
import RequestTypeEnum from "../models/Request/RequestTypeEnum";
import { Attachment } from "../models/Shared/Attachment";
import { ValidationFailure } from "../models/Shared/SaveResult";

interface RequestState {
  requests: RequestListItem[];
  request: Request;
}

let initialState: RequestState = {
  requests: [],
  request: RequestInitial,
};

export const getInitialConversationByRequestId = createAsyncThunk(
  "requests/getInitialConversationByRequestId",
  async (requestId: number) => {
    const response = await getInitialConversationByRequestIdApi(requestId);
    return response;
  }
);

export const getRequestById = createAsyncThunk(
  "request/getDraftRequest",
  async (id: number) => {
    const response = await getRequest(id);
    return response;
  }
);

export const selectConversationDataByQuestionGroupSort = createSelector(
  (state: RootState) => state.request.request.initialConversationQuestionGroups,
  (_: any, questionGroupSort: number) => questionGroupSort,
  (
    initialConversationQuestionGroups: InitialConversationQuestionGroup[],
    questionGroupSort: number
  ) =>
    initialConversationQuestionGroups
      .filter((qg) => qg.sort === questionGroupSort)
      .flatMap((qg) => qg.questions)
);

export const getPersonsOibs = createSelector(
  (state: RootState) => state.request.request,
  (request: Request) => {
    return {
      applicantParentOib: request.applicantParentDetails.person.oib,
      otherParentOib: request.otherParentDetails?.person?.oib,
      childOib: request.childDetails?.person?.oib,
    };
  }
);

export const selectMainInfo = createSelector(
  (state: RootState) => state.request.request,
  (request: Request) => {
    return {
      uId: request.uId,
      applicantParentOib: request.applicantParentDetails.person.oib,
      childOib: request.childDetails?.person?.oib,
    };
  }
);

const requestSlice = createSlice({
  name: "request",
  initialState: initialState,
  reducers: {
    updateRequestErrors(state, action: PayloadAction<ValidationFailure[]>) {
      //state.errors = action.payload;
    },
    updateRequest(state, action: PayloadAction<Request>) {
      if (
        action.payload.commercialProgramId &&
        action.payload.commercialProgramId > 0
      ) {
        action.payload.isPrimaryInstitutionCommercialProgramSelected = true;
      }
      if (
        action.payload.isInstitutionOutsideResidence &&
        action.payload.outsideEnrollmentAreaCommercialProgramId &&
        action.payload.outsideEnrollmentAreaCommercialProgramId > 0
      ) {
        action.payload.isSecondaryInstitutionCommercialProgramSelected = true;
      }
      if (action.payload && !action.payload.questionnaireAnswers) {
        action.payload.questionnaireAnswers = [] as RequestAnswer[];
      }
      state.request = {
        ...state.request,
        ...action.payload,
        //questionnaireAnswers: [],
      };
    },
    updateRequestSchoolYear(state, action: PayloadAction<number>) {
      state.request.schoolYearId = action.payload;
    },
    updateRequestParentNumber(state, action: PayloadAction<ParentNumberEnum>) {
      state.request.parentNumberId = action.payload;
      if (action.payload === ParentNumberEnum.TwoParents) {
        state.request.otherParentDetails = {} as PersonDetails;
      }
    },
    updateRequestStep(state, action: PayloadAction<number>) {
      state.request.step = action.payload;
    },
    updateRequestSubStep(state, action: PayloadAction<number>) {
      state.request.subStep = action.payload;
    },
    updateRequestType(state, action: PayloadAction<RequestTypeEnum>) {
      state.request.type = action.payload;
    },
    updateRequestApplicant(state, action: PayloadAction<PersonDetails>) {
      state.request.applicantParentDetails = action.payload;
    },
    updateRequestOtherParent(
      state = initialState,
      action: PayloadAction<PersonDetails>
    ) {
      state.request.otherParentDetails = action.payload;
    },
    updateRequestChild(state, action: PayloadAction<PersonDetails>) {
      state.request.childDetails = {
        ...state.request.childDetails,
        ...action.payload,
      };
    },

    setQuestionAnswer(
      state,
      action: PayloadAction<QuestionnaireQuestionGroup[]>
    ) {
      const newRequest = {
        ...state.request,
      };

      const groupPayload = action.payload;

      groupPayload.forEach((qg) => {
        qg.questions.forEach((q) => {
          let questionAnswer: RequestAnswer = {
            id: 0,
            questionId: q.questionGroupQuestionId,
            answerId: 0,
            answerText: "",
          };

          const i = newRequest.questionnaireAnswers?.findIndex(
            (_element) => _element.questionId === questionAnswer.questionId
          );
          if (i < 0) {
            newRequest.questionnaireAnswers.push(questionAnswer);
          }
        });
      });

      state.request = newRequest;
    },

    updateInsertQuestionAnswer(
      state,
      action: PayloadAction<{
        questionId: number;
        answerId?: number;
        answerIds?: number[];
        answerText?: string;
      }>
    ) {
      const questionId = action.payload.questionId;
      const answerId = action.payload.answerId || 0;
      const answerIds = action.payload.answerIds || [];
      const answerText = action.payload.answerText || undefined;
      const newRequest = {
        ...state.request,
      };

      //moramo updejtati više odabira
      if (answerIds && answerIds.length > 0) {
        var newQuestionnaireAnswers = newRequest.questionnaireAnswers.filter(
          (obj) => {
            return obj.questionId !== questionId;
          }
        );

        answerIds.forEach((_answerId) => {
          let _questionAnswer: RequestAnswer = {
            id: 0,
            questionId: questionId,
            answerId: Number(_answerId),
            answerText: answerText,
          };

          newQuestionnaireAnswers.push(_questionAnswer);
        });
        newRequest.questionnaireAnswers = newQuestionnaireAnswers;
      } else if (newRequest.questionnaireAnswers && questionId) {
        let questionAnswer: RequestAnswer = {
          id: 0,
          questionId: questionId,
          answerId: Number(answerId),
          answerText: answerText,
        };
        const i = newRequest.questionnaireAnswers.findIndex(
          (_element) => _element.questionId === questionId
        );
        if (i > -1) newRequest.questionnaireAnswers[i] = questionAnswer;
        else newRequest.questionnaireAnswers.push(questionAnswer);
      }
      state.request = newRequest;
    },

    updateRequestInitialConversationQuestionGroup(
      state,
      action: PayloadAction<{
        questionGroup: QuestionnaireQuestionGroup;
        initialConversationQuestionsData: InitialConversationQuestionDataItem[];
      }>
    ) {
      const groupPayload = action.payload.questionGroup;
      const dataPayload = action.payload.initialConversationQuestionsData;
      const groupState = state.request.initialConversationQuestionGroups;
      //const questionAnswerState = state.request.questionnaireAnswers;
      const newRequest = {
        ...state.request,
      };

      let initialConversationQuestionGroup = {
        id: groupPayload.id,
        sort: groupPayload.sort,
        name: groupPayload.name,
        questions: dataPayload?.map((qd) => {
          return { ...qd };
        }),
      } as InitialConversationQuestionGroup;

      initialConversationQuestionGroup.questions.forEach((qd) => {
        const question = groupPayload.questions.find(
          (q) => q.questionGroupQuestionId === qd.questionGroupQuestionId
        );

        if (!question) {
          //ako nismo našli pitanje/odgovor na listi, da li ga treba maknuti?? Ako se netko možda vratio i maknuo jedan od izbora iz multiselecta?
          return;
        }

        let questionAnswer: RequestAnswer = {
          id: 0,
          questionId: question.questionGroupQuestionId,
          answerId: 0,
          answerText: undefined,
        };

        qd.name = question.name;

        if (qd.answerId && qd.answerId > 0) {
          // Resolve answer for single selection
          qd.answer = question.answers.find((a) => a.id === qd.answerId)?.name;

          questionAnswer.answerId = qd.answerId;
        } else if (qd.answerIds && qd.answerIds.length > 0) {
          // Resolve answer for multiple selection
          qd.answer = question.answers
            .filter((a) => qd.answerIds?.includes(a.id))
            .map((a) => a.name)
            .join(", ");

          // ovdje kreiramo RequestAnswerDto objekt za svaki odabrani odgovor iz multiselecta
          question.answers
            .filter((a) => qd.answerIds?.includes(a.id))
            .forEach((a) => {
              let questionAnswer: RequestAnswer = {
                id: 0,
                questionId: question.questionGroupQuestionId,
                answerId: a.id,
                answerText: undefined,
              };

              console.log("ovo je jedan odgovor za multiselect");
              console.log(questionAnswer);

              //kako ćemo maknuti element sa liste ako se netko vrati i makne jedan od odabira iz multiselecta?
              const i = newRequest.questionnaireAnswers.findIndex(
                (_element) => _element.questionId === questionAnswer.questionId
              );
              if (i > -1) newRequest.questionnaireAnswers[i] = questionAnswer;
              else newRequest.questionnaireAnswers.push(questionAnswer);

              state.request = newRequest;
            });

          return;
        } else questionAnswer.answerText = qd.answer;

        const i = newRequest.questionnaireAnswers.findIndex(
          (_element) => _element.questionId === questionAnswer.questionId
        );
        if (i > -1) newRequest.questionnaireAnswers[i] = questionAnswer;
        else newRequest.questionnaireAnswers.push(questionAnswer);
      });

      const groupStateIndex = groupState.findIndex(
        (qg) => qg.id === groupPayload.id
      );

      if (groupStateIndex === -1) {
        groupState.push(initialConversationQuestionGroup);
      } else {
        groupState[groupStateIndex] = initialConversationQuestionGroup;
      }

      state.request = newRequest;
    },
    clearRequestData(state) {
      state.request = initialState.request;
    },
    setRequestUID(state, action: PayloadAction<string>) {
      state.request.uId = action.payload;
    },
    setRequestID(state, action: PayloadAction<number>) {
      state.request.id = action.payload;
    },
    updateRequestOtherInstitutionSelect(
      state,
      action: PayloadAction<CodeBook[]>
    ) {
      state.request.otherInstitutionSelect = action.payload;
    },
    updateRequestIsInstitutionOutsideResidence(
      state,
      action: PayloadAction<boolean>
    ) {
      state.request.isInstitutionOutsideResidence = action.payload;
    },
    updateRequestInstitutionOutsideResidenceReasonId(
      state,
      action: PayloadAction<number>
    ) {
      state.request.institutionOutsideResidenceReasonId = action.payload;
    },
    // DOCUMENTS
    updateChildDocuments(state, action: PayloadAction<UploadFile[]>) {
      state.request = {
        ...state.request,
        childUploadFiles: action.payload,
      };
    },
    updateMedicalDocuments(state, action: PayloadAction<UploadFile[]>) {
      state.request = {
        ...state.request,
        medicalUploadFiles: action.payload,
      };
    },
    updatePsychologyDocuments(state, action: PayloadAction<UploadFile[]>) {
      state.request = {
        ...state.request,
        psychologyUploadFiles: action.payload,
      };
    },
    updateEducationalRehabilitationDocuments(
      state,
      action: PayloadAction<UploadFile[]>
    ) {
      state.request = {
        ...state.request,
        educationalRehabilitationUploadFiles: action.payload,
      };
    },
    updateOtherParentDocuments(state, action: PayloadAction<UploadFile[]>) {
      state.request = {
        ...state.request,
        otherUploadFiles: action.payload,
      };
    },
    updatePsychophysicalConditionDecisionDocuments(
      state,
      action: PayloadAction<UploadFile[]>
    ) {
      state.request = {
        ...state.request,
        psychophysicalConditionDecisionUploadFiles: action.payload,
      };
    },
    updateUniqueBodyOfExpertiseDocuments(
      state,
      action: PayloadAction<UploadFile[]>
    ) {
      state.request = {
        ...state.request,
        uniqueBodyOfExpertiseUploadFiles: action.payload,
      };
    },

    updateChildAttachments(state, action: PayloadAction<Attachment[]>) {
      state.request = {
        ...state.request,
        childAttachments: action.payload,
      };
    },
    updateMedicalAttachments(state, action: PayloadAction<Attachment[]>) {
      state.request = {
        ...state.request,
        medicalAttachments: action.payload,
      };
    },
    updatePshilogyAttachments(state, action: PayloadAction<Attachment[]>) {
      state.request = {
        ...state.request,
        psychologyAttachments: action.payload,
      };
    },
    updateEducationalRehabilitationAttachments(
      state,
      action: PayloadAction<Attachment[]>
    ) {
      state.request = {
        ...state.request,
        educationalRehabilitationAttachments: action.payload,
      };
    },
    updateOtherParentAttachments(state, action: PayloadAction<Attachment[]>) {
      state.request = {
        ...state.request,
        otherAttachments: action.payload,
      };
    },
    updatePsychophysicalConditionDecisionAttachments(
      state,
      action: PayloadAction<Attachment[]>
    ) {
      state.request = {
        ...state.request,
        psychophysicalConditionDecisionAttachments: action.payload,
      };
    },
    updateUniqueBodyOfExpertiseAttachments(
      state,
      action: PayloadAction<Attachment[]>
    ) {
      state.request = {
        ...state.request,
        uniqueBodyOfExpertiseAttachments: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getRequestBasicInfo.fulfilled, (state, { payload }) => {
      //   state.request.parentNumberId = payload.parentNumberId;
      //   state.request.statusId = payload.statusId;
      //   state.request.uId = payload.uId;
      //   state.request.createdByAdmin = payload.createdByAdmin;
      // })

      .addCase(
        getInitialConversationByRequestId.fulfilled,
        (state, { payload }) => {
          state.request.initialConversationQuestionGroups = payload;
        }
      )
      .addCase(getRequestById.fulfilled, (state, { payload }) => {
        state.request = { ...state.request, ...payload };
      });
  },
});

export const {
  updateRequestErrors,
  updateRequest,
  updateRequestSchoolYear,
  updateRequestParentNumber,
  updateRequestStep,
  updateRequestSubStep,
  updateRequestType,
  updateRequestApplicant,
  updateRequestOtherParent,
  updateRequestChild,
  setQuestionAnswer,
  updateInsertQuestionAnswer,
  updateRequestInitialConversationQuestionGroup,
  clearRequestData,
  setRequestUID,
  setRequestID,
  updateRequestOtherInstitutionSelect,
  updateRequestIsInstitutionOutsideResidence,
  updateRequestInstitutionOutsideResidenceReasonId,

  updateChildDocuments,
  updateMedicalDocuments,
  updatePsychologyDocuments,
  updateEducationalRehabilitationDocuments,
  updateOtherParentDocuments,
  updatePsychophysicalConditionDecisionDocuments,
  updateUniqueBodyOfExpertiseDocuments,

  updateChildAttachments,
  updateMedicalAttachments,
  updatePshilogyAttachments,
  updateEducationalRehabilitationAttachments,
  updateOtherParentAttachments,
  updatePsychophysicalConditionDecisionAttachments,
  updateUniqueBodyOfExpertiseAttachments,
} = requestSlice.actions;

export default requestSlice.reducer;
