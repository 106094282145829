import { LabelProps } from "@king-ict/eupisi-ui/components";

import ImportantDate from "../models/ImportantDate/ImportantDate";
import RequestStatusEnum from "../models/Request/RequestStatusEnum";

export function getStatusLabelColor(
  importantDate: ImportantDate
): LabelProps["color"] {
  var color = "danger"; // provide a default
  if (new Date(importantDate.dateTimeFrom).getTime() > new Date().getTime()) {
    color = "warning"; // yellow
  } else if (
    new Date(importantDate.dateTimeTo).getTime() < new Date().getTime()
  ) {
    color = "secondary"; // gray
  } else if (
    new Date(importantDate.dateTimeFrom).getTime() <= new Date().getTime() &&
    new Date(importantDate.dateTimeTo).getTime() >= new Date().getTime()
  ) {
    color = "success"; // green
  }
  return color as LabelProps["color"];
}

export function getStatusBadgeString(importantDate: ImportantDate): string {
  var status = "NEPOZNATO"; // provide a default
  if (new Date(importantDate.dateTimeFrom) > new Date()) {
    status = "NIJE ZAPOČETO"; // yellow
  } else if (new Date(importantDate.dateTimeTo) < new Date()) {
    status = "ZAVRŠENO"; // gray
  } else if (
    new Date(importantDate.dateTimeFrom) <= new Date() &&
    new Date(importantDate.dateTimeTo) >= new Date()
  ) {
    status = "U TIJEKU"; // green
  }
  return status;
}

export const getRequestStatusColor = (status: RequestStatusEnum) => {
  var color: LabelProps["color"];
  switch (status) {
    case RequestStatusEnum.Draft:
      color = "noted-2";
      break;
    case RequestStatusEnum.Submitted:
    case RequestStatusEnum.ProcedureSubmitted:
      color = "primary"; // green
      break;
    case RequestStatusEnum.Cancelled:
      color = "danger"; // green
      break;
    case RequestStatusEnum.Registered:
      color = "success"; // yellow
      break;
    case RequestStatusEnum.DecisionOnAppropriateProgram:
    case RequestStatusEnum.DecisionOnEnrollmentPostponement:
    case RequestStatusEnum.EnrollmentApproved:
    case RequestStatusEnum.EnrollmentConfirmed:
    case RequestStatusEnum.OnSchoolCandidateList:
    case RequestStatusEnum.OpinionWithRecommendation:
    case RequestStatusEnum.ProcedureApproved:
    case RequestStatusEnum.RegistrationFormCreated:
    case RequestStatusEnum.RegistrationFormCreating:
    case RequestStatusEnum.RegistrationSigned:
      color = "warning"; // yellow
      break;
    default:
      color = "noted-2";
  }
  return color;
};

export function hrDateWithTime(date: Date) {
  return new Date(date).toLocaleString("hr-HR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
