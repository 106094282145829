import { Button, IconWrapper } from "@king-ict/eupisi-ui/components";
import { ReactComponent as Download } from "@king-ict/eupisi-ui/components/Icon/Download.svg";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { getInitialConversationQuestionnaireDownload } from "../../../api/Request/RequestApi";
import { RequestInfoPanel } from "../../Request/RequestComponents/RequestInfoPanel";

interface Props {
  requestID?: number;
  showEdit: boolean;
  moveToSpecificStep?: () => void;
}

export default function InitialConversationDetails({
  requestID,
  showEdit,
  moveToSpecificStep,
}: Props) {
  const { t } = useTranslation();
  const handleInitialConversationDownload = async () => {
    console.debug("requestID", requestID);
    if (requestID) {
      await getInitialConversationQuestionnaireDownload(+requestID);
    }
  };
  return (
    <RequestInfoPanel
      title={t("requestReviewStep.initialConversation")}
      showEdit={showEdit}
      onEditClick={moveToSpecificStep}
    >
      <Row>
        <Col md={10}>
          <Button
            size="sm"
            outline
            icon={
              <IconWrapper color="primary">
                <Download />
              </IconWrapper>
            }
            color="primary"
            onClick={handleInitialConversationDownload}
          >
            {t("common.download")}
          </Button>
        </Col>
      </Row>
    </RequestInfoPanel>
  );
}
