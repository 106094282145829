import React, { useEffect } from "react";

import { Typography } from "@king-ict/eupisi-ui/components";
import { Button } from "@king-ict/eupisi-ui/components";
import qs from "qs";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  getEnrollmentDecisionByRequestId,
  getRequestDocument,
} from "../../../api/Request/RequestApi";
import { DocumentType } from "../../../models/Request/DocumentType";
import { RequestFormCard, RequestFormTopMessage } from "../RequestComponents";

interface Props {
  moveToSpecificStep: (step: number, substep?: number) => void;
}

export default function EnrollmentDecisionStep({ moveToSpecificStep }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const params = qs.parse(location.search.substring(1));
  const { id } = params;

  const handleEnrollmentDownload = async () => {
    await getEnrollmentDecisionByRequestId(+id!)
      .then((response) => {
        if (response) {
          if (
            response.attachment !== null &&
            response.attachment !== undefined
          ) {
            getRequestDocument(
              response.attachment.id,
              response.requestId,
              DocumentType.EnrollmentDecision
            );
          }
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    //
  }, []);

  return (
    <>
      <RequestFormTopMessage
        title={t("decisionEnrollmentStep.mainInfoTitle")}
        infoMessage={t("decisionEnrollmentStep.mainInfoMessage")}
      />
      <RequestFormCard
        cardTitle={t("decisionEnrollmentStep.mainInfoTitle")}
        cardSubTitle={""}
      >
        <Row>
          <Col md={6} xs={6}>
            <Typography component="div" className={"decisionEnrollmentTitle"}>
              {t("decisionEnrollmentStep.decisionEnrollment")}
            </Typography>
          </Col>

          <Col md={6} xs={6} className="text-right">
            <Button
              onClick={handleEnrollmentDownload}
              size="sm"
              color="primary"
              outline
            >
              {t("decisionEnrollmentStep.previewEnrollmentDecision")}
            </Button>
          </Col>
        </Row>
      </RequestFormCard>
    </>
  );
}
