import { TextField, Typography } from "@king-ict/eupisi-ui/components";
import { CodeBook } from "@king-ict/eupisi-ui/models";
import { hrDate } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ParentshipTypeEnum from "../../../models/Person/ParentshipTypeEnum";
import { PersonDetails } from "../../../models/Request/PersonDetails";
import { RequestInfoPanel } from "../../Request/RequestComponents/RequestInfoPanel";

interface Props {
  emptyValue: string;
  applicantParent: PersonDetails;
  parentNumber?: string;
  countries: CodeBook[];
  citizenships: CodeBook[];
  nationalMinorities: CodeBook[];
  nationalities: CodeBook[];
  parentshipTypes: CodeBook[];
  professionalQualifications: CodeBook[];
  genders: CodeBook[];
  showEdit: boolean;
  moveToSpecificStep?: () => void;
}

export default function ApplicantParentDetails({
  emptyValue,
  applicantParent,
  parentNumber,
  countries,
  citizenships,
  nationalMinorities,
  nationalities,
  parentshipTypes,
  professionalQualifications,
  genders,
  showEdit,
  moveToSpecificStep,
}: Props) {
  const { t } = useTranslation();
  if (!applicantParent.person) return null;
  return (
    <RequestInfoPanel
      title={t("requestReviewStep.applicantParent")}
      subTitle={t("person.personalInformation")}
      showEdit={showEdit}
      onEditClick={moveToSpecificStep}
    >
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.firstName")}
            helperText={t("common.birthRegistry")}
            value={applicantParent?.firstName || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.lastName")}
            helperText={t("common.birthRegistry")}
            value={applicantParent?.lastName || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.oib")}
            helperText={t("common.oibApi")}
            value={applicantParent?.person?.oib || emptyValue}
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.dateOfBirth")}
            helperText={t("common.birthRegistry")}
            value={
              applicantParent && applicantParent.dateOfBirth
                ? hrDate(applicantParent?.dateOfBirth)
                : emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.sex")}
            helperText={t("common.birthRegistry")}
            value={
              genders?.find((x) => x.id === applicantParent.genderId)?.name ||
              emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.birthplace")}
            helperText={t("common.birthRegistry")}
            value={applicantParent?.cityOfBirth || emptyValue}
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.countryBirth")}
            helperText={t("common.birthRegistry")}
            value={
              countries?.find((x) => x.id === applicantParent.countryOfBirthId)
                ?.name || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.citizenship")}
            helperText={t("common.birthRegistry")}
            value={
              citizenships?.find((x) => x.id === applicantParent.citizenshipId)
                ?.name || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.nationalMinority")}
            value={
              nationalMinorities?.find(
                (x) => x.id === applicantParent.nationalMinorityId
              )?.name || emptyValue
            }
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.nationality")}
            value={
              nationalities?.find((x) => x.id === applicantParent.nationalityId)
                ?.name || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.profession")}
            value={applicantParent.professionName || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.professionalQualification")}
            value={
              professionalQualifications?.find(
                (v) => v.id === applicantParent.professionalQualificationId
              )?.name || emptyValue
            }
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="email"
            label={t("person.email")}
            value={applicantParent?.email || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.phoneNumber")}
            value={applicantParent?.phoneNumber || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.parentshipType")}
            value={
              parentshipTypes?.find(
                (v) => v.id === applicantParent.parentshipTypeId
              )?.name || emptyValue
            }
            plaintext
          />
        </Col>
        {parentNumber && (
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.parentNumber")}
              value={parentNumber || emptyValue}
              plaintext
            />
          </Col>
        )}
      </Row>
      {applicantParent.parentshipTypeId === ParentshipTypeEnum.Custodian && (
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.custodian")}
              value={applicantParent.custody || emptyValue}
              plaintext
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <Typography variant="smallHeading">
            {t("person.permanentResidenceInformation1")}
          </Typography>
          {/* <Typography variant="smallHeading">
                        {t("person.permanentResidenceInformation2")}
                      </Typography> */}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t("person.country")}`}
            value={
              countries?.find(
                (x) => x.id === applicantParent.residence?.countryId
              )?.name || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t(
              "person.municipality"
            )}`}
            value={
              applicantParent?.residence?.permanentAddress?.municipality ||
              applicantParent?.residence?.address ||
              emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t("person.town")}`}
            value={
              applicantParent?.residence?.permanentAddress?.town || emptyValue
            }
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t(
              "person.postalCode"
            )}`}
            value={
              applicantParent?.residence?.permanentAddress?.postalCode ||
              emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t("person.street")}`}
            value={
              applicantParent?.residence?.permanentAddress?.street || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t(
              "person.streetNumber"
            )}`}
            value={
              applicantParent?.residence?.permanentAddress?.houseNumber ||
              emptyValue
            }
            plaintext
          />
        </Col>
      </Row>
      {applicantParent?.residence &&
        applicantParent?.residence?.temporaryAddress && (
          <>
            <Row>
              <Col md={12}>
                <Typography variant="smallHeading">
                  {t("person.temporaryResidenceInformation")}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.municipality"
                  )}`}
                  value={
                    applicantParent?.residence?.temporaryAddress
                      ?.municipality || emptyValue
                  }
                  plaintext
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.town"
                  )}`}
                  value={
                    applicantParent?.residence?.temporaryAddress?.town ||
                    emptyValue
                  }
                  plaintext
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.postalCode"
                  )}`}
                  value={
                    applicantParent?.residence?.temporaryAddress?.postalCode ||
                    emptyValue
                  }
                  plaintext
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.street"
                  )}`}
                  value={
                    applicantParent?.residence?.temporaryAddress?.street ||
                    emptyValue
                  }
                  plaintext
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.streetNumber"
                  )}`}
                  value={
                    applicantParent?.residence?.temporaryAddress?.houseNumber ||
                    emptyValue
                  }
                  plaintext
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.streetNumberAddition"
                  )}`}
                  value={emptyValue}
                  plaintext
                />
              </Col>
            </Row>
          </>
        )}
    </RequestInfoPanel>
  );
}
