enum QuestionnaireDataTypeEnum {
  Text = 1,
  Date = 2,
  Integer = 3,
  Decimal = 4,
  Time = 5,
  File = 6,
}

export default QuestionnaireDataTypeEnum;
