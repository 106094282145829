import { useEffect, useState } from "react";

import { CodeBook } from "@king-ict/eupisi-ui/models";
import {
  getAgeFromBirthDate,
  getYearFromDate,
  hrDate,
} from "@king-ict/eupisi-ui/utils";

import { useTypedSelector } from "../../../app/rootReducer";
import { useAppDispatch } from "../../../app/store";
import AnswerTypeEnum from "../../../models/Questionnaire/AnswerTypeEnum";
import ChildAnswerTypeEnum from "../../../models/Questionnaire/ChildAnswerTypeEnum";
import ParentAnswerTypeEnum from "../../../models/Questionnaire/ParentAnswerTypeEnum";
import QuestionDisplayTypeEnum from "../../../models/Questionnaire/QuestionDisplayTypeEnum";
import QuestionnaireQuestion, {
  getAnswerTypeAndSubType,
  isConditionallyShown,
  isEligibleForAutomaticAnswering,
} from "../../../models/Questionnaire/QuestionnaireQuestion";
import QuestionnaireQuestionDto from "../../../models/Request/InitialConversationQuestionDataItem";
import Request from "../../../models/Request/Request";
import {
  useGetGendersQuery,
  useGetProfessionalQualificationsQuery,
} from "../../../queries/dataQuery";
import { selectInitialConversationQuestionGroupBySort } from "../../../slices/questionnaireSlice";
import { updateInsertQuestionAnswer } from "../../../slices/requestSlice";

interface Props {
  subStep: number;
}

export const useInitialConversationStep = ({ subStep }: Props) => {
  const request = useTypedSelector((state) => state.request.request);
  const { data: genders } = useGetGendersQuery();
  const {
    data: professionalQualifications,
  } = useGetProfessionalQualificationsQuery();
  const dispatch = useAppDispatch();
  const questionGroupSort = subStep + 1;
  const questionGroup = useTypedSelector((state) =>
    selectInitialConversationQuestionGroupBySort(state, questionGroupSort)
  );

  const [
    questionGroupConversationItems,
    setQuestionGroupConversationItems,
  ] = useState<QuestionnaireQuestionDto[]>([]);

  useEffect(() => {
    if (
      request &&
      questionGroup &&
      questionGroupConversationItems &&
      genders &&
      professionalQualifications
    ) {
      const resolvedItems = resolveConversationItems(
        questionGroup.questions,
        questionGroupConversationItems,
        request,
        genders,
        professionalQualifications
      );
      if (questionGroup.id === 1 || questionGroup.id === 2) {
        resolvedItems.forEach((item) => {
          dispatch(
            updateInsertQuestionAnswer({
              questionId: item.questionGroupQuestionId,
              answerId: item.answerId,
              answerText: item.answer,
            })
          );
        });
      }
      setQuestionGroupConversationItems(resolvedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionGroup, genders, professionalQualifications]);

  const handleSetQuestionGroupConversationData = (
    data:
      | QuestionnaireQuestionDto[]
      | ((prevData: QuestionnaireQuestionDto[]) => QuestionnaireQuestionDto[])
  ) => {
    const itemsToResolve =
      typeof data === "function" ? data(questionGroupConversationItems) : data;
    const resolvedItems = resolveConversationItems(
      questionGroup?.questions!,
      itemsToResolve,
      request,
      genders!,
      professionalQualifications!
    );
    setQuestionGroupConversationItems(resolvedItems);
  };
  return [
    questionGroup,
    questionGroupConversationItems,
    handleSetQuestionGroupConversationData,
  ] as const;
};

const emptyValue = "-";

const resolveConversationItems = (
  questionGroupQuestions: QuestionnaireQuestion[],
  questionGroupConversationItems: QuestionnaireQuestionDto[],
  request: Request,
  genders: CodeBook[],
  professionalQualifications: CodeBook[]
) => {
  let items: QuestionnaireQuestionDto[] = [];
  const isRepeatableQuestionSet = questionGroupQuestions.some(
    (question) => question.isRepeatable
  );

  if (isRepeatableQuestionSet && questionGroupConversationItems.length > 0) {
    const maxRepeatableQuestionSort = questionGroupConversationItems.reduce(
      (prev, current) => (prev.sort > current.sort ? prev : current)
    ).sort;

    for (let i = 0; i <= maxRepeatableQuestionSort; i++) {
      questionGroupQuestions.forEach((q) => {
        const conversationItem = questionGroupConversationItems.find(
          (ci) =>
            ci.questionGroupQuestionId === q.questionGroupQuestionId &&
            ci.sort === i
        );
        items.push(
          initializeConversationItem(
            q,
            request,
            genders!,
            professionalQualifications,
            conversationItem,
            i
          )
        );
      });
    }
  } else {
    questionGroupQuestions.forEach((q) => {
      const conversationItem = questionGroupConversationItems.find(
        (ci) => ci.questionGroupQuestionId === q.questionGroupQuestionId
      );

      if (isConditionallyShown(q)) {
        const parentConversationItem = items.find(
          (ci) =>
            ci.questionGroupQuestionId === q.parentQuestionGroupQuestionId &&
            (+(ci.answerId ?? 0) === q.parentAnswerId ||
              ci.answerIds?.includes(q.parentAnswerId ?? 0))
        );

        if (!parentConversationItem || !q.parentAnswerId) {
          return;
        }
        if (
          parentConversationItem.displayTypeId ===
            QuestionDisplayTypeEnum.MultiSelect &&
          !parentConversationItem?.answerIds?.includes(q.parentAnswerId)
        ) {
          return;
        }
        if (
          parentConversationItem.displayTypeId ===
            QuestionDisplayTypeEnum.Select &&
          q.parentAnswerId !== parentConversationItem.answerId
        ) {
          return;
        }
      }
      items.push(
        initializeConversationItem(
          q,
          request,
          genders!,
          professionalQualifications,
          conversationItem,
          0
        )
      );
    });
  }

  return items;
};

const initializeConversationItem = (
  question: QuestionnaireQuestion,
  request: Request,
  genders: CodeBook[],
  professionalQualifications: CodeBook[],
  existingConversationItem?: QuestionnaireQuestionDto,
  repeatableSort?: number
) => {
  let conversationItem = {
    ...existingConversationItem,
    questionGroupQuestionId: question.questionGroupQuestionId,
    name: question.name,
    displayTypeId: question.displayTypeId,
    isRequired: question.isRequired,
    isRepeatable: question.isRepeatable,
    sort: question.isRepeatable ? repeatableSort : undefined,
    isVisible: question.isVisible,
    parentQuestionGroupQuestionId: question.parentQuestionGroupQuestionId,
    parentAnswerId: question.parentAnswerId,
    answer: isEligibleForAutomaticAnswering(question)
      ? resolveQuestionAnswer(
          question,
          request,
          genders!,
          professionalQualifications
        )
      : existingConversationItem?.answer ??
        resolveQuestionAnswerText(question, request),
    answerId:
      existingConversationItem?.answerId ??
      resolveQuestionAnswerId(question, request),
    answerIds:
      existingConversationItem?.answerIds ??
      resolveQuestionAnswerIds(question, request),
  } as QuestionnaireQuestionDto;

  return conversationItem;
};

const resolveQuestionAnswerId = (
  question: QuestionnaireQuestion,
  request: Request
) => {
  let answerId = request?.questionnaireAnswers?.find(
    (a) => a.questionId === question.id
  )?.answerId;
  return answerId;
};
const resolveQuestionAnswerIds = (
  question: QuestionnaireQuestion,
  request: Request
) => {
  let answerIds = request?.questionnaireAnswers
    ?.filter((a) => a.questionId === question.id)
    .map((a) => a.answerId);
  return answerIds;
};

const resolveQuestionAnswerText = (
  question: QuestionnaireQuestion,
  request: Request
) => {
  let answerText: string =
    request?.questionnaireAnswers?.find(
      (a) => a.questionId === question.id && a.answerText
    )?.answerText ?? "";
  return answerText;
};

const resolveQuestionAnswer = (
  question: QuestionnaireQuestion,
  request: Request,
  genders: CodeBook[],
  professionalQualifications: CodeBook[]
) => {
  const { answerType, answerSubType } = getAnswerTypeAndSubType(question);
  let answer = emptyValue;
  console.log(answerType);

  switch (answerType) {
    case AnswerTypeEnum.Parent:
      answer = resolveParentQuestionAnswer(
        answerSubType as ParentAnswerTypeEnum,
        request,
        professionalQualifications
      );
      break;
    case AnswerTypeEnum.ParentII:
      answer = resolveOtherParentQuestionAnswer(
        answerSubType as ParentAnswerTypeEnum,
        request,
        professionalQualifications
      );
      break;
    case AnswerTypeEnum.Child:
      answer = resolveChildQuestionAnswer(
        answerSubType as ChildAnswerTypeEnum,
        request,
        genders!
      );
      break;
  }
  return answer;
};

const resolveOtherParentQuestionAnswer = (
  answerType: ParentAnswerTypeEnum,
  request: Request,
  professionalQualifications: CodeBook[]
) => {
  const otherParent = request.otherParentDetails;
  let answer = emptyValue;
  if (!otherParent) return answer;
  switch (answerType) {
    case ParentAnswerTypeEnum.Fullname:
      answer = `${otherParent.firstName} ${otherParent.lastName}`;
      break;
    case ParentAnswerTypeEnum.BirthYear:
      answer = getYearFromDate(otherParent?.dateOfBirth).toString();
      break;
    case ParentAnswerTypeEnum.Profession:
      answer = otherParent?.professionName || emptyValue;
      break;
    case ParentAnswerTypeEnum.Qualifications:
      answer =
        professionalQualifications.find(
          (v) => v.id === otherParent?.professionalQualificationId
        )?.name || emptyValue;
      break;
    case ParentAnswerTypeEnum.Address:
      const residence = otherParent?.residence?.permanentAddress;
      if (residence) {
        answer = `${residence.street} ${residence.houseNumber}, ${residence.town}`;
      } else {
        answer = otherParent?.residence?.address ?? "";
      }
      break;
  }

  return answer;
};

const resolveParentQuestionAnswer = (
  answerType: ParentAnswerTypeEnum,
  request: Request,
  professionalQualifications: CodeBook[]
) => {
  const applicant = request.applicantParentDetails;
  let answer = emptyValue;

  switch (answerType) {
    case ParentAnswerTypeEnum.Fullname:
      answer = `${applicant.firstName} ${applicant.lastName}`;
      break;
    case ParentAnswerTypeEnum.BirthYear:
      answer = getYearFromDate(applicant?.dateOfBirth).toString();
      break;
    case ParentAnswerTypeEnum.BirthPlace:
      answer = applicant?.cityOfBirth || "";
      break;
    case ParentAnswerTypeEnum.Profession:
      answer = applicant?.professionName || emptyValue;
      break;
    case ParentAnswerTypeEnum.Qualifications:
      answer =
        professionalQualifications.find(
          (v) => v.id === applicant?.professionalQualificationId
        )?.name || emptyValue;
      break;
    case ParentAnswerTypeEnum.Address:
      const residence = applicant?.residence?.permanentAddress;
      if (residence) {
        answer = `${residence.street} ${residence.houseNumber}, ${residence.town}`;
      } else {
        answer = applicant?.residence?.address ?? "";
      }
      break;
    case ParentAnswerTypeEnum.Mobile:
      answer = applicant?.phoneNumber || "";
      break;
    case ParentAnswerTypeEnum.Email:
      answer = applicant?.email || "";
      break;
  }

  return answer;
};

const resolveChildQuestionAnswer = (
  answerType: ChildAnswerTypeEnum,
  request: Request,
  genders: CodeBook[]
) => {
  const child = request.childDetails;
  let answer = emptyValue;

  switch (answerType) {
    case ChildAnswerTypeEnum.Name:
      answer = child?.firstName + " " + child?.lastName;
      break;
    case ChildAnswerTypeEnum.Sex:
      answer = genders!?.find((x) => x.id === child?.genderId)?.name || "";
      break;
    case ChildAnswerTypeEnum.Birth:
      answer = hrDate(child?.dateOfBirth);
      break;
    case ChildAnswerTypeEnum.Oib:
      answer = child?.person?.oib || "";
      break;
    case ChildAnswerTypeEnum.Age:
      answer = getAgeFromBirthDate(child?.dateOfBirth).toString();
      break;
    case ChildAnswerTypeEnum.Address:
      const residence = child?.residence?.permanentAddress;
      if (residence) {
        answer = `${residence.street} ${residence.houseNumber}, ${residence.town}`;
      } else {
        answer = child?.residence?.address ?? "";
      }
      break;
  }

  return answer;
};
