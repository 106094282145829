import { useEffect, useState } from "react";

import {
  ArrowButton,
  Autocomplete,
  Button,
  ExpandablePanel,
  ExpandablePanelBody,
  ExpandablePanelHeader,
  IconWrapper,
  InfoPanel,
  Label,
  Modal,
  Select,
  Typography,
  Spinner,
  SelectData,
  AutocompleteMultipleSelect,
} from "@king-ict/eupisi-ui/components";
import { ReactComponent as DeleteRequestIcon } from "@king-ict/eupisi-ui/components/Icon/DeleteRequest.svg";
import { useBreakpoint } from "@king-ict/eupisi-ui/hooks";
import { hrDate } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { getAllChildrenForListAsync } from "../../api/Children/childApi";
import {
  createRequest,
  deleteDraftRequest,
} from "../../api/Request/RequestApi";
import { ReactComponent as InfoIconBoy } from "../../assets/images/boy.svg";
import { ReactComponent as InfoIconGirl } from "../../assets/images/girl.svg";
import { ReactComponent as InfoIconTrash } from "../../assets/images/trashBox.svg";
import { ContentArea } from "../../components/ContentArea";
import { InstitutionCodeBook } from "../../models/Institution/InstitutionCodeBook";
import Child from "../../models/Person/Child";
import ChildListDto from "../../models/Person/ChildListDto";
import RequestListItem from "../../models/Request/RequestListItem";
import RequestStatus from "../../models/Request/RequestStatusEnum";
import RequestType from "../../models/Request/RequestTypeEnum";
import { GenderEnum } from "../../models/Shared/GenderEnum";
import { ValidationFailure } from "../../models/Shared/SaveResult";
import {
  useGetCurrentSchoolYearQuery,
  useGetInstitutionCodeBookListQuery,
} from "../../queries/dataQuery";
import {
  childrenList as childrenListRoute,
  draftRequestUrl,
  requestUrl,
} from "../../routes/routes";
import {
  clearRequestData,
  updateRequest,
  updateRequestType,
} from "../../slices/requestSlice";
import { FormatUtils, MsgUtils } from "../../utils";
import styles from "./ChildrenList.module.scss";

export function ChildrenList() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [idToDelete, setIdToDelete] = useState(0);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [
    openTemporaryExemptionConfirmModal,
    setOpenTemporaryExemptionConfirmModal,
  ] = useState(false);
  const [disabledActionButtons, setDisabledActionButtons] = useState(false);
  const [openRequestDeletedModal, setOpenRequestDeletedModal] = useState(false);
  const [openEarlyEnrollmentModal, setOpenEarlyEnrollmentModal] = useState(
    false
  );
  const [selectedInstitutionId, setSelectedInstitutionId] = useState<
    number | undefined
  >();
  const [institutionFilter, setInstitutionFilter] = useState<
    string | undefined
  >();
  const [childrenList, setChildrenList] = useState<ChildListDto>();

  const [errors, setErrors] = useState<ValidationFailure[]>();
  const [selectedOib, setSelectedOib] = useState<string>();

  const { data: schoolYear } = useGetCurrentSchoolYearQuery();
  const { data: institutions } = useGetInstitutionCodeBookListQuery();

  const breakpointSize = useBreakpoint();
  const isMobile = () => {
    return breakpointSize === "xs" || breakpointSize === "sm";
  };
  useEffect(() => {
    dispatch(clearRequestData());
    const _setChildren = () => {
      getAllChildrenForListAsync().then((res) => {
        console.log(res);
        setChildrenList(res);
      });
    };
    _setChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetailsClicked = (request: RequestListItem) => {
    if (request.status === RequestStatus.Draft && request.id) {
      history.push(draftRequestUrl(request.id));
    } else {
      history.push(requestUrl(request.id));
    }
  };

  const handleCreateTemporaryExemptionDraftClicked = (childOib: string) => {
    setSelectedOib(childOib);
    setOpenTemporaryExemptionConfirmModal(true);
  };

  const handleCreateEarlyEnrollmentDraftClicked = (childOib: string) => {
    setSelectedOib(childOib);
    setOpenEarlyEnrollmentModal(true);
  };

  const handleCreateDraftClicked = (
    childOib: string,
    requestType: RequestType /*,
    institutionId?: number */
  ) => {
    if (childOib) {
      setSelectedOib(childOib);
      setErrors(undefined);
      if (childrenList && childrenList.children) {
        setDisabledActionButtons(true);
        dispatch(updateRequestType(requestType));
        createRequest(childOib, requestType /*, institutionId */).then(
          (result) => {
            if (result.isValid && result.result && result.result.id) {
              dispatch(updateRequest(result.result));
              history.push(draftRequestUrl(result.result.id));
            } else {
              setErrors([]);
              setErrors(result.validationFailures);
            }
            setDisabledActionButtons(false);
          }
        );
      }
    }
  };

  const handleToggleConfirmModal = () => {
    setOpenConfirmModal((prevOpenConfirmModal) => !prevOpenConfirmModal);
  };

  const handleToggleRequestDeletedModal = () => {
    setOpenRequestDeletedModal(
      (prevOpenRequestDeletedModal) => !prevOpenRequestDeletedModal
    );
  };

  const handleDeleteDraftRequest = async () => {
    handleToggleConfirmModal();

    await deleteDraftRequest(idToDelete).then(() => {
      handleToggleRequestDeletedModal();
    });

    setIdToDelete(0);
  };

  const getMessageInfoPanels = (child: Child) => {
    if (child.commentList && child.commentList.length > 0) {
      var i = 0;
      return (
        <>
          {child.commentList.map((message) => (
            <InfoPanel
              key={`msg_xx${i++}`}
              message={message}
              color="warning"
              fullWidth={!isMobile()}
            />
          ))}
        </>
      );
    }
    if (child.messageList && child.messageList.length > 0) {
      return (
        <>
          {child.messageList.map((messageId) => (
            <InfoPanel
              key={`msg_${messageId}`}
              message={t("childrenList.messages.message" + +messageId)}
              color="warning"
              fullWidth={!isMobile()}
            />
          ))}
        </>
      );
    }
  };

  const getMessageErrorPanels = (child: Child) => {
    return (
      <>
        {child.oib === selectedOib && (
          <>
            {errors && (
              <InfoPanel
                color="danger"
                fullWidth={!isMobile()}
                dismissible={true}
                message=""
              >
                <b>Greška kod snimanja podataka</b>
                <br></br>
                <ul>
                  {errors.map((error) => (
                    <li>{error.message}</li>
                  ))}
                </ul>
              </InfoPanel>
            )}
          </>
        )}
      </>
    );
  };

  const getActionButtons = (child: Child) => {
    return (
      <>
        {child.showRU && (
          <Button
            disabled={disabledActionButtons}
            size="sm"
            onClick={() =>
              handleCreateDraftClicked(child.oib, RequestType.RegularEnrollment)
            }
            className={styles.actionButton}
          >
            {t("childrenList.buttonRU")}
          </Button>
        )}
        {child.showRUTUR && (
          <Button
            disabled={disabledActionButtons}
            size="sm"
            onClick={() =>
              handleCreateDraftClicked(
                child.oib,
                RequestType.RegularEnrollmentForChildrenWithLearningDifficulties
              )
            }
            className={styles.actionButton}
          >
            {t("childrenList.buttonRUTUR")}
          </Button>
        )}
        {child.showPO && (
          <Button
            disabled={disabledActionButtons}
            size="sm"
            onClick={() =>
              handleCreateTemporaryExemptionDraftClicked(child.oib)
            }
            className={styles.actionButton}
          >
            {t("childrenList.buttonPO")}
          </Button>
        )}
        {child.showPU && (
          <Button
            disabled={disabledActionButtons}
            size="sm"
            onClick={() =>
              //handleCreateEarlyEnrollmentDraftClicked(child.oib)
              handleCreateDraftClicked(child.oib, RequestType.EarlyEnrollment)
            }
            className={styles.actionButton}
          >
            {t("childrenList.buttonPU")}
          </Button>
        )}
      </>
    );
  };

  const getChildrenHeader = (child: Child) => {
    return (
      <>
        <Col className="" xs={3} md={1}>
          {child.genderId === GenderEnum.Male && <InfoIconBoy />}
          {child.genderId === GenderEnum.Female && <InfoIconGirl />}
        </Col>
        <Col xs={9} md={2}>
          <Typography
            variant="baseBold"
            component="h5"
            className={styles.childDetails}
          >
            {child.firstName + " " + child.lastName}
          </Typography>
          <Typography component="h6" className={styles.childDetails}>
            {"OIB " + child.oib}
          </Typography>
          <Typography component="h6" className={styles.childDetails}>
            {hrDate(child.dateOfBirth)}
          </Typography>
        </Col>
      </>
    );
  };

  const getActiveRequest = (child: Child) => {
    return (
      <>
        {child.requests &&
          child.requests.map((request) => (
            <Row className="border p-2 mb-2">
              <Col md={2}>
                <Typography variant="baseBold" component="div">
                  <div>
                    {t("childrenList.request.InstitutionByEnrollmentAreaName")}
                  </div>
                  {request?.outsideEnrollmentAreaInstitutionId && (
                    <div>
                      {t(
                        "childrenList.request.InstitutionByApplicantDemandName"
                      )}
                    </div>
                  )}
                </Typography>
                <Typography variant="base">
                  {institutions && request?.schoolCandidateInstitutionId
                    ? institutions?.find(
                        (i) => i.id === request.schoolCandidateInstitutionId
                      )?.name
                    : "-"}
                  <br />
                  {institutions && request?.outsideEnrollmentAreaInstitutionId
                    ? institutions?.find(
                        (i) =>
                          i.id === request.outsideEnrollmentAreaInstitutionId
                      )?.name || "-"
                    : ""}
                </Typography>
              </Col>
              <Col md={2}>
                <Typography variant="baseBold" component="div">
                  {t("childrenList.request.Code")}
                </Typography>
                <Typography variant="base">
                  {request?.code ? request?.code : "-"}
                </Typography>
              </Col>
              <Col md={2}>
                <Typography variant="baseBold" component="div">
                  {t("childrenList.request.RequestTypeName")}
                </Typography>
                <Typography variant="base">
                  {MsgUtils.getRequestTypeName(request.requestTypeId)}
                </Typography>
              </Col>
              <Col md={2}>
                <Typography variant="baseBold" component="div">
                  {t("childrenList.request.EnrolledInstitutionName")}
                </Typography>
                <Typography variant="base">
                  {institutions && request?.enrolledInstitutionId
                    ? institutions?.find(
                        (i) => i.id === request.enrolledInstitutionId
                      )?.name
                    : "-"}
                </Typography>
              </Col>
              <Col md={2} className="text-center">
                <Typography variant="baseBold" component="div">
                  {t("childrenList.request.Status")}
                </Typography>
                <Label
                  className="text-center mb-3 mt-2"
                  color={FormatUtils.getRequestStatusColor(request.status)}
                  fullWidth={true}
                >
                  {MsgUtils.getRequestStatusName(request.status)}
                </Label>
              </Col>
              <Col md={2}>
                <Typography variant="baseBold" component="div">
                  {t("childrenList.details")}
                </Typography>
                <Row className="pr-2">
                  <Col md={8} xs={10}>
                    <ArrowButton
                      ariaLabel={t("childrenList.details")}
                      onClick={() => handleDetailsClicked(request)}
                    />
                  </Col>
                  <Col md={1} xs={1}>
                    {request.showDelete &&
                      request.status === RequestStatus.Draft && (
                        <>
                          <Button
                            outline={true}
                            boxless={false}
                            size="sm"
                            className={styles.deleteButton}
                            onClick={() => {
                              handleToggleConfirmModal();
                              setIdToDelete(request.id || 0);
                            }}
                            icon={<InfoIconTrash className="m-0 p-0" />}
                          />
                        </>
                      )}
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
      </>
    );
  };
  //TODO - After first year of production
  // const getArchiveRequest = (child: Child) => {
  //   return (
  //     <>
  //       {child.hasArchiveRequests && (
  //         <>
  //           <Col
  //             xs={5}
  //             className={` ${styles.columnPading} ${styles.columnWidth}`}
  //           ></Col>
  //           <Col
  //             xs={3}
  //             className={` ${styles.columnPading} ${styles.columnWidth}`}
  //           >
  //             <div className="horizontalAlign">
  //               <div className="floatLeft">
  //                 <InfoIconArchive />
  //               </div>
  //               <div className="floatRight">
  //                 <Typography variant="base" color="light">
  //                   {t("childrenList.buttonArchiveBox")}
  //                 </Typography>
  //               </div>
  //             </div>
  //           </Col>
  //         </>
  //       )}
  //       {!child.hasArchiveRequests && (
  //         <>
  //           <Col
  //             xs={5}
  //             className={` ${styles.columnPading} ${styles.columnWidth}`}
  //           ></Col>
  //           <Col
  //             xs={3}
  //             className={` ${styles.columnPading} ${styles.columnWidth}`}
  //           >
  //             <div className="horizontalAlign">
  //               <div className="floatLeft">
  //                 <InfoIconArchiveDisabled />
  //               </div>
  //               <div className="floatRight">
  //                 <Typography variant="base" color="light">
  //                   {t("childrenList.buttonArchiveBox")}
  //                 </Typography>
  //               </div>
  //             </div>
  //           </Col>
  //         </>
  //       )}
  //       {child.hasArchiveRequests && (
  //         <table>
  //           {child.archivedRequestListItemDtos?.map((archivedRequest) => (
  //             <>
  //               <thead>
  //                 <tr key={archivedRequest.id}>
  //                   <th key={archivedRequest.id}>
  //                     {t("childrenList.archiveRequest.requestTypeName")}
  //                   </th>
  //                   <th>{t("childrenList.archiveRequest.schoolName")}</th>
  //                   <th>{t("childrenList.archiveRequest.statusName")}</th>
  //                   <th>{t("childrenList.archiveRequest.schoolYearName")}</th>
  //                   <th>{t("childrenList.details")}</th>
  //                 </tr>
  //               </thead>

  //               <tbody>
  //                 <tr>
  //                   <td>{archivedRequest.requestType}</td>
  //                   <td>{archivedRequest.school}</td>
  //                   <td>{archivedRequest.status}</td>
  //                   <td>{archivedRequest.schoolYear}</td>
  //                   <td>
  //                     <ArrowButton
  //                       ariaLabel={t("childrenList.details")}
  //                       onClick={dummy}
  //                     />
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </>
  //           ))}
  //         </table>
  //       )}
  //     </>
  //   );
  // };

  const getChildrenList = () => {
    if (
      childrenList &&
      childrenList.children &&
      childrenList.children.length > 0
    ) {
      return (
        <>
          <div>
            {childrenList.children.map((child) => (
              <div key={child.oib} className={styles.cardItem}>
                <Row className="d-flex flex-row align-items-center">
                  {getChildrenHeader(child)}
                  <Col md={9}>
                    {getMessageInfoPanels(child)}
                    {getMessageErrorPanels(child)}
                  </Col>
                </Row>
                {(!child.requests[0] ||
                  child.requests[0].status === RequestStatus.Cancelled ||
                  child.requests[0].status ===
                    RequestStatus.ProcedureRejected) && (
                  <Row>
                    <Col
                      xs={12}
                      className={` ${styles.columnPading} ${styles.columnWidth}`}
                    >
                      <Row>
                        <Col md={12} className="text-right">
                          {getActionButtons(child)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row className="border-top mt-1 pt-2">
                  <Col md={12}>{getActiveRequest(child)}</Col>
                </Row>
                <Row>{/* {getArchiveRequest(child)} */}</Row>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  const getChildrenMobileList = () => {
    if (
      childrenList &&
      childrenList.children &&
      childrenList.children.length > 0
    ) {
      return (
        <>
          {childrenList.children.map((child) => (
            <>
              <ExpandablePanel className={styles.cardItem}>
                <ExpandablePanelHeader>
                  <Row className="d-flex flex-row align-items-center">
                    {getChildrenHeader(child)}
                  </Row>
                </ExpandablePanelHeader>
                <ExpandablePanelBody>
                  <hr></hr>
                  {getMessageInfoPanels(child)}
                  {getMessageErrorPanels(child)}
                  {(!child.requests[0] ||
                    child.requests[0].status === RequestStatus.Cancelled) && (
                    <Row>
                      <Col sm={12}>{getActionButtons(child)}</Col>
                    </Row>
                  )}
                  <Row>{getActiveRequest(child)}</Row>
                </ExpandablePanelBody>
              </ExpandablePanel>
            </>
          ))}
        </>
      );
    }
  };

  if (!schoolYear) return <Spinner show />;
  return (
    <ContentArea>
      <>
        {!childrenList?.errorGettingChildren && (
          <Row className="mt-4">
            <Col md={12}>
              <Typography variant="baseBold" component="h3">
                {`${t("childrenList.title")} ${schoolYear?.name}`}
              </Typography>
            </Col>
          </Row>
        )}
        {isMobile() ? <>{getChildrenMobileList()}</> : <>{getChildrenList()}</>}

        {childrenList?.errorGettingChildren && (
          <InfoPanel
            message={t("childrenList.messages.message15")}
            color="danger"
            fullWidth
          />
        )}
        <br />
        {!childrenList?.errorGettingChildren && (
          <Typography variant="baseBold" component="h3">
            {t("childrenList.messages.message1")}
          </Typography>
        )}
      </>

      <Modal
        type={"confirm"}
        title={t("requestDetails.deleteRequestConfirmModalTitle")}
        open={openConfirmModal}
        confirmText={t("requestDetails.deleteRequestConfirmModalYes")}
        cancelText={t("common.no")}
        onClose={handleToggleConfirmModal}
        onConfirm={handleDeleteDraftRequest}
      >
        <Typography component="div" variant="medium">
          {t("requestDetails.deleteRequestConfirmModalMessage")}
        </Typography>
      </Modal>

      <Modal
        type={"confirm"}
        title={t("childrenList.buttonPU")}
        open={openEarlyEnrollmentModal}
        confirmText={t("common.yes")}
        cancelText={t("common.no")}
        onClose={() => {
          setSelectedOib(undefined);
          setOpenEarlyEnrollmentModal(false);
        }}
        onConfirm={() => {
          if (selectedInstitutionId) {
            setOpenEarlyEnrollmentModal(false);
            handleCreateDraftClicked(
              selectedOib!,
              RequestType.EarlyEnrollment /*,
              selectedInstitutionId */
            );
          }
        }}
      >
        <AutocompleteMultipleSelect
          label={t("institutionDataStep.schoolName")}
          name="institutionSelect"
          required={true}
          valueProp="id"
          textProp="name"
          maximumSelectedItems={1}
          selectOptions={
            institutions?.filter(
              (v) =>
                v.isByEnrollmentArea &&
                (!institutionFilter ||
                  v.name
                    .toLowerCase()
                    .includes(institutionFilter.toLowerCase()))
            ) ?? []
          }
          value={
            selectedInstitutionId && institutions
              ? institutions!.filter((v) => v.id === selectedInstitutionId)
              : []
          }
          onFilterChange={async (filter) => setInstitutionFilter(filter)}
          onChange={(e: any) => {
            const values = e.target.value as InstitutionCodeBook[];
            if (values && values.length > 0)
              setSelectedInstitutionId(values[0].id);
            else setSelectedInstitutionId(undefined);
          }}
        />
      </Modal>

      <Modal
        type={"confirm"}
        title={t(
          "childrenList.modalMessages.confirmTemporaryExemptionDraftTitle"
        )}
        open={openTemporaryExemptionConfirmModal}
        confirmText={t("common.yes")}
        cancelText={t("common.no")}
        onClose={() => {
          setSelectedOib(undefined);
          setOpenTemporaryExemptionConfirmModal(false);
        }}
        onConfirm={() => {
          setOpenTemporaryExemptionConfirmModal(false);
          handleCreateDraftClicked(
            selectedOib!,
            RequestType.TemporaryExemption
          );
        }}
      >
        <Typography component="h4" variant="mediumBold">
          {t(
            "childrenList.modalMessages.confirmTemporaryExemptionDraftMessage1"
          )}
        </Typography>
        <Typography component="p" variant="medium">
          {t(
            "childrenList.modalMessages.confirmTemporaryExemptionDraftMessage2"
          )}
        </Typography>
        <Typography component="p" variant="medium">
          {t(
            "childrenList.modalMessages.confirmTemporaryExemptionDraftMessage2a"
          )}
        </Typography>
        <Typography component="h4" variant="mediumBold">
          {t(
            "childrenList.modalMessages.confirmTemporaryExemptionDraftMessage3"
          )}
        </Typography>
        <Typography component="p" variant="medium">
          {t(
            "childrenList.modalMessages.confirmTemporaryExemptionDraftMessage4"
          )}
        </Typography>
        <Typography component="div" variant="mediumBold">
          {t(
            "childrenList.modalMessages.confirmTemporaryExemptionDraftMessage5"
          )}
        </Typography>
      </Modal>

      <Modal
        type={"message"}
        open={openRequestDeletedModal}
        confirmText={t("common.close")}
        onConfirm={() => (window.location.href = childrenListRoute())}
        onClose={() => (window.location.href = childrenListRoute())}
      >
        <div className="text-center">
          <IconWrapper color="danger">
            <DeleteRequestIcon />
          </IconWrapper>
          <Typography variant="largeBold" className="mt-2">
            {t("requestDetails.deleteRequestSuccessfullMessage")}
          </Typography>
        </div>
      </Modal>
    </ContentArea>
  );
}
