import {
  Button,
  ExpandablePanel,
  ExpandablePanelBody,
  ExpandablePanelHeader,
  Typography,
} from "@king-ict/eupisi-ui/components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export interface RequestInfoPanelProps {
  children?: any;
  title?: string;
  subTitle?: string;
  showEdit: boolean;
  onEditClick?: () => void;
  onExpandablePanelClick?: () => void;
}
export const RequestInfoPanel = ({
  children,
  title = "",
  subTitle = "",
  showEdit = false,
  onEditClick,
  onExpandablePanelClick,
}: RequestInfoPanelProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col md={12}>
          <ExpandablePanel clickableHeader>
            <ExpandablePanelHeader onClick={onExpandablePanelClick}>
              {title}
            </ExpandablePanelHeader>
            <ExpandablePanelBody>
              <Row>
                <Col md={10}>
                  <Typography variant="smallHeading">{subTitle}</Typography>
                </Col>
                {showEdit && (
                  <Col md={2}>
                    <Button size="sm" outline onClick={onEditClick}>
                      {t("common.edit")}
                    </Button>
                  </Col>
                )}
              </Row>
              {children}
            </ExpandablePanelBody>
          </ExpandablePanel>
        </Col>
      </Row>
    </>
  );
};
