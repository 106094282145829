import { InfoPanel, Typography } from "@king-ict/eupisi-ui/components";
import { Row, Col } from "react-bootstrap";

export interface RequestFormTopMessageProps {
  infoMessage: string;
  title?: string;
  color?:
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "primary"
    | "secondary";
}
export const RequestFormTopMessage = ({
  infoMessage,
  title,
  color = "warning",
}: RequestFormTopMessageProps) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <InfoPanel color={color} fullWidth={true} message="">
            {title && (
              <>
                <b>{title}</b>
                <br />
              </>
            )}
            {infoMessage}
          </InfoPanel>
        </Col>
      </Row>
    </>
  );
};
