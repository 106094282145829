import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";

import { getInitialConversationQuestionnaire as getInitialConversationQuestionnaireApi } from "../api/Request/RequestApi";
import { RootState } from "../app/rootReducer";
import Questionnaire from "../models/Questionnaire/Questionnaire";

interface QuestionnaireState {
  initialConversation: Questionnaire;
}

let initialState: QuestionnaireState = {
  initialConversation: {} as Questionnaire,
};

export const getInitialConversation = createAsyncThunk(
  "questionnaire/getInitialConversation",
  async () => {
    const response = await getInitialConversationQuestionnaireApi();
    return response;
  }
);

export const selectInitialConversationQuestionGroupNames = createSelector(
  (state: RootState) => state.questionnaire.initialConversation,
  (initialConversation: Questionnaire) =>
    initialConversation.questionGroups == null
      ? []
      : initialConversation.questionGroups.map((qg) => qg.name)
);

export const selectInitialConversationQuestionGroupBySort = createSelector(
  (state: RootState) => state.questionnaire.initialConversation,
  (_: any, questionGroupSort: number) => questionGroupSort,
  (initialConversation: Questionnaire, questionGroupSort: number) =>
    initialConversation.questionGroups?.find(
      (qg) => qg.sort === questionGroupSort
    )
);

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInitialConversation.fulfilled, (state, { payload }) => {
      state.initialConversation = payload;
    });
  },
});

export default questionnaireSlice.reducer;
