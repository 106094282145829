import { TextField, Typography } from "@king-ict/eupisi-ui/components";
import { CodeBook } from "@king-ict/eupisi-ui/models";
import { hrDate } from "@king-ict/eupisi-ui/utils";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { PersonDetails } from "../../../models/Request/PersonDetails";
import { RequestInfoPanel } from "../../Request/RequestComponents/RequestInfoPanel";

interface Props {
  emptyValue: string;
  child: PersonDetails;
  countries: CodeBook[];
  citizenships: CodeBook[];
  nationalMinorities: CodeBook[];
  nationalities: CodeBook[];
  genders: CodeBook[];
  showEdit: boolean;
  moveToSpecificStep?: () => void;
}

export default function ChildDetails({
  emptyValue,
  child,
  countries,
  citizenships,
  nationalMinorities,
  nationalities,
  genders,
  showEdit,
  moveToSpecificStep,
}: Props) {
  const { t } = useTranslation();

  return (
    <RequestInfoPanel
      title={t("requestReviewStep.childInformation")}
      subTitle={t("person.childPersonalInformation")}
      showEdit={showEdit}
      onEditClick={moveToSpecificStep}
    >
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.firstName")}
            helperText={t("common.birthRegistry")}
            value={child?.firstName || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.lastName")}
            helperText={t("common.birthRegistry")}
            value={child?.lastName || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.oib")}
            helperText={t("common.oibApi")}
            value={child?.person?.oib || emptyValue}
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.dateOfBirth")}
            helperText={t("common.birthRegistry")}
            value={
              child && child.dateOfBirth
                ? hrDate(child?.dateOfBirth)
                : emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.sex")}
            helperText={t("common.birthRegistry")}
            value={
              genders?.find((x) => x.id === child.genderId)?.name || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.birthplace")}
            helperText={t("common.birthRegistry")}
            value={child?.cityOfBirth || emptyValue}
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.countryBirth")}
            helperText={t("common.birthRegistry")}
            value={
              countries?.find((x) => x.id === child.countryOfBirthId)?.name ||
              emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.citizenship")}
            helperText={t("common.birthRegistry")}
            value={
              citizenships?.find((x) => x.id === child.citizenshipId)?.name ||
              emptyValue
            }
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.nationalMinority")}
            value={
              nationalMinorities?.find((x) => x.id === child.nationalMinorityId)
                ?.name || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={t("person.nationality")}
            value={
              nationalities?.find((x) => x.id === child.nationalityId)?.name ||
              emptyValue
            }
            plaintext
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Typography variant="smallHeading">
            {t("person.permanentResidenceInformation1")}
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t("person.country")}`}
            value={
              countries?.find((x) => x.id === child.residence?.countryId)
                ?.name || emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t(
              "person.municipality"
            )}`}
            value={
              child?.residence?.permanentAddress?.municipality ||
              child?.residence?.address ||
              emptyValue
            }
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t("person.town")}`}
            value={child?.residence?.permanentAddress?.town || emptyValue}
            plaintext
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t(
              "person.postalCode"
            )}`}
            value={child?.residence?.permanentAddress?.postalCode || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t("person.street")}`}
            value={child?.residence?.permanentAddress?.street || emptyValue}
            plaintext
          />
        </Col>
        <Col md={4}>
          <TextField
            type="text"
            label={`${t("person.permanentResidence")} - ${t(
              "person.streetNumber"
            )}`}
            value={
              child?.residence?.permanentAddress?.houseNumber || emptyValue
            }
            plaintext
          />
        </Col>
      </Row>

      {child?.residence && child?.residence?.temporaryAddress && (
        <>
          <Row>
            <Col md={12}>
              <Typography variant="smallHeading">
                {t("person.temporaryResidenceInformation1")}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col md={4}></Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.municipality"
                )}`}
                value={
                  child?.residence?.temporaryAddress?.municipality || emptyValue
                }
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.town"
                )}`}
                value={child?.residence?.temporaryAddress?.town || emptyValue}
                plaintext
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.postalCode"
                )}`}
                value={
                  child?.residence?.temporaryAddress?.postalCode || emptyValue
                }
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.street"
                )}`}
                value={child?.residence?.temporaryAddress?.street || emptyValue}
                plaintext
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.streetNumber"
                )}`}
                value={
                  child?.residence?.temporaryAddress?.houseNumber || emptyValue
                }
                plaintext
              />
            </Col>
          </Row>
        </>
      )}
    </RequestInfoPanel>
  );
}
