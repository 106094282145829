import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Modal,
  StepperDispatchContext,
  StepperStateContext,
  SuccessModalContent,
  Typography,
} from "@king-ict/eupisi-ui/components";
import { useBreakpoint } from "@king-ict/eupisi-ui/hooks";
import qs from "qs";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { saveRequest } from "../../api/Request/RequestApi";
import { useTypedSelector } from "../../app/rootReducer";
import { useAppDispatch } from "../../app/store";
import { useBusinessRule } from "../../hooks/businessRule/useBusinessRule";
import RequestStatusEnum from "../../models/Request/RequestStatusEnum";
import RequestStepType from "../../models/Request/RequestStepType";
import { dashboard as dashboardRoute, childrenList } from "../../routes/routes";
import { resetIsEdited } from "../../slices/applicationSlice";
import {
  clearRequestData,
  updateRequestErrors,
} from "../../slices/requestSlice";

interface Props {
  currentStep: number;
  currentSubstep?: number;
}

export const RequestStepperHeaderButtons = ({
  currentStep,
  currentSubstep,
}: Props) => {
  const request = useTypedSelector((state) => state.request.request);
  const stepperStateContext = useContext(StepperStateContext);
  const stepperDispatch = useContext(StepperDispatchContext);

  const { isRequestDraft } = useBusinessRule();
  const { t } = useTranslation();

  const location = useLocation();
  const params = qs.parse(location.search.substring(1));
  const { guid } = params;

  const dispatch = useAppDispatch();

  const history = useHistory();

  const [
    openRequestDraftSuccessModal,
    setOpenRequestDraftSuccessModal,
  ] = useState(false);

  const [openDraftModal, setOpenDraftModal] = useState(false);

  const [save, setSave] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);

  const breakpointSize = useBreakpoint();
  const isMobile = () => {
    return breakpointSize === "xs" || breakpointSize === "sm";
  };

  useEffect(() => {
    if (save) {
      setSave(false);

      if (request.id) {
        saveRequest(request, RequestStepType.SaveOnly).then((result) => {
          if (result.isValid) {
            history.push(childrenList());
          } else {
            if (result.validationFailures) {
              dispatch(updateRequestErrors([]));
              dispatch(updateRequestErrors(result.validationFailures));
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, save, guid, request]);

  const sizeBreakpoint = useBreakpoint();

  const handleToggleDraftModal = () => {
    setOpenDraftModal((openDraftModal) => !openDraftModal);
  };

  const handleToggleRequestDraftSuccessModal = () => {
    setOpenRequestDraftSuccessModal(
      (openRequestDraftSuccessModal) => !openRequestDraftSuccessModal
    );
  };

  const handleRequeststDraftSuccessConfirmed = () => {
    handleToggleRequestDraftSuccessModal();
    if (shouldClose) {
      dispatch(clearRequestData());
      history.push(dashboardRoute());
    }
  };
  const handleClose = () => {
    stepperStateContext &&
      stepperStateContext.close &&
      stepperStateContext.close();
    stepperDispatch && stepperDispatch({ type: "close", payload: undefined });

    history.push(childrenList());
  };

  const handleSave = async () => {
    stepperStateContext &&
      stepperStateContext.save &&
      stepperStateContext.save();

    handleToggleDraftModal();
    stepperDispatch && stepperDispatch({ type: "save", payload: undefined });
    dispatch(resetIsEdited());
    setSave(true);
  };

  const handleSaveAndCloseClicked = () => {
    setShouldClose(true);
    handleToggleDraftModal();
  };

  const renderButton = (button: any) => {
    if (sizeBreakpoint === "xs" || sizeBreakpoint === "sm") {
      return (
        <Row>
          <Col xs={12}>{button}</Col>
        </Row>
      );
    }
    return button;
  };

  return (
    <>
      <div
        className={
          isMobile() ? "d-flex flex-row align-items-center" : "text-right"
        }
      >
        {/* EDIT STEP */}
        {currentStep < 5 &&
          isRequestDraft() &&
          renderButton(
            <Button outline onClick={handleClose} size="sm">
              {t("request.closeRequestButton")}
            </Button>
          )}
        {currentStep < 5 &&
          isRequestDraft() &&
          renderButton(
            <Button
              onClick={handleSaveAndCloseClicked}
              size="sm"
              className="ml-2"
            >
              {t("request.saveAndCloseRequestButton")}
            </Button>
          )}
        {/* FINAL STEP */}
        {((currentStep === 5 && isRequestDraft()) ||
          (request.statusId > 0 &&
            request.statusId !== RequestStatusEnum.Draft)) &&
          renderButton(
            <Button outline onClick={handleClose} size="sm">
              {t("request.closeRequestButton")}
            </Button>
          )}
      </div>

      <Modal
        type={"confirm"}
        title={t("request.draftConfirmationTitle")}
        open={openDraftModal}
        confirmText={t("request.draftConfirmationYes")}
        cancelText={t("common.no")}
        onClose={handleToggleDraftModal}
        onConfirm={handleSave}
      >
        <Typography component="div" variant="medium">
          {t("request.draftConfirmationMessage")}
          {
            <div>
              <strong>{t("request.draftConfirmationMessageWarning")}: </strong>
              {t("request.draftConfirmationMessageWarningText")}
            </div>
          }
        </Typography>
      </Modal>

      <Modal
        type={"message"}
        open={openRequestDraftSuccessModal}
        confirmText={t("common.close")}
        onConfirm={handleRequeststDraftSuccessConfirmed}
        onClose={() => handleToggleRequestDraftSuccessModal()}
      >
        <SuccessModalContent
          message={t("requestDetails.draftRequestSuccessfullMessage")}
        />
      </Modal>
    </>
  );
};
