import { useContext, useState } from "react";

import {
  Button,
  Modal,
  StepperStateContext,
  Typography,
} from "@king-ict/eupisi-ui/components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { saveRequest } from "../../api/Request/RequestApi";
import { useTypedSelector } from "../../app/rootReducer";
import { useAppDispatch } from "../../app/store";
import { useBusinessRule } from "../../hooks/businessRule/useBusinessRule";
import Request from "../../models/Request/Request";
import RequestStatusEnum from "../../models/Request/RequestStatusEnum";
import { RequestStep } from "../../models/Request/RequestStep";
import RequestStepType from "../../models/Request/RequestStepType";
import { SaveResult } from "../../models/Shared/SaveResult";
import { childrenList } from "../../routes/routes";
import { resetIsEdited } from "../../slices/applicationSlice";
import { updateRequest, updateRequestErrors } from "../../slices/requestSlice";

type Props = {
  stepperState: any;
  stepperApi: any;
};

export default function RequestDraftStepperActions({
  stepperState,
  stepperApi,
}: Props) {
  const stepperStateContext = useContext(StepperStateContext);

  const { isRequestDraft } = useBusinessRule();
  const { t } = useTranslation();
  const history = useHistory();

  const { requestId } = useParams<{ requestId: string }>();
  const request = useTypedSelector((state) => state.request.request);
  const dispatch = useAppDispatch();
  const { isApplicantParent } = useBusinessRule();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleToggleConfirmModal = () => {
    setOpenConfirmModal((openConfirmModal) => !openConfirmModal);
  };

  /*STEPPER*/
  const handleNext = () => {
    const action =
      stepperStateContext &&
      stepperStateContext.toNext &&
      stepperStateContext.toNext();

    if (action !== false) {
      if (request.id) {
        console.log("handleNEXT", request);
        saveRequest(request, RequestStepType.NextStep).then((result) => {
          updateRequestStateAfterSave(result);
        });
      }
      window.scrollTo(0, 0);
    }
  };
  const handlePrevious = () => {
    stepperStateContext &&
      stepperStateContext.toPrevious &&
      stepperStateContext.toPrevious();
    if (request.id) {
      saveRequest(request, RequestStepType.PreviousStep).then((result) => {
        updateRequestStateAfterSave(result);
      });
    }
    window.scrollTo(0, 0);
  };
  /*ACTION*/
  const handleCreateRequestFromDraft = () => {
    saveRequest(request, RequestStepType.NextStep).then((result) => {
      if (result.isValid) {
        dispatch(resetIsEdited());
        history.push(childrenList());
      }
    });
  };
  const updateRequestStateAfterSave = (result: SaveResult<Request>) => {
    if (result && result.isValid) {
      if (result?.result) {
        dispatch(updateRequest(result.result));
      }

      if (result.result?.subStep) {
        stepperApi.moveToSpecific(result.result?.step, result.result?.subStep);
      } else {
        stepperApi.moveToSpecific(result.result?.step);
      }
    } else {
      if (result.validationFailures) {
        dispatch(updateRequestErrors([]));
        dispatch(updateRequestErrors(result.validationFailures));
      }
    }
  };
  // only aaplicant has right for stepper actions
  if (!isApplicantParent()) return null;
  return (
    <>
      <Row className="border-top border-2 bg-light p-3">
        <Col md={6} xs={6}>
          {(stepperState.currentStep > RequestStep.ParentData &&
            stepperState.currentStep < RequestStep.Approval) ||
          (stepperState.currentStep === RequestStep.ParentData &&
            stepperState.currentSubstep > 0) ? (
            <Button onClick={handlePrevious} size="sm" color="primary" outline>
              {t("request.prevStepButton")}
            </Button>
          ) : (
            <></>
          )}
        </Col>
        <Col className="text-right" md={6} xs={6}>
          {stepperState.currentStep < RequestStep.Approval && isRequestDraft() && (
            <Button onClick={handleNext} size="sm">
              {t("request.nextStepButton")}
            </Button>
          )}
          {stepperState.currentStep === RequestStep.Approval &&
            isRequestDraft() && (
              <Button onClick={() => setOpenConfirmModal(true)} size="sm">
                {t("request.sendRequestButton")}
              </Button>
            )}
        </Col>
      </Row>
      <Modal
        type={"confirm"}
        title={t("request.sendRequestConfirmModalTitle")}
        open={openConfirmModal}
        confirmText={
          requestId
            ? t("request.editRequestButton")
            : t("request.sendRequestButton")
        }
        cancelText={t("common.cancel")}
        onClose={handleToggleConfirmModal}
        onConfirm={handleCreateRequestFromDraft}
      >
        <Typography component="div" variant="medium">
          {request.statusId === RequestStatusEnum.Draft
            ? t("request.sendRequestConfirmModalMessage", {
                institutionName: "",
                //request.selectedInstitution.name,
              })
            : t("request.sendRequestConfirmModalMessage")}
        </Typography>
      </Modal>
    </>
  );
}
