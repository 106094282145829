import styles from "./StatementOfAccessibilityPage.module.scss";

export const StatementOfAccessibilityPage = () => {
  return (
    <div className={styles.about}>
      <div className={styles.title}>Izjava o pristupačnosti</div>
      <p className={styles.paragraph}>
        Kao tijelo javnog sektora, CARNET je obvezan osigurati pristupačnost
        svojih mrežnih lokacija u skladu sa Zakonom o pristupačnosti mrežnih
        stranica i programskih rješenja za pokretne uređaje tijela javnog
        sektora Republike Hrvatske (NN 17/19) od 23. rujna 2019., kojim se u
        nacionalno zakonodavstvo preuzima Direktiva (EU) 2016/2102 Europskog
        parlamenta i Vijeća o pristupačnosti internetskih stranica i mobilnih
        aplikacija tijela javnog sektora.
      </p>
      <p>
        Ova Izjava o pristupačnosti odnosi se na e-Osnovne škole, podsustav za
        prijave i upise u osnovne škole.
      </p>
      <div className={styles.subtitle}>Status usklađenosti</div>
      <p className={styles.paragraph}>
        Aplikacija e-Osnovne škole je djelomično usklađena sa Zakonom o
        pristupačnosti mrežnih stranica i programskih rješenja za pokretne
        uređaje tijela javnog sektora. Ispunjen je dio zahtjeva europske norme
        EN 301 549 V1.1.2 (2015-04), uz nekoliko iznimki.
      </p>
      <div className={styles.subtitle}>Nepristupačni sadržaj</div>
      <p className={styles.paragraph}>
        Iznimke od usklađenosti sa Zakonom o pristupačnosti mrežnih stranica i
        programskih rješenja za pokretne uređaje tijela javnog sektora Republike
        Hrvatske očituju se u sljedećem:
      </p>
      <ul>
        <li>Gumb za popunjavanje zahtjeva nema jasnu deskripciju</li>
        <li>
          Kursor bi trebao biti pozicioniran na heading element dijela zahtjeva
          koji se popunjava
        </li>
        <li>
          U kategoriji „Dokumentacija o vašem djetetu“, gumbi za dodavanje
          dokumenata nisu funkcionalni za čitač zaslona
        </li>
      </ul>
      <div className={styles.subtitle}>Podizanje razine pristupačnosti</div>
      <p className={styles.paragraph}>
        CARNET je proveo testiranje digitalne pristupačnosti aplikacije
        e-Osnovne škole i dobavljaču aplikacije, KING ICT d.o.o., dostavio Nalaz
        o stanju digitalne pristupačnosti s preporukama za unaprjeđenje stanja.
        Dobavljač aplikacije jamči da je otklonio nedostatke pristupačnosti
        pronađene tijekom testiranja, izuzev prethodno navedenih nedostataka.
      </p>
      <div className={styles.subtitle}>
        Priprema ove Izjave o pristupačnosti
      </div>
      <p className={styles.paragraph}>
        Ova Izjava pripremljena je dana 15. studenog 2022., sukladno Predlošku
        izjave o pristupačnosti koji je u skladu s Direktivom (EU) 2016/2102
        Europskog parlamenta i Vijeća o pristupačnosti internetskih stranica i
        mobilnih aplikacija tijela javnog sektora, a utvrđen je Provedbenom
        odlukom komisije EU 2018/1523 11. listopada 2018.
      </p>
      <p className={styles.paragraph}>
        Za pripremu ove Izjave korištena je metoda procjene u obliku testiranja
        pristupačnosti.
      </p>
      <p className={styles.paragraph}>
        Testiranje pristupačnosti proveli su stručnjaci za digitalnu
        pristupačnost i osobe s različitim vrstama invaliditeta koje su napredni
        korisnici informacijske tehnologije. Testiranja su izvršena na
        platformama MS Windows 10, MS Windows 11, Android 10, iOS 15, iOS 16.
        Internetski preglednici koji su korišteni tijekom testiranja su aktualne
        verzije Microsoft Edge, Mozilla FireFox, Google Chrome, Safari.
        Asistivna tehnologija korištena tijekom testiranja: čitači ekrana JAWS i
        NVDA, VoiceOver, Talkback, brajev redak, prilagođena tipkovnica.
      </p>
      <div className={styles.subtitle}>
        Povratne informacije i podaci za kontakt
      </div>
      <p className={styles.paragraph}>
        Sve upite vezane uz pristupačnost navedenog digitalnog obrazovnog
        sadržaja korisnici mogu uputiti CARNET-ovoj Službi za korisnike
        (Helpdesk).
      </p>
      <p className={styles.paragraph}>
        CARNET-ov Helpdesk možete kontaktirati:
      </p>
      <ul>
        <li>e-poštom: helpdesk@carnet.hr</li>
        <li>telefonom: +385 1 6661 555</li>
        <li>telefaksom: +385 1 6661 630</li>
        <li>putem CARNET-ove službene Facebook stranice ili</li>
        <li>
          poštom: CARNET-ova Služba za podršku korisnicima, Josipa Marohnića 5,
          10000 Zagreb
        </li>
      </ul>
      <p className={styles.paragraph}>
        Radno vrijeme CARNET-ovog Helpdeska je od ponedjeljka do nedjelje od 8
        do 22 sata.
      </p>
      <p className={styles.paragraph}>
        CARNET je dužan na upit, obavijest ili zahtjev korisnika vezano uz
        osiguravanje pristupačnosti odgovoriti u roku od 15 dana od dana
        primitka obavijesti, odnosno zahtjeva ili ga u istom roku, uz detaljno
        obrazloženje razloga koji zahtijevaju odgodu, obavijestiti o naknadnom
        roku u kojem će odgovoriti na korisnikovu obavijest ili zahtjev. U
        skladu s uobičajenim radom svog Helpdeska, CARNET će nastojati odgovore
        na upite obraditi unutar dva radna dana.
      </p>
      <div className={styles.subtitle}>Inspekcijski nadzor</div>
      <p className={styles.paragraph}>
        Tijelo nadležno za praćenje usklađenosti mrežnih stranica i programskih
        rješenja za pokretne uređaje tijela javnog sektora sa zahtjevima
        pristupačnosti i nadzor nad provedbom Zakona o pristupačnosti je
        Povjerenik za informiranje Republike Hrvatske.
      </p>
      <p className={styles.paragraph}>
        U slučaju nezadovoljavajućih odgovora na obavijest ili zahtjev za
        povratne informacije o pristupačnosti ovim mrežnih stranica, korisnici
        se mogu obratiti Povjereniku za informiranje putem telefona broj +385 1
        4609 041 ili putem elektroničke pošte: pristupacnost@pristupinfo.hr.
      </p>
    </div>
  );
};
