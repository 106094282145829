import {
  SidebarNavigationContainer,
  SidebarNavigationItem,
} from "@king-ict/eupisi-ui/components";
import { useTranslation } from "react-i18next";

import {
  dashboard,
  importantDates,
  childrenList,
  faqGroupsAndItemsRoute,
  aboutProgramRoute,
  helpAndSupportRoute,
  newErrorReporRoute,
  statementOfAccessibilityRoute,
} from "../../routes/routes";
import AboutProgram from "../AboutProgram/AboutProgram";
import { ChildrenList } from "../Children/ChildrenList";
import DashboardPage from "../Dashboard/DashboardPage";
import { ErrorReportCreate } from "../ErrorReport";
import { Faq } from "../Faq";
import HelpAndSupport from "../HelpAndSupport/HelpAndSupport";
import { ImportantDatePage } from "../ImportantDates";
import { StatementOfAccessibility } from "../StatementOfAccessibility";

export interface MatchParams {
  requestId: string;
  id: string;
}
export interface SidebarProps {
  sidebarIsOpen?: boolean;
}

export function Sidebar({ sidebarIsOpen }: SidebarProps) {
  const { t } = useTranslation();

  return (
    <>
      <SidebarNavigationContainer
        color="primary"
        size="2"
        sidebarIsOpen={sidebarIsOpen}
        sidebarShowHamburger={false}
        contentPadding="0 10px"
      >
        {/* {getSidebarItems()} */}
        <SidebarNavigationItem
          name={t("sidebar.dashboard")}
          route={dashboard()}
          url={"/dashboard"}
        >
          <DashboardPage />
        </SidebarNavigationItem>
        <SidebarNavigationItem
          name={t("sidebar.importantDatePage")}
          route={importantDates()}
          url={"/important-dates"}
        >
          <ImportantDatePage />
        </SidebarNavigationItem>
        <SidebarNavigationItem
          name={t("sidebar.childrenList")}
          route={childrenList()}
          url={"/children"}
        >
          <ChildrenList />
        </SidebarNavigationItem>

        {/* HIDDEN ITEMS */}
        <SidebarNavigationItem
          name={t("navigation.faq")}
          route={faqGroupsAndItemsRoute}
          url={"/faq"}
          hideFromMenu={true}
        >
          <Faq />
        </SidebarNavigationItem>

        <SidebarNavigationItem
          name={t("navigation.aboutProgram")}
          route={aboutProgramRoute}
          url={"/aboutprogram"}
          hideFromMenu={true}
        >
          <AboutProgram />
        </SidebarNavigationItem>

        <SidebarNavigationItem
          name={t("navigation.helpAndSupport")}
          route={helpAndSupportRoute}
          url={"/help-and-support"}
          hideFromMenu={true}
        >
          <HelpAndSupport />
        </SidebarNavigationItem>

        <SidebarNavigationItem
          key="103"
          name={"error reporting"}
          route={newErrorReporRoute}
          url={"/errorReport/new"}
          hideFromMenu={true}
          // This opens in a new window. There doesn't appear to be
          // any way to change it :(
        >
          <ErrorReportCreate />
        </SidebarNavigationItem>
        <SidebarNavigationItem
          key="104"
          name={t("navigation.statementOfAccessibility")}
          route={statementOfAccessibilityRoute}
          url={"statement-of-accessibility"}
          hideFromMenu={true}
        >
          <StatementOfAccessibility />
        </SidebarNavigationItem>
      </SidebarNavigationContainer>
    </>
  );
}
