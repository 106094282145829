import { Button, Typography } from "@king-ict/eupisi-ui/components";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";

import { ReactComponent as MenuIcon } from "../../assets/images/MenuIcon.svg";
import { UserLoginBox } from "../../components/UserLoginBox";
import styles from "./Navigation.module.scss";

export interface NavigationProps {
  onIconClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
}

export default function Navigation({ onIconClick }: NavigationProps) {
  const { t } = useTranslation();
  return (
    <>
      <Navbar className={styles.navbar} expand="sm">
        <Container className="p-0 m-0" fluid>
          <Navbar.Brand className="pb-3">
            <Button boxless={true} onClick={onIconClick} className="pb-2">
              <MenuIcon />
            </Button>
            <Typography
              component="span"
              variant="largeBold"
              color="light"
              className="pl-4"
            >
              {t("navigation.title")}
            </Typography>
          </Navbar.Brand>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <UserLoginBox />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
