import React, { useCallback, useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import { useHistory, useRouteMatch } from "react-router";

import {
  getUserManual,
  getStatement,
} from "../../api/UserManual/UserManualApi";
import { useTypedSelector } from "../../app/rootReducer";
import { Footer, FooterItem } from "../../components/Footer/Footer";
import RouteLeavingGuard from "../../components/RouteLeavingGuard";
import { draftRequestRoute, requestRoute } from "../../routes/routes";
import { Navbar } from "../Navbar/Navbar";
import RequestDraftPage from "../Request/RequestDraftPage";
import RequestPage from "../Request/RequestPage";
import { Sidebar } from "../Sidebar";
import styles from "./Layout.module.scss";
import Navigation from "./Navigation";

interface Props {
  children?: React.ReactNode;
}

export interface MatchParams {
  requestId: string;
  id: string;
}

export default function Layout({ children }: Props) {
  const history = useHistory();
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const requestDraftMatch = useRouteMatch<MatchParams>([draftRequestRoute()]);
  const requestMatch = useRouteMatch<MatchParams>([requestRoute()]);

  const downloadUserManual = () => {
    getUserManual();
  };

  const setImpairedStyle = useCallback(() => {
    document.documentElement.classList.add("theme-vision-impaired");
    document.documentElement.classList.remove("theme-default");
  }, []);

  const removeImpairedStyle = useCallback(() => {
    document.documentElement.classList.add("theme-default");
    document.documentElement.classList.remove("theme-vision-impaired");
  }, []);

  const showFaq = () => history.push("/faq");
  const showHelpAndSupport = () => history.push("/help-and-support");
  const showAboutProgram = () => history.push("/aboutprogram");
  const showStatementOfAccessibility = () => {
    window.scrollTo(0, 0);
    history.push("/statement-of-accessibility");
  };

  const footerItems: Array<FooterItem> = [
    {
      label: "Česta pitanja",
      callback: (item: FooterItem) => {
        showFaq();
        return false;
      },
    },
    {
      label: "Korisničke upute",
      callback: (item: FooterItem) => {
        downloadUserManual();
        return false;
      },
    },
    {
      label: "Pomoć i podrška",
      callback: (item: FooterItem) => {
        showHelpAndSupport();
        return false;
      },
    },
    {
      label: "O programu",
      callback: (item: FooterItem) => {
        showAboutProgram();
        return false;
      },
    },
    {
      label: "Izjava o pristupačnosti",
      callback: (item: FooterItem) => {
        // getStatement();
        showStatementOfAccessibility();
        return false;
      },
    },
  ];

  //prevent browser close in if form isedited
  const isEdited: boolean =
    useTypedSelector((state) => state.application.isEdited) &&
    (requestDraftMatch?.isExact || false);
  useEffect(() => {
    if (isEdited) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [isEdited]);

  const alertUser = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
  };

  return (
    <>
      <Navbar
        setImpairedStyle={setImpairedStyle}
        removeImpairedStyle={removeImpairedStyle}
      />
      <RouteLeavingGuard
        when={isEdited}
        navigate={(path) => {
          history.push(path);
        }}
      />
      <Navigation onIconClick={() => setShowSidebar(!showSidebar)} />
      <main className={styles.main}>
        <Container fluid>
          {!requestDraftMatch?.isExact && !requestMatch?.isExact && (
            <Sidebar sidebarIsOpen={showSidebar} />
          )}
          {requestDraftMatch?.isExact && <RequestDraftPage />}
          {requestMatch?.isExact && <RequestPage />}
          {children}
        </Container>
      </main>
      <Footer items={footerItems} />
    </>
  );
}
