export enum RequestTypeEnum {
  /**
   * RU - RedovanUpis
   */
  RegularEnrollment = 1,
  /**
   * RU TUR - RedovanUpitZaDjecuSaTeskocamaURazvoju
   */
  RegularEnrollmentForChildrenWithLearningDifficulties = 2,
  /**
   * PO - PrivremenoOslobadanje
   */
  TemporaryExemption = 3,
  /**
   * PU - PrijevremeniUpis
   */
  EarlyEnrollment = 4,
  /**
   * PP - PrimjereniProgram
   */
  //SuitableProgram = 5,
  /**
   * OU - OdgodaUpisa
   */
  //EnrollmentDelay = 6
}
export default RequestTypeEnum;
