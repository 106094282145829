import { CodeBook } from "@king-ict/eupisi-ui/models/Common/CodeBook";
import { getI18n } from "react-i18next";

import OtherParentDataCheckStatusEnum from "../models/Person/OtherParentDataCheckStatusEnum";
import RequestStatus from "../models/Request/RequestStatusEnum";
import RequestType from "../models/Request/RequestTypeEnum";
import { GenderEnum } from "../models/Shared/GenderEnum";
import {
  ServiceResultError,
  ServiceResultStatus,
} from "../models/Shared/OibServiceResult";

export const saveSuccess = "saveOK";

export const getOibErrorMessage = (
  serviceResult: ServiceResultStatus
): string => {
  switch (serviceResult) {
    case ServiceResultStatus.Unknown:
      return "common.oibServiceResultStatus.unknown";
    case ServiceResultStatus.OibFailed:
      return "common.oibServiceResultStatus.oibFailed";
    case ServiceResultStatus.BirthRegistryFailed:
      return "common.oibServiceResultStatus.birthRegistryFailed";
    case ServiceResultStatus.MupFailed:
      return "common.oibServiceResultStatus.mupFailed";
    case ServiceResultStatus.OibExists:
      return "common.oibServiceResultStatus.oibExists";
    case ServiceResultStatus.OibNotValid:
      return "common.oibServiceResultStatus.oibNotValid";
    case ServiceResultStatus.AddressNotFound:
      return "common.oibServiceResultStatus.addressNotFound";
    case ServiceResultStatus.OibExistsInSchoolCandidate:
      return "common.oibServiceResultStatus.oibExistsInSchoolCandidate";
    default:
      return "common.oibServiceResultStatus.success";
  }
};

export const getOibErrorMessageNew = (
  serviceResult: ServiceResultError
): string => {
  switch (serviceResult) {
    case ServiceResultError.Unknown:
      return "common.oibServiceResultStatus.unknown";
    case ServiceResultError.OibFailed:
      return "common.oibServiceResultStatus.oibFailed";
    case ServiceResultError.BirthRegistryFailed:
      return "common.oibServiceResultStatus.birthRegistryFailed";
    case ServiceResultError.MupFailed:
      return "common.oibServiceResultStatus.mupFailed";
    case ServiceResultError.OibExists:
      return "common.oibServiceResultStatus.oibExists";
    case ServiceResultError.OibNotValid:
      return "common.oibServiceResultStatus.oibNotValid";
    case ServiceResultError.AddressNotFound:
      return "common.oibServiceResultStatus.addressNotFound";
    case ServiceResultError.OibExistsInSchoolCandidate:
      return "common.oibServiceResultStatus.oibExistsInSchoolCandidate";
    default:
      return "common.oibServiceResultStatus.unknown";
  }
};

export const getOtherParentValidationMessage = (
  serviceResult: OtherParentDataCheckStatusEnum
): string => {
  switch (serviceResult) {
    case OtherParentDataCheckStatusEnum.Unknown:
      return "common.oibServiceResultStatus.unknown";
    case OtherParentDataCheckStatusEnum.OibFailed:
      return "common.oibServiceResultStatus.oibFailed";
    case OtherParentDataCheckStatusEnum.BirthRegistryFailed:
      return "common.oibServiceResultStatus.birthRegistryFailed";
    case OtherParentDataCheckStatusEnum.MupFailed:
      return "common.oibServiceResultStatus.mupFailed";
    case OtherParentDataCheckStatusEnum.OibExists:
      return "common.oibServiceResultStatus.oibExists";
    case OtherParentDataCheckStatusEnum.OibNotValid:
      return "common.oibServiceResultStatus.oibNotValid";
    case OtherParentDataCheckStatusEnum.OibExistsInSchoolCandidate:
      return "common.oibServiceResultStatus.oibExistsInSchoolCandidate";
    case OtherParentDataCheckStatusEnum.DataMismatch:
      return "common.oibServiceResultStatus.dataMismatch";
    case OtherParentDataCheckStatusEnum.NoCustody:
      return "common.oibServiceResultStatus.noCustody";
    case OtherParentDataCheckStatusEnum.SameAsApplicantParent:
      return "common.oibServiceResultStatus.sameAsApplicantParent";
    default:
      return "common.oibServiceResultStatus.success";
  }
};

export const getGenderString = (gender: GenderEnum | undefined): string => {
  switch (gender) {
    case GenderEnum.Male:
      return "common.schoolCandidateModel.schoolCandidateGenderValues.male";
    case GenderEnum.Female:
      return "common.schoolCandidateModel.schoolCandidateGenderValues.female";
    default:
      return "";
  }
};

export const getEnumToCodeBook = <TEnum extends {}>(
  type: TEnum,
  enumName: string
): CodeBook[] => {
  const i18 = getI18n();
  const arrayObjects = [] as CodeBook[];
  for (const [propertyKey, propertyValue] of Object.entries(type)) {
    var num = Number.parseInt(propertyKey);
    if (isNaN(num)) {
      continue;
    }
    var transKey = enumName + "." + propertyValue;
    var trans = i18.t(transKey);
    var codeBook = { id: num, name: trans } as CodeBook;
    arrayObjects.push(codeBook);
  }
  return arrayObjects.sort((a, b) => a.id - b.id);
};

export const getRequestStatusName = (requestId: number): string => {
  let statuses = getEnumToCodeBook(RequestStatus, "requestStatus");
  return statuses.find((v) => v.id === requestId)?.name ?? "-";
};
export const getRequestTypeName = (requestTypeId: number): string => {
  let types = getEnumToCodeBook(RequestType, "requestType");
  return types.find((v) => v.id === requestTypeId)?.name ?? "-";
};
