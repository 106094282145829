import React, { useState } from "react";

import {
  Select,
  SelectData,
  Spinner,
  TextField,
  useStepperDispatch,
} from "@king-ict/eupisi-ui/components";
import { useValidation } from "@king-ict/eupisi-ui/hooks";
import { hrDate, nameof } from "@king-ict/eupisi-ui/utils";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { updatePersonServiceData } from "../../../api/Request/RequestApi";
import { useTypedSelector } from "../../../app/rootReducer";
import { useAppDispatch } from "../../../app/store";
import ChildValidationSchema from "../../../models/Person/ChildValidationSchema";
import { PersonDetails } from "../../../models/Request/PersonDetails";
import CodeBook from "../../../models/Shared/CodeBook";
import { ServiceResultError } from "../../../models/Shared/OibServiceResult";
import {
  useGetNationalMinoritiesQuery,
  useGetCitizenshipsQuery,
  useGetNationalitiesQuery,
  useGetCountriesQuery,
  useGetGendersQuery,
} from "../../../queries/dataQuery";
import {
  updateRequest,
  updateRequestChild,
} from "../../../slices/requestSlice";
import { MsgUtils } from "../../../utils";
import {
  RequestFormCard,
  RequestFormOIBCheckMessage,
  RequestFormTopMessage,
} from "../RequestComponents";

export default function ChildDataStep() {
  const [showChildOibAlert, setShowChildOibAlert] = useState<
    string | undefined
  >();
  const [showChildOibSuccess, setShowChildOibSuccess] = useState<
    string | undefined
  >();
  const [showRefreshOIB, setShowRefreshOIB] = useState<boolean>(true);

  const childState = useTypedSelector(
    (state) => state.request.request.childDetails
  );

  const dispatch = useAppDispatch();

  const request = useTypedSelector((state) => state.request.request);

  useStepperDispatch({
    type: "next",
    payload: () => {
      return handleStep();
    },
  });

  useStepperDispatch({
    type: "previous",
    payload: () => {
      return handleStep();
    },
  });

  const { data: genders } = useGetGendersQuery();
  const { data: citizenships } = useGetCitizenshipsQuery();
  const { data: nationalMinorities } = useGetNationalMinoritiesQuery();
  const { data: nationalities } = useGetNationalitiesQuery();
  const { data: countries } = useGetCountriesQuery();

  const { t } = useTranslation();

  const [childErrors, doValidations] = useValidation({
    data: childState,
    validationSchema: ChildValidationSchema,
  });

  const handleNationalMinorityChange = (e: any) => {
    const newNationalMinorityId = e.target.value as number;
    const newChild: PersonDetails = {
      ...childState,
      nationalMinorityId: newNationalMinorityId,
    };
    dispatch(updateRequestChild(newChild));
    setShowRefreshOIB(false);
  };

  const handleNationalityChange = (e: any) => {
    const newNationalityId = e.target.value as number;

    const newChild: PersonDetails = {
      ...childState,
      nationalityId: newNationalityId,
    };
    dispatch(updateRequestChild(newChild));
    setShowRefreshOIB(false);
  };

  const handleStep = () => {
    if (childState) {
      const isValid = doValidations();
      if (isValid) {
        dispatch(updateRequestChild(childState));
      } else {
        return isValid;
      }
    } else {
      return false;
    }
  };

  const handleRefresh = () => {
    if (!request?.id) return null;
    setShowChildOibSuccess("");
    setShowChildOibAlert("");
    updatePersonServiceData(request?.id, 1).then((results) => {
      if (results?.result && results.isValid) {
        dispatch(updateRequest(results.result));
        setShowChildOibSuccess(
          t("common.oibServiceResultStatus.successRefresh")
        );
      } else if (
        results?.result &&
        !results.isValid &&
        results.validationFailures &&
        results.validationFailures[0]
      ) {
        setShowChildOibAlert(
          t(
            MsgUtils.getOibErrorMessageNew(
              results.validationFailures[0].message as ServiceResultError
            )
          )
        );
      } else {
        setShowChildOibAlert(
          t(MsgUtils.getOibErrorMessageNew(ServiceResultError.Unknown))
        );
      }
    });
  };

  const emptyValue = "-";

  if (!countries || !childState) return <Spinner show={true} />;

  return (
    <>
      <RequestFormTopMessage
        title={t("person.mainInfoChildTitle")}
        infoMessage={t("person.mainInfoChildMessage")}
      />
      <RequestFormOIBCheckMessage
        checkOibAlert={showChildOibAlert}
        checkOibSuccess={showChildOibSuccess}
      />
      <RequestFormCard
        cardTitle={t("childDataStep.basicInformation")}
        showRefresh={showRefreshOIB}
        onClickRefresh={handleRefresh}
      >
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.firstName")}
              helperText={t("common.birthRegistry")}
              value={childState?.firstName || emptyValue}
              plaintext
              bold
              errorText={childErrors?.firstName}
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.lastName")}
              helperText={t("common.birthRegistry")}
              value={childState?.lastName || emptyValue}
              plaintext
              bold
              errorText={childErrors?.lastName}
            />
          </Col>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.oib")}
              helperText={t("common.oibApi")}
              value={childState?.person?.oib || emptyValue}
              plaintext
              bold
              errorText={childErrors?.person?.oib}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.dateOfBirth")}
              helperText={t("common.birthRegistry")}
              value={
                childState && childState.dateOfBirth
                  ? hrDate(childState?.dateOfBirth)
                  : emptyValue
              }
              plaintext
              bold
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.sex")}
              helperText={t("common.birthRegistry")}
              value={
                genders?.find((x: any) => x.id === childState.genderId)?.name
              }
              plaintext
              bold
              errorText={childErrors?.genderId}
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.birthplace")}
              helperText={t("common.birthRegistry")}
              value={childState?.cityOfBirth || emptyValue}
              plaintext
              bold
              errorText={childErrors?.cityOfBirth}
            />
          </Col>
          <Col md={3}>
            <TextField
              type="text"
              label={t("person.countryBirth")}
              helperText={t("common.birthRegistry")}
              value={
                countries?.find((x) => x.id === childState.countryOfBirthId)
                  ?.name || emptyValue
              }
              plaintext
              bold
              errorText={childErrors?.countryOfBirthId}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <TextField
              type="text"
              label={t("person.citizenship")}
              helperText={t("common.birthRegistry")}
              value={
                citizenships!?.find((x) => x.id === childState.citizenshipId)
                  ?.name || emptyValue
              }
              plaintext
              bold
              errorText={childErrors?.citizenshipId}
            />
          </Col>
          <Col md={4}>
            <Select
              emptyOption={{ value: undefined, text: "" }}
              label={t("person.nationalMinority")}
              name={nameof<typeof childState>("nationalMinorityId")}
              value={childState?.nationalMinorityId}
              onChange={handleNationalMinorityChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: nationalMinorities || [],
                } as SelectData<CodeBook>
              }
              errorText={childErrors?.nationalMinorityId}
            />
          </Col>
          <Col md={4}>
            <Select
              required
              label={t("person.nationality")}
              name={nameof<typeof childState>("nationalityId")}
              value={childState?.nationalityId}
              onChange={handleNationalityChange}
              selectData={
                {
                  valueProp: "id",
                  textProp: "name",
                  data: nationalities || [],
                } as SelectData<CodeBook>
              }
              errorText={childErrors?.nationalityId}
            />
          </Col>
        </Row>
      </RequestFormCard>
      {(childState?.residence?.permanentAddress ||
        childState?.residence?.address) && (
        <RequestFormCard
          cardTitle={t("person.permanentResidenceInformation1")}
          cardSubTitle={t("person.permanentResidenceInformation2")}
        >
          <Row>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.permanentResidence")} - ${t(
                  "person.country"
                )}`}
                value={
                  countries?.find(
                    (x) => x.id === childState.residence?.countryId
                  )?.name || emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.permanentResidence")} - ${t(
                  "person.municipality"
                )}`}
                value={
                  childState?.residence?.permanentAddress?.municipality ||
                  childState?.residence?.address ||
                  emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={4}>
              <TextField
                type="text"
                label={`${t("person.permanentResidence")} - ${t(
                  "person.town"
                )}`}
                value={
                  childState?.residence?.permanentAddress?.town || emptyValue
                }
                plaintext
                bold
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.permanentResidence")} - ${t(
                  "person.postalCode"
                )}`}
                value={
                  childState?.residence?.permanentAddress?.postalCode ||
                  emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.permanentResidence")} - ${t(
                  "person.street"
                )}`}
                value={
                  childState?.residence?.permanentAddress?.street || emptyValue
                }
                plaintext
                bold
              />
            </Col>
            <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.permanentResidence")} - ${t(
                  "person.streetNumber"
                )}`}
                value={
                  childState?.residence?.permanentAddress?.houseNumber ||
                  emptyValue
                }
                plaintext
                bold
              />
            </Col>
          </Row>
        </RequestFormCard>
      )}
      {childState?.residence?.temporaryAddress && (
        <>
          <RequestFormCard
            cardTitle={t("person.temporaryResidenceInformation1")}
            cardSubTitle={t("person.temporaryResidenceInformation2")}
          >
            <Row>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.country"
                  )}`}
                  value={
                    countries?.find(
                      (x) => x.id === childState.residence?.countryId
                    )?.name || emptyValue
                  }
                  plaintext
                  bold
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.municipality"
                  )}`}
                  value={
                    childState?.residence?.temporaryAddress?.municipality ||
                    emptyValue
                  }
                  plaintext
                  bold
                />
              </Col>
              <Col md={4}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.town"
                  )}`}
                  value={
                    childState?.residence?.temporaryAddress?.town || emptyValue
                  }
                  plaintext
                  bold
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.postalCode"
                  )}`}
                  value={
                    childState?.residence?.temporaryAddress?.postalCode ||
                    emptyValue
                  }
                  plaintext
                  bold
                />
              </Col>
              <Col md={3}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.street"
                  )}`}
                  value={childState?.residence?.temporaryAddress?.street}
                  plaintext
                  bold
                />
              </Col>
              <Col md={3}>
                <TextField
                  type="text"
                  label={`${t("person.temporaryResidence")} - ${t(
                    "person.streetNumber"
                  )}`}
                  value={
                    childState?.residence?.temporaryAddress?.houseNumber ||
                    emptyValue
                  }
                  plaintext
                  bold
                />
              </Col>
              {/* <Col md={3}>
              <TextField
                type="text"
                label={`${t("person.temporaryResidence")} - ${t(
                  "person.streetNumberAddition"
                )}`}
                value={
                  childState?.temporaryResidence?.streetNumberAddition ||
                  emptyValue
                }
                plaintext
                bold
              />
            </Col> */}
            </Row>
          </RequestFormCard>
        </>
      )}
    </>
  );
}
