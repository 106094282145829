import { CodeBook } from "@king-ict/eupisi-ui/models";
import { TFunction } from "i18next";
import * as yup from "yup";

import { PersonIdentification } from "../Shared/PersonIdentification";
import { OibValidationStringScheme } from "../ValidationScheme/OibValidationScheme";
import ParentshipTypeEnum from "./ParentshipTypeEnum";

interface Props {
  t: TFunction;
}

const ApplicantParentValidationSchema = ({ t }: Props) => {
  const requiredMsg = t("validations.required");

  return yup.object().shape({
    firstName: yup.string().trim().nullable().required(requiredMsg),
    lastName: yup.string().trim().nullable().required(requiredMsg),
    person: yup
      .object({
        id: yup.number().notRequired(),
        oib: OibValidationStringScheme({ t }),

        firstName: yup.string().nullable().notRequired(),
        lastName: yup.string().nullable().notRequired(),
        dateOfBirth: yup.date().nullable().notRequired(),
      })
      .transform(optionalPersonIdentificationObject),
    genderId: yup.number().required(requiredMsg),
    cityOfBirth: yup.string().nullable().required(requiredMsg),
    countryOfBirthId: yup.number().required(requiredMsg),
    citizenshipId: yup.number().required(requiredMsg),
    nationalityId: yup.number().required(requiredMsg),
    parentshipTypeId: yup.number().required(requiredMsg),
    parentNumberId: yup.number().required(requiredMsg),
    contactMethodId: yup.number().required(requiredMsg),
    email: yup
      .string()
      .nullable()
      .email(t("validations.email"))
      .required(requiredMsg),
    phoneNumber: yup.string().nullable().required(requiredMsg),
    professionName: yup.string().nullable().required(requiredMsg),
    professionalQualificationId: yup.number().required(requiredMsg),
    custody: yup.string().when("parentshipTypeId", {
      is: (parentshipTypeId: number) =>
        parentshipTypeId === ParentshipTypeEnum.Custodian,
      then: yup.string().nullable().required(requiredMsg),
      otherwise: yup.string().nullable(),
    }),
  });
};

export default ApplicantParentValidationSchema;

function optionalCodeBookObject(object: CodeBook) {
  if (object && object?.id) {
    return object;
  } else {
    return undefined;
  }
}

function optionalPersonIdentificationObject(object: PersonIdentification) {
  if (object) {
    return object;
  } else {
    return undefined;
  }
}
